import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [passwordToggle, setPasswordToggle] = useState(true);
    const [passwordConfirmToggle, setPasswordConfirmToggle] = useState(true);
    const [passwordFieldsToggle, setPasswordFieldsToggle] = useState(false);
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

    const validationSchema = Yup.object({
        firstName: Yup.string().required(
            intl.formatMessage({id: 'USERS.VALIDATION.FIRST_NAME_MISSING'}),
        ),
        lastName: Yup.string().required(
            intl.formatMessage({id: 'USERS.VALIDATION.LAST_NAME_MISSING'}),
        ),
        email: Yup.string()
            .email(
                intl.formatMessage({
                    id: 'USERS.VALIDATION.EMAIL_INVALID_FORMAT',
                }),
            )
            .required(
                intl.formatMessage({id: 'USERS.VALIDATION.EMAIL_MISSING'}),
            ),
        phone: Yup.string()
            .matches(
                new RegExp(
                    '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
                ),
                intl.formatMessage({
                    id: 'USERS.VALIDATION.MOBILE_PHONE_INVALID_FORMAT',
                }),
            )
            .required(
                intl.formatMessage({
                    id: 'USERS.VALIDATION.MOBILE_PHONE_MISSING',
                }),
            ),
        roles: Yup.array().of(Yup.number()).min(0).required(),

        picture: Yup.array().min(0).required(),
        password: Yup.string()
            .when((value, schema) => {
                if (!props.isEdit || (props.isEdit && passwordFieldsToggle)) {
                    return schema.required(
                        intl.formatMessage({
                            id: 'USERS.VALIDATION.PASSWORD_MISSING',
                        }),
                    );
                }
            })
            .min(
                8,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MIN',
                }),
            )
            .max(
                24,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MAX',
                }),
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S*$/,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_FORMAT',
                }),
            ),
        passwordConfirmation: Yup.string()
            .when((value, schema) => {
                if (!props.isEdit || (props.isEdit && passwordFieldsToggle)) {
                    return schema.required(
                        intl.formatMessage({
                            id:
                                'USERS.VALIDATION.PASSWORD_CONFIRMATION_MISSING',
                        }),
                    );
                }
            })
            .oneOf(
                [Yup.ref('password')],

                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_CONFIRMATION_DOES_NOT_MATCH',
                }),
            )
            .min(
                8,

                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MIN',
                }),
            )
            .max(
                24,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MAX',
                }),
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S*$/,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_FORMAT',
                }),
            ),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const onDrop = (acceptedFiles, rejectedFiles, setFieldValue) => {
        /* istanbul ignore else */
        if (!rejectedFiles.length) {
            const picture = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );

            setFieldValue('picture', [...picture]);
        }
    };

    const handleTogglePassword = () => setPasswordToggle(!passwordToggle);

    const handleTogglePasswordConfirm = () =>
        setPasswordConfirmToggle(!passwordConfirmToggle);

    const handleToggleChangePassword = () => {
        setPasswordFieldsToggle(!passwordFieldsToggle);
    };

    const handleToggleIsDeleteUserModal = () => {
        setIsDeleteUserModalOpen(!isDeleteUserModalOpen);
    };

    return (
        <Formik
            data-test='userFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='3'>
                                        <Dropzone
                                            data-test='dropzone'
                                            accept='image/*'
                                            onDrop={(
                                                acceptedFiles,

                                                rejectedFiles,
                                            ) =>
                                                onDrop(
                                                    acceptedFiles,

                                                    rejectedFiles,

                                                    setFieldValue,
                                                )
                                            }
                                            multiple={false}
                                        >
                                            {({
                                                getRootProps,

                                                getInputProps,

                                                isDragActive,
                                            }) => (
                                                <section>
                                                    <div
                                                        {...getRootProps()}
                                                        className={classNames(
                                                            'dropzone',

                                                            {
                                                                'dropzone--isActive': isDragActive,
                                                            },
                                                        )}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />

                                                        {!values.picture
                                                            .length &&
                                                        !props.preview ? (
                                                            isDragActive ? (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'USERS.PROFILE_IMAGE_DRAG_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'USERS.PROFILE_IMAGE_DROP_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            )
                                                        ) : null}

                                                        {values.picture
                                                            .length ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    {values.picture.map(
                                                                        (
                                                                            file,

                                                                            i,
                                                                        ) => (
                                                                            <img
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className='img-fluid'
                                                                                src={
                                                                                    file.preview
                                                                                }
                                                                                alt={`${values.firstName} ${values.lastName}`}
                                                                            />
                                                                        ),
                                                                    )}
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : props.preview ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    <img
                                                                        className='img-fluid'
                                                                        src={
                                                                            props.preview
                                                                        }
                                                                        alt={`${values.firstName} ${values.lastName}`}
                                                                    />
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : null}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Col>

                                    <Col md='9'>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'USERS.USER_FIRST_NAME_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'USERS.USER_FIRST_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='firstName'
                                                value={values.firstName}
                                                onChange={handleChange}
                                                error={errors.firstName}
                                                isInvalid={errors.firstName}
                                            />

                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'USERS.USER_LAST_NAME_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'USERS.USER_FIRST_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='lastName'
                                                value={values.lastName}
                                                onChange={handleChange}
                                                error={errors.lastName}
                                                isInvalid={errors.lastName}
                                            />
                                        </BaseForm.Row>

                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='email'
                                                label={intl.formatMessage({
                                                    id:
                                                        'USERS.EMAIL_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'USERS.EMAIL_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='email'
                                                value={values.email}
                                                onChange={handleChange}
                                                error={errors.email}
                                                isInvalid={errors.email}
                                            />

                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'USERS.MOBILE_PHONE_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'USERS.MOBILE_PHONE_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='phone'
                                                value={values.phone}
                                                onChange={handleChange}
                                                error={errors.phone}
                                                isInvalid={errors.phone}
                                            />
                                        </BaseForm.Row>

                                        {props.isEdit && (
                                            <BaseForm.Row>
                                                <Input
                                                    type='switch'
                                                    id='changePassword'
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id:
                                                                'USERS.USER_CHANGE_PASSWORD_FIELD_LABEL',
                                                        },
                                                    )}
                                                    onChange={
                                                        handleToggleChangePassword
                                                    }
                                                    checked={
                                                        passwordFieldsToggle
                                                    }
                                                />
                                            </BaseForm.Row>
                                        )}

                                        <BaseForm.Row
                                            hidden={
                                                props.isEdit &&
                                                !passwordFieldsToggle
                                            }
                                        >
                                            <Input
                                                md='6'
                                                type='password'
                                                name='password'
                                                label={intl.formatMessage({
                                                    id:
                                                        'USERS.PASSWORD_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'USERS.PASSWORD_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                value={values.password}
                                                onChange={handleChange}
                                                error={errors.password}
                                                isInvalid={errors.password}
                                                hiddenToggle={passwordToggle}
                                                onClickToggle={
                                                    handleTogglePassword
                                                }
                                            />

                                            <Input
                                                md='6'
                                                type='password'
                                                name='passwordConfirmation'
                                                label={intl.formatMessage({
                                                    id:
                                                        'USERS.CONFIRM_PASSWORD_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'USERS.CONFIRM_PASSWORD_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                value={
                                                    values.passwordConfirmation
                                                }
                                                onChange={handleChange}
                                                error={
                                                    errors.passwordConfirmation
                                                }
                                                isInvalid={
                                                    errors.passwordConfirmation
                                                }
                                                hiddenToggle={
                                                    passwordConfirmToggle
                                                }
                                                onClickToggle={
                                                    handleTogglePasswordConfirm
                                                }
                                            />
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>

                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'USERS.ROLE_AND_PRIVILEGES_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <p>
                                        {intl.formatMessage({
                                            id: 'USERS.ROLE_FIELD_PLACEHOLDER',
                                        })}
                                    </p>
                                </BaseForm.Row>

                                <BaseForm.Row>
                                    {props.roles.map((role, i) => (
                                        <Input
                                            id={`#role${i}`}
                                            name='roles'
                                            key={i}
                                            md='3'
                                            type='switch'
                                            value={role.id}
                                            placeholder={role.name}
                                            onChange={handleChange}
                                            checked={values.roles.includes(
                                                role.id.toString(),
                                            )}
                                        />
                                    ))}
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <div>
                                <div>
                                    <Can run='USERS_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'USERS.DELETE_USER_MODAL_TITLE',
                                            })}
                                            handleConfirm={
                                                props.handleDeleteUser
                                            }
                                            handleShow={
                                                handleToggleIsDeleteUserModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteUserModal
                                            }
                                            show={isDeleteUserModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id:
                                                    'USERS.DELETE_USER_BUTTON_LABEL',
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.CONFIRM_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.CANCEL_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'USERS.DELETE_USER_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'USERS.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
