import React from 'react';
import {Switch} from 'react-router-dom';

import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';

import withLayout from '../../components/ui/Layout';
import ServiceList from './ServiceList';
import ServiceDetail from './ServiceDetail';
import CreateService from './CreateService';
import EditService from './EditService';

function Venues() {
    const intl = useIntl();

    return (
        <Switch data-test='servicesComponent'>
            <PrivateRoute
                permission='SERVICES_INDEX'
                exact
                path='/services'
                component={withLayout(
                    ServiceList,
                    intl.formatMessage({id: 'ROUTES.SERVICES.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='SERVICES_CREATE'
                exact
                path='/services/create'
                component={withLayout(
                    CreateService,
                    intl.formatMessage({id: 'ROUTES.SERVICES.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='SERVICES_EDIT'
                exact
                path='/services/:id/edit'
                component={withLayout(
                    EditService,
                    intl.formatMessage({id: 'ROUTES.SERVICES.EDIT'}),
                )}
            />
            <PrivateRoute
                permission='SERVICES_SHOW'
                exact
                path='/services/:id'
                component={withLayout(
                    ServiceDetail,
                    intl.formatMessage({id: 'ROUTES.SERVICES.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Venues;
