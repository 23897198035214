import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import StarterList from '../../components/starter/StarterList';

export class BookingList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bookings: [],
            venues: [],

            // Meta pagination
            page: 1,
            pageSize: 12,
            total: 0,

            // Search
            searchTimeout: 0,
            search: '',
            venue: '',
            boxType: '',
            paymentStatus: '',
            bookingStatus: '',

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchBookings(), this.fetchVenues()]);
    }

    getBookingTypeText(booking) {
        let text =
            booking.boxType &&
            this.props.intl.formatMessage({
                id: `BOOKINGS.BOOKING_TYPE_${booking.boxType.toUpperCase()}`,
            });
        if (booking.boxType === 'onetime') {
            text += `: ${booking.event?.name}`;
        }
        return text;
    }

    fetchBookings = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const result = await API.bookings.get({
                page: pageNumber,
                search: this.state.search,
                venueId: this.state.venue,
                boxType: this.state.boxType,
                paymentStatus: this.state.paymentStatus,
                status: this.state.bookingStatus,
            });

            this.setState({
                bookings: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchVenues = async () => {
        this.setState({loading: false});

        try {
            const {venues} = await API.venues.getAll();

            this.setState({
                venues,
                loading: false,
                error: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchBookings(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => this.fetchBookings(1), 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchBookings(1);
            },
        );
    };

    render() {
        const venuesOptions = this.state.venues.map((venue) => ({
            value: venue.id,
            label: venue.name,
        }));

        const boxTypeOptions = [
            {
                value: 'onetime',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_TYPE_ONETIME`,
                }),
            },
            {
                value: 'season',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_TYPE_SEASON`,
                }),
            },
        ];

        const bookingStatusOptions = [
            {
                value: 'pending',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_STATUS_PENDING`,
                }),
            },
            {
                value: 'accepted',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_STATUS_ACCEPTED`,
                }),
            },
            {
                value: 'rejected',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_STATUS_REJECTED`,
                }),
            },
            {
                value: 'partial',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_STATUS_PARTIAL`,
                }),
            },
            {
                value: 'missed',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_STATUS_MISSED`,
                }),
            },
            {
                value: 'canceled',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_STATUS_CANCELED`,
                }),
            },
        ];

        const paymentStatusOptions = [
            {
                value: 'pending',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_PAYMENT_STATUS_PENDING`,
                }),
            },
            {
                value: 'authorization',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_PAYMENT_STATUS_AUTHORIZATION`,
                }),
            },
            {
                value: 'capture',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_PAYMENT_STATUS_CAPTURE`,
                }),
            },
            {
                value: 'refund',
                label: this.props.intl.formatMessage({
                    id: `BOOKINGS.BOOKING_PAYMENT_STATUS_REFUND`,
                }),
            },
        ];

        return (
            <div data-test='bookingListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'BOOKINGS.BOOKING_CLIENT_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOOKINGS.BOOKING_OWNER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOOKINGS.BOOKING_BOX_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOOKINGS.BOOKING_BOX_TYPE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOOKINGS.BOOKING_VENUE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'BOOKINGS.BOOKING_BOOKING_STATUS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'BOOKINGS.BOOKING_PAYMENT_STATUS_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.bookings.map((booking) => ({
                            content: [
                                booking.key,
                                `${booking.buyer?.firstName} ${booking.buyer?.lastName}`,
                                `${booking.box?.userName}`,
                                `${booking.box?.number} ${booking.box?.boxZone}`,
                                this.getBookingTypeText(booking),
                                booking.box?.venue,
                                booking.status &&
                                    this.props.intl.formatMessage({
                                        id: `BOOKINGS.BOOKING_STATUS_${booking.status.toUpperCase()}`,
                                    }),
                                booking.payments &&
                                    booking.payments.length &&
                                    this.props.intl.formatMessage({
                                        id: `BOOKINGS.BOOKING_PAYMENT_STATUS_${booking.payments[0].status.toUpperCase()}`,
                                    }),
                            ],
                            link: `/bookings/${booking.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            options: venuesOptions,
                            name: 'venue',
                            placeholder: this.props.intl.formatMessage({
                                id:
                                    'BOOKINGS.BOOKING_FILTER_BY_VENUE_PLACEHOLDER',
                            }),
                            value: this.state.venue,
                        },
                        {
                            options: boxTypeOptions,
                            name: 'boxType',
                            placeholder: this.props.intl.formatMessage({
                                id:
                                    'BOOKINGS.BOOKING_FILTER_BY_BOX_TYPE_PLACEHOLDER',
                            }),
                            value: this.state.boxType,
                        },
                        {
                            options: bookingStatusOptions,
                            name: 'bookingStatus',
                            placeholder: this.props.intl.formatMessage({
                                id:
                                    'BOOKINGS.BOOKING_FILTER_BY_BOOKING_STATUS_PLACEHOLDER',
                            }),
                            value: this.state.bookingStatus,
                        },
                        {
                            options: paymentStatusOptions,
                            name: 'paymentStatus',
                            placeholder: this.props.intl.formatMessage({
                                id:
                                    'BOOKINGS.BOOKING_FILTER_BY_PAYMENT_STATUS_PLACEHOLDER',
                            }),
                            value: this.state.paymentStatus,
                        },
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(BookingList);
