import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(
        false,
    );

    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({id: 'CATEGORIES.VALIDATION.NAME_MISSING'}),
        ),
        order: Yup.number().required(
            intl.formatMessage({id: 'CATEGORIES.VALIDATION.ORDER_MISSING'}),
        ),
        picture: Yup.array().min(0).required(),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const onDrop = (acceptedFiles, rejectedFiles, setFieldValue) => {
        /* istanbul ignore else */
        if (!rejectedFiles.length) {
            const picture = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );

            setFieldValue('picture', [...picture]);
        }
    };

    const handleToggleIsDeleteCategoryModal = () => {
        setIsDeleteCategoryModalOpen(!isDeleteCategoryModalOpen);
    };

    return (
        <Formik
            data-test='categoryFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'CATEGORIES.GENERAL_INFORMATION_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='3'>
                                        <Dropzone
                                            data-test='dropzone'
                                            accept='image/*'
                                            onDrop={(
                                                acceptedFiles,

                                                rejectedFiles,
                                            ) =>
                                                onDrop(
                                                    acceptedFiles,

                                                    rejectedFiles,

                                                    setFieldValue,
                                                )
                                            }
                                            multiple={false}
                                        >
                                            {({
                                                getRootProps,

                                                getInputProps,

                                                isDragActive,
                                            }) => (
                                                <section>
                                                    <div
                                                        {...getRootProps()}
                                                        className={classNames(
                                                            'dropzone',

                                                            {
                                                                'dropzone--isActive': isDragActive,
                                                            },
                                                        )}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />

                                                        {!values.picture
                                                            .length &&
                                                        !props.preview ? (
                                                            isDragActive ? (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'CATEGORIES.IMAGE_DRAG_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'CATEGORIES.IMAGE_DROP_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            )
                                                        ) : null}

                                                        {values.picture
                                                            .length ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    {values.picture.map(
                                                                        (
                                                                            file,

                                                                            i,
                                                                        ) => (
                                                                            <img
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className='img-fluid'
                                                                                src={
                                                                                    file.preview
                                                                                }
                                                                                alt={`${values.name}`}
                                                                            />
                                                                        ),
                                                                    )}
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : props.preview ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    <img
                                                                        className='img-fluid'
                                                                        src={
                                                                            props.preview
                                                                        }
                                                                        alt={`${values.name}`}
                                                                    />
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : null}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Col>

                                    <Col md='9'>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.CATEGORY_NAME_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='name'
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name}
                                                isInvalid={errors.name}
                                            />

                                            <Input
                                                md='6'
                                                type='select'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.CATEGORY_ORDER_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_ORDER_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='order'
                                                value={values.order}
                                                onChange={handleChange}
                                                error={errors.order}
                                                isInvalid={errors.order}
                                                options={props.categoriesOrder}
                                            />
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <div>
                                <div>
                                    <Can run='CATEGORIES_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'CATEGORIES.DELETE_CATEGORY_MODAL_TITLE',
                                            })}
                                            handleConfirm={
                                                props.handleDeleteCategory
                                            }
                                            handleShow={
                                                handleToggleIsDeleteCategoryModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteCategoryModal
                                            }
                                            show={isDeleteCategoryModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id:
                                                    'CATEGORIES.DELETE_CATEGORY_BUTTON_LABEL',
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'CATEGORIES.CONFIRM_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'CATEGORIES.CANCEL_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.DELETE_CATEGORY_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'CATEGORIES.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
