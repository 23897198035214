export const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export const formatErrorMessageFromAPI = (e, errorMessage = '') => {
    if (e.response && e.response.status === 422) {
        errorMessage = Object.values(e.response.data.messages).join('\n');
    }

    return {
        loading: false,
        errorMessage,
    };
};

export const returnNotificationPath = (notificationType, notificationId) => {
    switch (notificationType) {
        case 'owner':
            return `/owners/${notificationId}`;
        case 'box':
            return `/boxes/${notificationId}`;
        case 'booking':
            return `/bookings/${notificationId}`;
        default:
            return '';
    }
};
