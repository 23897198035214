export default class Service {
    constructor({
        id = 0,
        name = '',
        order = 0,
        iconUrl = '',
        createdAt = '',
        updatedAt = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.order = order;
        this.iconUrl = iconUrl;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}
