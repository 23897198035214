import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import NotificationList from './NotificationList';
import NotificationDetail from './NotificationDetail';

function Notifications() {
    const intl = useIntl();

    return (
        <Switch data-test='notificationsComponent'>
            <PrivateRoute
                permission='NOTIFICATIONS_INDEX'
                exact
                path='/notifications'
                component={withLayout(
                    NotificationList,
                    intl.formatMessage({id: 'ROUTES.NOTIFICATIONS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='NOTIFICATIONS_INDEX'
                exact
                path='/notifications/:id'
                component={withLayout(
                    NotificationDetail,
                    intl.formatMessage({id: 'ROUTES.NOTIFICATIONS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Notifications;
