import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Venue from '../../models/Venue';

const venues = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/venues', {
            params: filters,
        });

        const list = new PaginatedList();

        list.items = response.data.map((venue) => new Venue(venue));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    getAll: async () => {
        const {data: response} = await API.get('/venues/all');

        return {
            venues: response.data.map((venue) => new Venue(venue)),
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/venues/${id}`);

        return {
            venue: new Venue(response.data),
        };
    },
    save: async (params) => {
        await API.post('/venues', params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {};
    },
    update: async (id, params) => {
        await API.put(`/venues/${id}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {};
    },
    delete: async (id) => {
        await API.delete(`/venues/${id}`);

        return {};
    },
    activate: async (id) => {
        await API.put(`/venues/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/venues/${id}/deactivate`);

        return {};
    },
};

export default venues;
