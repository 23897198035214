import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import CountryList from './CountryList';
import CountryDetail from './CountryDetail';
import CreateCountry from './CreateCountry';
import EditCountry from './EditCountry';

function Countries() {
    const intl = useIntl();

    return (
        <Switch data-test='countriesComponent'>
            <PrivateRoute
                permission='COUNTRIES_INDEX'
                exact
                path='/countries'
                component={withLayout(
                    CountryList,
                    intl.formatMessage({id: 'ROUTES.COUNTRIES.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='COUNTRIES_CREATE'
                exact
                path='/countries/create'
                component={withLayout(
                    CreateCountry,
                    intl.formatMessage({id: 'ROUTES.COUNTRIES.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='COUNTRIES_EDIT'
                exact
                path='/countries/:id/edit'
                component={withLayout(
                    EditCountry,
                    intl.formatMessage({id: 'ROUTES.COUNTRIES.EDIT'}),
                )}
            />
            <PrivateRoute
                permission='COUNTRIES_SHOW'
                exact
                path='/countries/:id'
                component={withLayout(
                    CountryDetail,
                    intl.formatMessage({id: 'ROUTES.COUNTRIES.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Countries;
