export default class Notification {
    constructor({
        id = 0,
        title = '',
        body = '',
        readAt = '',
        payload = {},
        user = {},
        createdAt = '',
        notificationType = '',
        notificationTypeId = 0,
    } = {}) {
        this.id = id;
        this.title = title;
        this.body = body;
        this.readAt = readAt;
        this.payload = payload;
        this.user = user;
        this.createdAt = createdAt;
        this.notificationType = notificationType;
        this.notificationTypeId = notificationTypeId;
    }

    get userFullName() {
        return `${this.user?.firstName || ''} ${this.user?.lastName || ''} ${
            this.user?.motherLastName || ''
        }`;
    }
}
