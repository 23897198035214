import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API';

export class PaymentMethodList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentMethods: [],

            // Error
            error: false,

            // Loading
            loading: true,
        };
    }

    async componentDidMount() {
        await this.fetchPaymentMethods();
    }

    fetchPaymentMethods = async () => {
        this.setState({loading: true});

        try {
            const {paymentMethods} = await API.paymentMethods.get();

            this.setState({
                paymentMethods,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    render() {
        return (
            <div data-test='paymentMethodListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    hidePagination={true}
                    hideSearch={true}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id:
                                    'PAYMENT_METHODS.PAYMENT_METHOD_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: `PAYMENT_METHODS.PAYMENT_METHOD_PROVIDER_TABLE_HEADER`,
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'PAYMENT_METHODS.PAYMENT_METHOD_IS_ACTIVE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.paymentMethods.map(
                            (paymentMethod) => ({
                                content: [
                                    paymentMethod.id,
                                    this.props.intl.formatMessage({
                                        id: `PAYMENT_METHODS.PAYMENT_METHOD_${paymentMethod.method.toUpperCase()}`,
                                    }),
                                    paymentMethod.provider,
                                    paymentMethod.isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No',
                                ],
                                link: `/payment-methods/${paymentMethod.id}`,
                            }),
                        ),
                    }}
                />
            </div>
        );
    }
}

export default injectIntl(PaymentMethodList);
