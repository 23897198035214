import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom';

import Category from '../../common/models/Category';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import ErrorModal from '../../components/ui/ErrorModal';

export class CategoryDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            category: new Category(),

            // Enable/Disable Category
            isCategoryAvailabilityModalOpen: false,

            // Errors
            isLoadErrorModalOpen: false,
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchCategory();
    }

    fetchCategory = async () => {
        this.setState({loading: true});

        try {
            const {category} = await API.categories.detail(
                this.props.match.params.id,
            );

            this.setState({
                category,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleCategoryAvailabilityModal = () => {
        this.setState({
            isCategoryAvailabilityModalOpen: !this.state
                .isCategoryAvailabilityModalOpen,
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleCategoryAvailability = async () => {
        const {category} = this.state;

        this.setState({loading: true});

        try {
            if (category.isActive) {
                await API.categories.deactivate(this.props.match.params.id);

                category.isActive = false;
            } else {
                await API.categories.activate(this.props.match.params.id);

                category.isActive = true;
            }

            this.setState({
                category,
                isCategoryAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `CATEGORIES.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isCategoryAvailabilityModalOpen: false,
            });
        }
    };

    render() {
        const {
            category: {isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/categories'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='categoryDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'CATEGORIES.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                {this.state.category.iconUrl && (
                                    <img
                                        className='img-fluid'
                                        src={this.state.category.iconUrl}
                                        alt={this.state.category.name}
                                    />
                                )}
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            Id
                                        </label>
                                        <p>{this.state.category.id}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'CATEGORIES.CATEGORY_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.category.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'CATEGORIES.CATEGORY_ORDER_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.category.order}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'CATEGORIES.CATEGORY_IS_ACTIVE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.state.category.isActive
                                                ? this.props.intl.formatMessage(
                                                      {
                                                          id: 'GENERAL.YES',
                                                      },
                                                  )
                                                : 'No'}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'CATEGORIES.CATEGORY_CREATED_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.category.createdAt}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'CATEGORIES.CATEGORY_UPDATED_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.category.updatedAt}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='CATEGORIES_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'CATEGORIES.BLOCK_CATEGORY_MODAL'
                                        : 'CATEGORIES.UNBLOCK_CATEGORY_MODAL',
                                })}
                                handleConfirm={this.toggleCategoryAvailability}
                                handleShow={
                                    this.toggleCategoryAvailabilityModal
                                }
                                handleClose={
                                    this.toggleCategoryAvailabilityModal
                                }
                                show={
                                    this.state.isCategoryAvailabilityModalOpen
                                }
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'CATEGORIES.BLOCK_CATEGORY_MODAL'
                                        : 'CATEGORIES.UNBLOCK_CATEGORY_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'CATEGORIES.CONFIRM_BLOCK_CATEGORY'
                                            : 'CATEGORIES.CONFIRM_UNBLOCK_CATEGORY',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'CATEGORIES.BLOCK_CATEGORY_CONFIRMATION_MESSAGE'
                                            : 'CATEGORIES.UNBLOCK_CATEGORY_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div>
                        <Can run='CATEGORIES_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/categories/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'CATEGORIES.EDIT_CATEGORY_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>
                <ErrorModal
                    isLoadErrorModalOpen={this.state.isLoadErrorModalOpen}
                    toggleLoadErrorModal={this.toggleLoadErrorModal}
                    errorMessage={this.props.intl.formatMessage({
                        id: 'CATEGORIES.ERROR_MODAL_TEXT',
                    })}
                    returnUrl={`/categories`}
                    loading={this.state.loading}
                    handleRetryClick={this.fetchCategory}
                />
            </>
        );
    }
}

export default injectIntl(CategoryDetail);
