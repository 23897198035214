import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import PaymentMethodList from './PaymentMethodList';
import PaymentMethodDetail from './PaymentMethodDetail';

function PaymentMethods(props) {
    const intl = useIntl();

    return (
        <Switch data-test='paymentMethodsComponent'>
            <PrivateRoute
                permission='PAYMENT_METHODS_INDEX'
                exact
                path='/payment-methods'
                component={withLayout(
                    PaymentMethodList,
                    intl.formatMessage({id: 'ROUTES.PAYMENT_METHODS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='PAYMENT_METHODS_INDEX'
                exact
                path='/payment-methods/:id'
                component={withLayout(
                    PaymentMethodDetail,
                    intl.formatMessage({id: 'ROUTES.PAYMENT_METHODS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default PaymentMethods;
