import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API';

export class PaymentProviderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentProviders: [],

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchPaymentProviders();
    }

    fetchPaymentProviders = async () => {
        this.setState({loading: true});

        try {
            const {paymentProviders} = await API.paymentProviders.get();

            this.setState({
                paymentProviders,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    render() {
        return (
            <div data-test='paymentProviderListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    hidePagination={true}
                    hideSearch={true}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id:
                                    'PAYMENT_PROVIDERS.PAYMENT_PROVIDER_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'PAYMENT_PROVIDERS.PAYMENT_PROVIDER_IS_ACTIVE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.paymentProviders.map(
                            (paymentProvider) => ({
                                content: [
                                    paymentProvider.id,
                                    paymentProvider.name,
                                    paymentProvider.isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No',
                                ],
                                link: `/payment-providers/${paymentProvider.id}`,
                            }),
                        ),
                    }}
                />
            </div>
        );
    }
}

export default injectIntl(PaymentProviderList);
