import React from 'react';
import {Switch} from 'react-router-dom';

import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';

import withLayout from '../../components/ui/Layout';
import VenueList from './VenueList';
import VenueDetail from './VenueDetail';
import CreateVenue from './CreateVenue';
import EditVenue from './EditVenue';

function Venues() {
    const intl = useIntl();

    return (
        <Switch data-test='venuesComponent'>
            <PrivateRoute
                permission='VENUES_INDEX'
                exact
                path='/venues'
                component={withLayout(
                    VenueList,
                    intl.formatMessage({id: 'ROUTES.VENUES.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='VENUES_CREATE'
                exact
                path='/venues/create'
                component={withLayout(
                    CreateVenue,
                    intl.formatMessage({id: 'ROUTES.VENUES.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='VENUES_EDIT'
                exact
                path='/venues/:id/edit'
                component={withLayout(
                    EditVenue,
                    intl.formatMessage({id: 'ROUTES.VENUES.EDIT'}),
                )}
            />
            <PrivateRoute
                permission='VENUES_SHOW'
                exact
                path='/venues/:id'
                component={withLayout(
                    VenueDetail,
                    intl.formatMessage({id: 'ROUTES.VENUES.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Venues;
