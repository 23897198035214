export default class Box {
    constructor({
        id = 0,
        number = '',
        boxZone = '',
        detail = '',
        type = '',
        propertyTitlePictureUrl = '',
        proofOfResidencyPictureUrl = '',
        availableSeats = 0,
        price = 0,
        deposit = 0,
        pictureUrl = [],
        seasons = [],
        position = {x: '0', y: '0'},
        cancellationPolicy = '',
        accessIndications = '',
        status = 'pending',
        isActive = false,
        isShared = false,
        readyForReview = false,
        venue = '',
        venuePictureUrl = '',
        venuePictureWidth = '',
        venuePictureHeight = '',
        city = '',
        userName = '',
        review = '',
    } = {}) {
        this.id = id;
        this.number = number;
        this.boxZone = boxZone;
        this.detail = detail;
        this.type = type;
        this.availableSeats = availableSeats;
        this.price = price;
        this.deposit = deposit;
        this.pictureUrl = pictureUrl;
        this.seasons = seasons;
        this.position = position;
        this.propertyTitlePictureUrl = propertyTitlePictureUrl;
        this.proofOfResidencyPictureUrl = proofOfResidencyPictureUrl;
        this.cancellationPolicy = cancellationPolicy;
        this.accessIndications = accessIndications;
        this.status = status;
        this.isActive = isActive;
        this.isShared = isShared;
        this.readyForReview = readyForReview;
        this.venue = venue;
        this.venuePictureUrl = venuePictureUrl;
        this.venuePictureWidth = venuePictureWidth;
        this.venuePictureHeight = venuePictureHeight;
        this.city = city;
        this.userName = userName;
        this.review = review;
    }
}
