import axios from 'axios';
import permissions from './permissions/Permissions';
import roles from './roles/Roles';
import cities from './cities/Cities';
import countries from './countries/Countries';
import paymentProviders from './payment_providers/PaymentProviders';
import paymentMethods from './payment_methods/PaymentMethods';
import session from './session/Session';
import states from './states/States';
import users from './users/Users';
import venues from './venues/Venues';
import owners from './owners/Owners';
import boxes from './boxes/Boxes';
import categories from './categories/Categories';
import services from './services/Services';
import events from './events/Events';
import notifications from './notifications/Notifications';
import bookings from './bookings/Bookings';
import settings from './settings/Settings';
import reports from './reports/Reports';
import {store} from '../redux/StoreReducers';

const authTokens = {
    bearer: undefined,
    refreshToken: undefined,
};

export const API = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {Accept: 'application/json', Authorization: ' bearer'},
});

// Export setters for bearer and refresh token

export const setBearerToken = (token) => {
    authTokens.bearer = token;
    API.defaults.headers.common['Authorization'] = token;
};

export const setRefreshToken = (token) => {
    authTokens.refreshToken = token;
};

API.interceptors.request.use((config) => {
    if (store.getState().sessionReducer.session) {
        config.headers.Authorization =
            'Bearer ' + store.getState().sessionReducer.session.token;
        config.headers['Accept-Language'] = store.getState().langReducer.lang;
    }
    return config;
});

// Set interceptor for refreshing auth token
const logout = function () {
    return {
        type: 'LOGOUT',
    };
};

API.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    },
);

export default {
    users,
    roles,
    session,
    cities,
    states,
    permissions,
    countries,
    paymentProviders,
    paymentMethods,
    venues,
    owners,
    boxes,
    categories,
    services,
    events,
    notifications,
    bookings,
    settings,
    reports,
};
