import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import Form from '../../components/form/users/UserForm';

export class CreateUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToIndex: false,
            roles: [],
            errorMessage: '',
        };
    }

    async componentDidMount() {
        return this.fetchRoles();
    }

    fetchRoles = async () => {
        this.setState({loading: true});

        try {
            const {roles} = await API.roles.getAll();

            this.setState({
                roles,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});
        const formData = new FormData();

        formData.append('firstName', input.firstName);
        formData.append('lastName', input.lastName);
        formData.append('email', input.email);
        formData.append('phone', input.phone);
        formData.append('password', input.password);

        input.roles.map((role) => formData.append('roles[]', role));

        /* istanbul ignore else */
        if (input.picture.length) {
            formData.append('picture', input.picture[0]);
        }

        try {
            await API.users.save(formData);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    };

    render() {
        const initialValues = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            passwordConfirmation: '',
            roles: [],
            picture: [],
        };
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/users',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'USERS.USER_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='createUserComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/users'
                    backText={this.props.intl.formatMessage({
                        id: 'LIST.BACK_TO_LIST',
                    })}
                    roles={this.state.roles}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                />
            </>
        );
    }
}

export default injectIntl(CreateUser);
