import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {useIntl} from 'react-intl';

import Users from './users/Users';
import Roles from './roles/Roles';
import Cities from './cities/Cities';
import States from './states/States';
import Countries from './countries/Countries';
import Dashboard from './dashboard/Dashboard';
import PaymentProviders from './payment_providers/PaymentProviders';
import withLayout from '../components/ui/Layout';
import PaymentMethods from './payment_methods/PaymentMethods';
import Venues from './venues/Venues';
import Owners from './owners/Owners';
import Boxes from './boxes/Boxes';
import Categories from './categories/Categories';
import Services from './services/Services';
import Events from './events/Events';
import Notifications from './notifications/Notifications';
import Bookings from './bookings/Bookings';
import Settings from './settings/Settings';

function MainContainer(props) {
    const intl = useIntl();

    return (
        <Switch>
            <Route
                exact
                path='/'
                component={withLayout(
                    Dashboard,
                    intl.formatMessage({id: 'MENU.DASHBOARD'}),
                )}
            />
            <Route path='/users' component={Users} />
            <Route path='/roles' component={Roles} />
            <Route path='/payment-providers' component={PaymentProviders} />
            <Route path='/payment-methods' component={PaymentMethods} />
            <Route path='/cities' component={Cities} />
            <Route path='/states' component={States} />
            <Route path='/countries' component={Countries} />
            <Route path='/venues' component={Venues} />
            <Route path='/owners' component={Owners} />
            <Route path='/boxes' component={Boxes} />
            <Route path='/categories' component={Categories} />
            <Route path='/services' component={Services} />
            <Route path='/events' component={Events} />
            <Route path='/notifications' component={Notifications} />
            <Route path='/bookings' component={Bookings} />
            <Route path='/settings' component={Settings} />
        </Switch>
    );
}

const logout = function () {
    return {
        type: 'LOGOUT',
    };
};

const mapStateToProps = function (state) {
    return {
        user: state.sessionReducer.user,
        title: state.testReducer.title,
        content: state.testReducer.content,
    };
};

const mapDispatchToProps = {
    logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
