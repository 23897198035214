import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom';

import PaymentMethod from '../../common/models/PaymentMethod';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import Modal from "react-bootstrap/Modal";
import LoadingButton from "../../components/form/LoadingButton";

export class PaymentMethodDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentMethod: new PaymentMethod(),

            // Enable/Disable Modal
            isPaymentMethodAvailabilityModalOpen: false,

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchPaymentMethod();
    }

    fetchPaymentMethod = async () => {
        this.setState({loading: true});

        try {
            const {paymentMethod} = await API.paymentMethods.detail(
                this.props.match.params.id,
            );

            this.setState({
                paymentMethod,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    togglePaymentMethodAvailabilityModal = () => {
        this.setState({
            isPaymentMethodAvailabilityModalOpen: !this.state
                .isPaymentMethodAvailabilityModalOpen,
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    togglePaymentMethodAvailability = async () => {
        const {paymentMethod} = this.state;

        this.setState({loading: true});

        try {
            if (paymentMethod.isActive) {
                await API.paymentMethods.deactivate(this.props.match.params.id);

                paymentMethod.isActive = false;
            } else {
                await API.paymentMethods.activate(this.props.match.params.id);

                paymentMethod.isActive = true;
            }

            this.setState({
                paymentMethod,
                isPaymentMethodAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `PAYMENT_METHODS.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isPaymentMethodAvailabilityModalOpen: false,
            });
        }
    };

    render() {
        const {
            paymentMethod: {isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/payment-methods'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='paymentMethodDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'PAYMENT_METHODS.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>Id</label>
                                <p>{this.state.paymentMethod.id}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'PAYMENT_METHODS.PAYMENT_METHOD_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.paymentMethod.method &&
                                        this.props.intl.formatMessage({
                                            id: `PAYMENT_METHODS.PAYMENT_METHOD_${this.state.paymentMethod.method.toUpperCase()}`,
                                        })}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'PAYMENT_METHODS.PAYMENT_METHOD_PROVIDER_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.paymentMethod.provider?.name ||
                                        ''}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'PAYMENT_METHODS.PAYMENT_METHOD_IS_ACTIVE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No'}
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='PAYMENT_METHODS_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'PAYMENT_METHODS.BLOCK_PAYMENT_METHOD_MODAL'
                                        : 'PAYMENT_METHODS.UNBLOCK_PAYMENT_METHOD_MODAL',
                                })}
                                handleConfirm={
                                    this.togglePaymentMethodAvailability
                                }
                                handleShow={
                                    this.togglePaymentMethodAvailabilityModal
                                }
                                handleClose={
                                    this.togglePaymentMethodAvailabilityModal
                                }
                                show={
                                    this.state
                                        .isPaymentMethodAvailabilityModalOpen
                                }
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'PAYMENT_METHODS.BLOCK_PAYMENT_METHOD_MODAL'
                                        : 'PAYMENT_METHODS.UNBLOCK_PAYMENT_METHOD_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'PAYMENT_METHODS.CONFIRM_BLOCK_PAYMENT_METHOD'
                                            : 'PAYMENT_METHODS.CONFIRM_UNBLOCK_PAYMENT_METHOD',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'PAYMENT_METHODS.BLOCK_PAYMENT_METHOD_CONFIRMATION_MESSAGE'
                                            : 'PAYMENT_METHODS.UNBLOCK_PAYMENT_METHOD_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'PAYMENT_METHODS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/payment-methods'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchPaymentMethod}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(PaymentMethodDetail);
