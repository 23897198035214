import React from 'react';
import {injectIntl} from 'react-intl';
import API from '../../common/utils/API';
import Alert from 'react-bootstrap/Alert';
import StarterList from '../../components/starter/StarterList';

export class OwnerList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            owners: [],

            // Meta pagination
            page: 1,
            pageSize: 12,
            total: 0,

            // Search
            searchTimeout: 0,
            search: '',

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchOwners();
    }

    fetchOwners = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const result = await API.owners.get({
                page: pageNumber,
                search: this.state.search,
            });

            this.setState({
                owners: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchOwners(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchOwners(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchOwners(1);
            },
        );
    };

    render() {
        return (
            <div data-test='ownerListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'OWNERS.OWNER_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'OWNERS.OWNER_EMAIL_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'OWNERS.OWNER_PHONE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'OWNERS.OWNER_STATUS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'OWNERS.OWNER_IS_ACTIVE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.owners.map((owner) => ({
                            content: [
                                owner.id,
                                owner.fullName,
                                owner.email,
                                owner.phone,
                                this.props.intl.formatMessage({
                                    id: `OWNERS.OWNER_STATUS_${owner.status.toUpperCase()}`,
                                }),
                                owner.isActive
                                    ? this.props.intl.formatMessage({
                                          id: 'GENERAL.YES',
                                      })
                                    : 'No',
                            ],
                            link: `/owners/${owner.id}`,
                        })),
                    }}
                />
            </div>
        );
    }
}

export default injectIntl(OwnerList);
