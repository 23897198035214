import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import API from '../../common/utils/API';
import LoadingButton from '../../components/form/LoadingButton';
import Venue from '../../common/models/Venue';
import Form from '../../components/form/venues/VenueForm';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';

export class EditVenue extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            venue: new Venue(),
            cities: [],

            // Redirects
            shouldRedirectToIndex: false,
            shouldRedirectToDetail: false,
            redirectSuccessMessage: '',

            // Errors
            isLoadErrorModalOpen: false,
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchVenue(), this.fetchCities()]);
    }

    fetchVenue = async () => {
        this.setState({loading: true});

        try {
            const {venue} = await API.venues.detail(this.props.match.params.id);

            this.setState({
                venue,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    fetchCities = async () => {
        this.setState({loading: true});

        try {
            const {cities} = await API.cities.getAll();

            this.setState({
                cities,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});
        const formData = new FormData();

        formData.append('name', input.name);
        formData.append('city', input.city);
        formData.append('address', input.address);
        formData.append('contact', input.contact);
        formData.append('latitude', input.latitude);
        formData.append('longitude', input.longitude);

        /* istanbul ignore else */
        if (input.picture?.length) {
            formData.append('picture', input.picture[0]);
            formData.append('pictureWidth', input.pictureWidth);
            formData.append('pictureHeight', input.pictureHeight);
        }

        try {
            await API.venues.update(this.props.match.params.id, formData);

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'VENUES.VENUE_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.UPDATING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    handleDeleteVenue = async () => {
        this.setState({loading: true});

        try {
            await API.venues.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'VENUES.VENUE_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        const {
            shouldRedirectToDetail,
            shouldRedirectToIndex,
            redirectSuccessMessage: successMessage,
        } = this.state;
        const venueCity = this.state.cities.find(
            (city) => city.id === this.state.venue.city.id,
        );
        const cities = this.state.cities.map((city) => ({
            value: city.id,
            label: city.name,
        }));
        const initialValues = {
            name: this.state.venue?.name || '',
            address: this.state.venue?.address || '',
            contact: this.state.venue?.contact || '',
            city: venueCity?.id || '',
            picture: [],
            latitude: this.state.venue?.latitude || '',
            longitude: this.state.venue?.longitude || '',
            pictureWidth: this.state.venue?.pictureWidth,
            pictureHeight: this.state.venue?.pictureHeight,
        };

        return shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/venues/${this.props.match.params.id}`,
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/venues',
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='updateVenueComponent'
                    initialValues={initialValues}
                    backRedirectUrl={`/venues/${this.props.match.params.id}`}
                    backText={this.props.intl.formatMessage({
                        id: 'GENERAL.BACK_TO_DETAIL',
                    })}
                    cities={cities}
                    loading={this.state.loading}
                    preview={this.state.venue.pictureUrl}
                    isEdit={true}
                    handleSubmit={this.onSubmit}
                    handleDeleteVenue={this.handleDeleteVenue}
                />
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'VENUES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/venues'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchVenue}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(EditVenue);
