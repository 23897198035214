import React from 'react';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import LoadingButton from '../../components/form/LoadingButton';
import Role from '../../common/models/Role';
import Form from '../../components/form/roles/RoleForm';

export class EditRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            role: new Role(),
            errorMessage: '',
            permissions: [],
            permissionGroups: [],
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchRole(), this.fetchPermissions()]);
    }

    fetchPermissions = async () => {
        this.setState({loading: true});

        try {
            const {permissions} = await API.permissions.get();
            let permissionGroups = [...this.state.permissionGroups];

            permissionGroups = permissionGroups.concat(
                permissions.map((permission) => permission.group),
            );

            this.setState({
                permissionGroups,
                permissions,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    fetchRole = async () => {
        this.setState({loading: true});

        try {
            const {role} = await API.roles.detail(this.props.match.params.id);
            let permissionGroups = [...this.state.permissionGroups];

            permissionGroups = permissionGroups.concat(
                role.permissions.map((permission) => permission.group),
            );

            this.setState({
                role,
                isLoadErrorModalOpen: false,
                permissionGroups,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        try {
            await API.roles.update(this.props.match.params.id, input);

            this.setState({loading: false, shouldRedirectToDetail: true});
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.UPDATING_DATA',
                    }),
                });
            }
        }
    };

    handleDeleteRole = async () => {
        this.setState({loading: true});

        try {
            await API.roles.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'ROLES.ROLE_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        const initialValues = {
            name: this.state.role?.name || '',
            permissions:
                this.state.role?.permissions.map(
                    (permission) => permission.code,
                ) || [],
        };
        const permissionGroups = this.state.permissionGroups
            .filter((x, i, a) => a.indexOf(x) === i)
            .sort();

        return this.state.shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/roles/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/roles',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='updateRoleComponent'
                    initialValues={initialValues}
                    backRedirectUrl={`/roles/${this.props.match.params.id}`}
                    backText={this.props.intl.formatMessage({
                        id: 'ROLES.BACK_TO_ROLE_DETAIL',
                    })}
                    permissions={this.state.permissions}
                    permissionGroups={permissionGroups}
                    role={this.state.role}
                    loading={this.state.loading}
                    isEdit={true}
                    handleSubmit={this.onSubmit}
                    handleDeleteRole={this.handleDeleteRole}
                />
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href={`/roles/${this.props.match.params.id}`}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadRole}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default injectIntl(EditRole);
