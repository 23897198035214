import React from 'react';
import {useIntl} from 'react-intl';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import LoadingButton from '../form/LoadingButton';

function ErrorModal(props) {
    const intl = useIntl();

    return (
        <Modal
            show={props.isLoadErrorModalOpen}
            onHide={props.toggleLoadErrorModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {intl.formatMessage({
                        id: 'ERRORS.MODAL_TITLE',
                    })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.errorMessage}</Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    className='btn-bold btn-light-dark'
                    href={props.returnUrl}
                >
                    {intl.formatMessage({
                        id: 'ERRORS.MODAL_BACK_BUTTON',
                    })}
                </Button>
                <LoadingButton
                    variant='primary'
                    className='btn-bold'
                    loading={props.loading}
                    onClick={props.handleRetryClick}
                >
                    {intl.formatMessage({
                        id: 'ERRORS.MODAL_RETRY_BUTTON',
                    })}
                </LoadingButton>
            </Modal.Footer>
        </Modal>
    );
}

export default ErrorModal;
