import React from 'react';
import IncomeGraph from '../../components/dashboard/IncomeGraph';
import DashboardPieGraph from '../../components/dashboard/DashboardPieGraph';
import DashboardBarGraph from '../../components/dashboard/DashboardBarGraph';
import ReservedBoxes from '../../components/dashboard/ReservedBoxes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import API from '../../common/utils/API';
import {injectIntl} from 'react-intl';

const monthOptions = [
    {
        name: 'Mensual',
        value: '1',
    },
    {
        name: 'Semestral',
        value: '6',
    },
    {
        name: 'Anual',
        value: '12',
    },
];

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <h3
                    style={{marginLeft: '2px', fontWeight: 'lighter'}}
                    className='mb-3'
                >
                    Ingresos
                </h3>
                <Row>
                    <Col md={7} sm={12}>
                        <IncomeGraph {...this.props} />
                    </Col>
                    <Col md={5} sm={12}>
                        <DashboardBarGraph
                            monthOptions={monthOptions}
                            getReport={API.reports.getIncomeReport}
                            header='Rentas'
                            dataKey='count'
                            dataName='Rentas'
                            {...this.props}
                        />
                    </Col>
                </Row>
                <h3
                    style={{marginLeft: '2px', fontWeight: 'lighter'}}
                    className='mb-3'
                >
                    Palcos
                </h3>
                <Row>
                    <Col md={6}>
                        <DashboardBarGraph
                            monthOptions={monthOptions}
                            getReport={API.reports.getBoxesReport}
                            header='Altas de palcos'
                            dataKey='count'
                            dataName='Altas'
                            {...this.props}
                        />
                    </Col>
                    <Col md={6}>
                        <ReservedBoxes />
                    </Col>
                </Row>
                <h3
                    style={{marginLeft: '2px', fontWeight: 'lighter'}}
                    className='mb-3'
                >
                    Usuarios
                </h3>
                <Row>
                    <Col md={12}>
                        <DashboardBarGraph
                            monthOptions={monthOptions}
                            getReport={API.reports.getUserSignUpsReport}
                            header='Registros de usuarios'
                            dataKey='count'
                            dataName='Registros'
                            {...this.props}
                        />
                    </Col>
                    <Col md={6}>
                        <DashboardPieGraph
                            header='Edades'
                            getReport={API.reports.getUserAgesReport}
                            {...this.props}
                        />
                    </Col>
                    <Col md={6}>
                        <DashboardPieGraph
                            header='Usuarios activos'
                            colors={['#00BB04', '#FFBB28']}
                            getReport={API.reports.getActiveUsersReport}
                            {...this.props}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default injectIntl(Dashboard);
