import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import {NavLink} from 'react-router-dom';

import Owner from '../../common/models/Owner';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import Input from '../../components/form/Input';
import LoadingButton from '../../components/form/LoadingButton';

export class OwnerDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            owner: new Owner(),

            // Modals
            isAcceptOwnerStatusModalOpen: false,
            isRejectOwnerStatusModalOpen: false,
            isOwnerAvailabilityModalOpen: false,

            // Reason for rejected status
            review: '',
            reviewError: '',

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchOwner();
    }

    fetchOwner = async () => {
        this.setState({loading: true});

        try {
            const {owner} = await API.owners.detail(this.props.match.params.id);

            this.setState({
                owner,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleOwnerAvailabilityModal = () => {
        this.setState({
            isOwnerAvailabilityModalOpen: !this.state
                .isOwnerAvailabilityModalOpen,
        });
    };

    toggleAcceptOwnerStatusModal = () => {
        this.setState({
            isAcceptOwnerStatusModalOpen: !this.state
                .isAcceptOwnerStatusModalOpen,
        });
    };

    toggleRejectOwnerStatusModal = () => {
        this.setState({
            isRejectOwnerStatusModalOpen: !this.state
                .isRejectOwnerStatusModalOpen,
            reviewError: '',
            review: '',
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleOwnerAvailability = async () => {
        const {owner} = this.state;

        this.setState({loading: true});

        try {
            if (owner.isActive) {
                await API.owners.deactivate(this.props.match.params.id);

                owner.isActive = false;
            } else {
                await API.owners.activate(this.props.match.params.id);

                owner.isActive = true;
            }

            this.setState({
                owner,
                isOwnerAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `OWNERS.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isOwnerAvailabilityModalOpen: false,
            });
        }
    };

    acceptOwnerHandler = async () => {
        const {owner} = this.state;

        this.setState({loading: true});

        try {
            await API.owners.changeStatus(this.props.match.params.id, {
                status: 'accepted',
                review: '',
            });

            owner.status = 'accepted';
            owner.isActive = true;
            owner.readyForReview = false;

            this.setState({
                owner,
                isAcceptOwnerStatusModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `OWNERS.STATUS_TOGGLE_ERROR_MESSAGE`,
                }),
                isAcceptOwnerStatusModalOpen: false,
            });
        }
    };

    rejectOwnerHandler = async () => {
        const {owner, review} = this.state;

        if (review.length < 10) {
            this.setState({
                reviewError: this.props.intl.formatMessage({
                    id: `OWNERS.VALIDATION.REVIEW_${
                        !review.length ? 'MISSING' : 'FORMAT'
                    }`,
                }),
            });

            return;
        }

        this.setState({loading: true});

        try {
            await API.owners.changeStatus(this.props.match.params.id, {
                status: 'returned',
                review: this.state.review,
            });

            owner.status = 'returned';
            owner.readyForReview = false;

            this.setState({
                owner,
                reviewError: '',
                isRejectOwnerStatusModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `OWNERS.STATUS_TOGGLE_ERROR_MESSAGE`,
                }),
                reviewError: '',
                isRejectOwnerStatusModalOpen: false,
            });
        }
    };

    handleReviewChange = ({target: {value}}) => {
        this.setState({review: value});
    };

    render() {
        const {
            owner: {readyForReview, isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/owners'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='ownerDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'OWNERS.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>Id</label>
                                <p>{this.state.owner.id}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'OWNERS.OWNER_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.owner.fullName}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'OWNERS.OWNER_EMAIL_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.owner.email}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'OWNERS.OWNER_PHONE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    ({this.state.owner.phoneCountry}){' '}
                                    {this.state.owner.phone}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'OWNERS.OWNER_ADDRESS_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.owner.address}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'OWNERS.OWNER_RFC_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.owner.rfc}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_PLACE_OF_BIRTH_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.owner.placeOfBirth}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'OWNERS.OWNER_BIRTH_AT_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {new Date(
                                        this.state.owner.birthAt,
                                    ).toLocaleDateString()}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_DOCUMENT_TYPE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.owner.documentType &&
                                        this.props.intl.formatMessage({
                                            id: `OWNERS.OWNER_DOCUMENT_TYPE_${this.state.owner.documentType.toUpperCase()}`,
                                        })}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'OWNERS.OWNER_STATUS_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: `OWNERS.OWNER_STATUS_${this.state.owner.status.toUpperCase()}`,
                                    })}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_IS_ACTIVE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.owner.isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No'}
                                </p>
                            </Col>
                            {this.state.owner.status === 'returned' && (
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'OWNERS.OWNER_REVIEW_FIELD_LABEL',
                                        })}
                                    </label>
                                    <p>{this.state.owner.review}</p>
                                </Col>
                            )}
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'OWNERS.BANK_ACCOUNT_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_ACCOUNT_NUMBER_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.owner.bankAccount.accountNumber}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_CLABE_NUMBER_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.owner.bankAccount.clabeNumber}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_HOLDER_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.owner.bankAccount.holderName}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'OWNERS.DOCUMENTS_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <p className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_DOCUMENT_FRONT_FIELD_LABEL',
                                    })}
                                </p>
                                <Image
                                    src={
                                        this.state.owner.ownerIdPictureUrl
                                            ?.front
                                    }
                                    className='img-fluid'
                                    alt={this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_DOCUMENT_FRONT_FIELD_LABEL',
                                    })}
                                />
                            </Col>
                            <Col md={6}>
                                <p className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.OWNER_DOCUMENT_BACK_FIELD_LABEL',
                                    })}
                                </p>
                                {this.state.owner.ownerIdPictureUrl?.back && (
                                    <Image
                                        src={
                                            this.state.owner.ownerIdPictureUrl
                                                .back
                                        }
                                        className='img-fluid'
                                        alt={this.props.intl.formatMessage({
                                            id:
                                                'OWNERS.OWNER_DOCUMENT_BACK_FIELD_LABEL',
                                        })}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-start'>
                    <div>
                        <Can run='OWNERS_CHANGE_STATUS'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: 'OWNERS.ACCEPT_OWNER_MODAL',
                                })}
                                handleConfirm={this.acceptOwnerHandler}
                                handleShow={this.toggleAcceptOwnerStatusModal}
                                handleClose={this.toggleAcceptOwnerStatusModal}
                                show={this.state.isAcceptOwnerStatusModalOpen}
                                disabled={!readyForReview}
                                variant='success'
                                buttonClassName='btn-bold btn-light-success'
                                buttonLabel={this.props.intl.formatMessage({
                                    id: 'OWNERS.ACCEPT_OWNER_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {id: 'OWNERS.CONFIRM_ACCEPT_OWNER'},
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.ACCEPT_OWNER_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div className='ml-4'>
                        <Can run='OWNERS_CHANGE_STATUS'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: 'OWNERS.REJECT_OWNER_MODAL',
                                })}
                                handleConfirm={this.rejectOwnerHandler}
                                handleShow={this.toggleRejectOwnerStatusModal}
                                handleClose={this.toggleRejectOwnerStatusModal}
                                show={this.state.isRejectOwnerStatusModalOpen}
                                disabled={!readyForReview}
                                variant='danger'
                                buttonClassName='btn-bold btn-light-danger'
                                buttonLabel={this.props.intl.formatMessage({
                                    id: 'OWNERS.REJECT_OWNER_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {id: 'OWNERS.CONFIRM_REJECT_OWNER'},
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'OWNERS.REJECT_OWNER_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                                <Form>
                                    <Form.Row>
                                        <Input
                                            md='12'
                                            type='textarea'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'OWNERS.OWNER_REVIEW_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'OWNERS.OWNER_REVIEW_PLACEHOLDER',
                                                },
                                            )}
                                            name='review'
                                            value={this.state.review}
                                            onChange={this.handleReviewChange}
                                            error={this.state.reviewError}
                                            isInvalid={this.state.reviewError}
                                        />
                                    </Form.Row>
                                </Form>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div className='ml-4'>
                        <Can run='OWNERS_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'OWNERS.BLOCK_OWNER_MODAL'
                                        : 'OWNERS.UNBLOCK_OWNER_MODAL',
                                })}
                                handleConfirm={this.toggleOwnerAvailability}
                                handleShow={this.toggleOwnerAvailabilityModal}
                                handleClose={this.toggleOwnerAvailabilityModal}
                                show={this.state.isOwnerAvailabilityModalOpen}
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'OWNERS.BLOCK_OWNER_MODAL'
                                        : 'OWNERS.UNBLOCK_OWNER_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'OWNERS.CONFIRM_BLOCK_OWNER'
                                            : 'OWNERS.CONFIRM_UNBLOCK_OWNER',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'OWNERS.BLOCK_OWNER_CONFIRMATION_MESSAGE'
                                            : 'OWNERS.UNBLOCK_OWNER_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'OWNERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/owners'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchOwner}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(OwnerDetail);
