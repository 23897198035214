import React from 'react';
import {NavLink} from 'react-router-dom';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';

import '../StarterList.scss';
import Form from 'react-bootstrap/Form';
import * as FeatherIcons from 'react-icons/fi';
import Pagination from 'react-js-pagination';
import {useIntl} from 'react-intl';

import AsyncAutocomplete from '../form/AsyncAutocomplete';
import Can from '../../common/security/Can';
import Datepicker from '../form/DatePicker';
import {formatDate} from '../../common/utils/helpers';
import LoadingButton from '../form/LoadingButton';

function StarterList(props) {
    const intl = useIntl();

    const toggleNotification = async (id) => {
        if (props.notifications) {
            props.toggleNotification(id);
        }

        return false;
    };

    return (
        <div className='starter-list'>
            <div className='mb-4 d-flex justify-content-between'>
                {props.hideSearch ? (
                    <div data-test='testFiltersHidden' />
                ) : (
                    <Form
                        data-test='testFilters'
                        inline
                        className='search-form'
                        onSubmit={(e) => e.preventDefault()}
                    >
                        {!props.hideSearchInput && (
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id='basic-addon1'>
                                        <FeatherIcons.FiSearch />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    placeholder={intl.formatMessage({
                                        id: 'LIST.TYPE_HERE_TO_SEARCH',
                                    })}
                                    aria-label='Username'
                                    aria-describedby='basic-addon1'
                                    name='search'
                                    value={props.searchKeyword}
                                    onChange={props.onSearchKeywordChange}
                                    data-test='searchInput'
                                />
                                {props.searchKeyword?.length > 0 ? (
                                    <InputGroup.Append>
                                        <Button
                                            variant='secondary'
                                            data-test='clearInput'
                                            onClick={() =>
                                                props.onSearchKeywordClear(
                                                    'search',
                                                )
                                            }
                                        >
                                            {intl.formatMessage({
                                                id: 'LIST.CLEAR',
                                            })}
                                        </Button>
                                    </InputGroup.Append>
                                ) : null}
                            </InputGroup>
                        )}
                        &nbsp;
                        {props.searchFields
                            ? props.searchFields.map((field, i) => (
                                  <InputGroup key={i}>
                                      <InputGroup.Prepend>
                                          <InputGroup.Text>
                                              <FeatherIcons.FiSearch />
                                          </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      {field.options ? (
                                          <Form.Control
                                              as='select'
                                              custom
                                              name={field.name}
                                              value={field.value}
                                              data-test='searchSelect'
                                              className='starter-select'
                                              onChange={
                                                  props.onSearchKeywordChange
                                              }
                                          >
                                              <option value=''>
                                                  {field.placeholder}
                                              </option>
                                              {field.options.map(
                                                  ({value, label}) => (
                                                      <option
                                                          value={value}
                                                          key={value}
                                                      >
                                                          {label}
                                                      </option>
                                                  ),
                                              )}
                                          </Form.Control>
                                      ) : field.autocomplete ? (
                                          <AsyncAutocomplete
                                              data-test='testAutocomplete'
                                              onChange={(val) =>
                                                  props.onAutocompleteOrDateSearchChange(
                                                      val,
                                                      field.name,
                                                  )
                                              }
                                              handleAutocompleteSearch={
                                                  props.handleAutocompleteSearch
                                              }
                                              onAutocompleteClear={() =>
                                                  props.onSearchKeywordClear(
                                                      field.name,
                                                  )
                                              }
                                              fieldValue={field.value}
                                              showClear={true}
                                              placeholder={field.placeholder}
                                              clearButtonLabel={intl.formatMessage(
                                                  {
                                                      id: 'LIST.CLEAR',
                                                  },
                                              )}
                                              id={`${field.name}_autocomplete`}
                                              width='220px'
                                              labelKey={field.labelKey}
                                          />
                                      ) : field.date ? (
                                          <Datepicker
                                              data-test='testDate'
                                              placeholder={field.placeholder}
                                              onChange={(val) =>
                                                  props.onAutocompleteOrDateSearchChange(
                                                      formatDate(
                                                          val.toLocaleDateString(),
                                                      ),
                                                      field.name,
                                                  )
                                              }
                                              value={field.value}
                                          />
                                      ) : (
                                          <Form.Control
                                              placeholder={field.placeholder}
                                              name={field.name}
                                              value={field.value}
                                              onChange={
                                                  props.onSearchKeywordChange
                                              }
                                          />
                                      )}
                                      {field.value && !field.autocomplete ? (
                                          <InputGroup.Append>
                                              <Button
                                                  variant='secondary'
                                                  data-test='specificClearInput'
                                                  onClick={() =>
                                                      props.onSearchKeywordClear(
                                                          field.name,
                                                      )
                                                  }
                                              >
                                                  {intl.formatMessage({
                                                      id: 'LIST.CLEAR',
                                                  })}
                                              </Button>
                                          </InputGroup.Append>
                                      ) : null}
                                  </InputGroup>
                              ))
                            : null}
                    </Form>
                )}
                <div
                    className={`d-flex justify-content-between ${
                        props.orderPermission ? 'btn-group-starter' : ''
                    }`}
                >
                    {props.addNewLink ? (
                        <Can run={props.addNewPermission}>
                            <LoadingButton
                                as={NavLink}
                                data-test='testAddNew'
                                className='btn-bold d-flex align-items-center'
                                variant='primary'
                                to={props.addNewLink}
                                loading={props.loading}
                            >
                                {intl.formatMessage({id: 'LIST.ADD_NEW'})}
                            </LoadingButton>
                        </Can>
                    ) : null}
                </div>
            </div>
            <Card>
                <Card.Body>
                    <Table
                        className='mb-0'
                        data-test='starterListTableComponent'
                    >
                        <thead>
                            <tr>
                                {props.data.headerTitles.map(
                                    (headerTitle, key) => (
                                        <th
                                            data-test='testTableHeader'
                                            key={key}
                                        >
                                            {headerTitle}
                                        </th>
                                    ),
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.rows.map((row, i) => (
                                <tr
                                    data-test='testTableRow'
                                    key={i}
                                    className='starter-list-item-row'
                                >
                                    {row.content.map((text, j) => (
                                        <td
                                            key={j}
                                            className='align-middle'
                                            style={{
                                                ...(props.notifications &&
                                                    !row.readAt && {
                                                        backgroundColor:
                                                            '#e3f2fd',
                                                    }),
                                            }}
                                        >
                                            {row.link ? (
                                                <a
                                                    className='d-block text-default'
                                                    onClick={() =>
                                                        toggleNotification(
                                                            row.content[0],
                                                        )
                                                    }
                                                    href={row.link}
                                                >
                                                    {typeof text === 'string' &&
                                                    text.includes(
                                                        'storage.s3',
                                                    ) ? (
                                                        <div
                                                            className='starter-list-column-icon'
                                                            style={{
                                                                backgroundColor: `#${row.color}`,
                                                            }}
                                                        >
                                                            <img
                                                                hidden={!text}
                                                                className='img-fluid'
                                                                src={text}
                                                                alt={`text`}
                                                                width='48px'
                                                            />
                                                        </div>
                                                    ) : (
                                                        text
                                                    )}
                                                </a>
                                            ) : typeof text === 'string' &&
                                              text.includes('storage.s3') ? (
                                                <img
                                                    hidden={!text}
                                                    className='img-fluid'
                                                    src={text}
                                                    alt={`text`}
                                                    width='48px'
                                                />
                                            ) : (
                                                <p>{text}</p>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {!props.hidePagination ? (
                <div
                    data-test='testPaginationContainer'
                    className='mt-4 d-flex justify-content-end'
                >
                    <Pagination
                        itemClass='page-item'
                        linkClass='page-link'
                        activePage={props.page}
                        itemsCountPerPage={props.pageSize}
                        totalItemsCount={props.total}
                        pageRangeDisplayed={5}
                        onChange={props.onPageChange}
                        data-test='testPagination'
                        prevPageText={<FeatherIcons.FiChevronLeft />}
                        nextPageText={<FeatherIcons.FiChevronRight />}
                        firstPageText={<FeatherIcons.FiChevronsLeft />}
                        lastPageText={<FeatherIcons.FiChevronsRight />}
                    />
                </div>
            ) : null}
        </div>
    );
}

export default StarterList;
