import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {Form, Row, Col} from 'react-bootstrap';

import API from '../../common/utils/API';
import Role from '../../common/models/Role';
import LoadingButton from '../../components/form/LoadingButton';
import Can from '../../common/security/Can';

export class RoleDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            role: new Role(),
            permissions: [],
            isLoadErrorModalOpen: false,
            permissionGroups: [],
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchRole(), this.fetchPermissions()]);
    }

    fetchPermissions = async () => {
        this.setState({loading: true});

        try {
            const {permissions} = await API.permissions.get();
            let permissionGroups = [...this.state.permissionGroups];

            permissionGroups = permissionGroups.concat(
                permissions.map((permission) => permission.group),
            );

            this.setState({
                permissionGroups,
                permissions,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    fetchRole = async () => {
        this.setState({
            loading: true,
        });

        try {
            const {role} = await API.roles.detail(this.props.match.params.id);
            let permissionGroups = [...this.state.permissionGroups];

            permissionGroups = permissionGroups.concat(
                role.permissions.map((permission) => permission.group),
            );
            this.setState({
                role,
                permissionGroups,
                isLoadErrorModalOpen: false,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        return (
            <>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/roles'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='roleDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ROLES.ROLE_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.role.name}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.PRIVILEGE_PROFILE_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body className='p-0'>
                        <Table className='my-0'>
                            {this.state.permissionGroups
                                .filter((x, i, a) => a.indexOf(x) === i)
                                .sort()
                                .map((group, i) => (
                                    <tr key={i}>
                                        <td>{group}</td>
                                        <td>
                                            {(
                                                this.state.permissions ||
                                                this.state.role.permissions
                                            )
                                                .filter(
                                                    (permission) =>
                                                        permission.group ===
                                                        group,
                                                )
                                                .map((permission) => (
                                                    <Row key={permission.code}>
                                                        <Col>
                                                            <Form.Row>
                                                                <Form.Check
                                                                    disabled
                                                                    type='switch'
                                                                    id={
                                                                        permission.code
                                                                    }
                                                                    label={
                                                                        permission.description
                                                                    }
                                                                    checked={this.state.role.permissions.find(
                                                                        (
                                                                            selectedPermission,
                                                                        ) =>
                                                                            permission.code ===
                                                                            selectedPermission.code,
                                                                    )}
                                                                    value={
                                                                        permission.code
                                                                    }
                                                                />
                                                            </Form.Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                        </td>
                                    </tr>
                                ))}
                        </Table>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-end'>
                    <Can run='ROLES_EDIT'>
                        <Button
                            as={NavLink}
                            to={`/roles/${this.props.match.params.id}/edit`}
                            className='btn-warning btn-bold'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.EDIT_ROLE_BUTTON_TEXT',
                            })}
                        </Button>
                    </Can>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/roles'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadRole}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(RoleDetail);
