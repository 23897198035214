export default class Category {
    constructor({
        id = 0,
        name = '',
        order = 0,
        iconUrl = '',
        isActive = false,
        updatedAt = '',
        createdAt = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.order = order;
        this.iconUrl = iconUrl;
        this.isActive = isActive;
        this.updatedAt = updatedAt;
        this.createdAt = createdAt;
    }
}
