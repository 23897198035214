import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import PaymentProviderList from './PaymentProviderList';
import PaymentProviderDetail from './PaymentProviderDetail';

function PaymentProviders() {
    const intl = useIntl();

    return (
        <Switch data-test='paymentProvidersComponent'>
            <PrivateRoute
                permission='PAYMENT_PROVIDERS_INDEX'
                exact
                path='/payment-providers'
                component={withLayout(
                    PaymentProviderList,
                    intl.formatMessage({id: 'ROUTES.PAYMENT_PROVIDERS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='PAYMENT_PROVIDERS_SHOW'
                exact
                path='/payment-providers/:id'
                component={withLayout(
                    PaymentProviderDetail,
                    intl.formatMessage({id: 'ROUTES.PAYMENT_PROVIDERS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default PaymentProviders;
