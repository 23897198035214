import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import BookingList from './BookingList';
import BookingDetail from './BookingDetail';

function Boxes() {
    const intl = useIntl();

    return (
        <Switch data-test='bookingsComponent'>
            <PrivateRoute
                permission='BOOKINGS_INDEX'
                exact
                path='/bookings'
                component={withLayout(
                    BookingList,
                    intl.formatMessage({id: 'ROUTES.BOOKINGS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='BOOKINGS_SHOW'
                exact
                path='/bookings/:id'
                component={withLayout(
                    BookingDetail,
                    intl.formatMessage({id: 'ROUTES.BOOKINGS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Boxes;
