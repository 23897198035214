import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import StarterList from '../../components/starter/StarterList';

export class VenueList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            venues: [],
            cities: [],

            // Meta pagination
            page: 1,
            pageSize: 12,
            total: 0,

            // Search
            searchTimeout: 0,
            search: '',
            city: '',

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchVenues(), this.fetchCities()]);
    }

    fetchVenues = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const result = await API.venues.get({
                page: pageNumber,
                search: this.state.search,
                city: this.state.city,
            });

            this.setState({
                venues: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchCities = async () => {
        this.setState({loading: true});

        try {
            const {cities} = await API.cities.getAll();

            this.setState({
                cities,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchVenues(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchVenues(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchVenues(1);
            },
        );
    };

    render() {
        const citiesOptions = this.state.cities.map((city) => ({
            value: city.id,
            label: city.name,
        }));

        return (
            <div data-test='venueListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'VENUES.VENUE_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'VENUES.VENUE_ADDRESS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'VENUES.VENUE_CITY_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'VENUES.VENUE_CONTACT_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'VENUES.VENUE_IS_ACTIVE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.venues.map((venue) => ({
                            content: [
                                venue.id,
                                venue.name,
                                venue.address || '-',
                                venue.city?.name || '',
                                venue.contact,
                                venue.isActive
                                    ? this.props.intl.formatMessage({
                                          id: 'GENERAL.YES',
                                      })
                                    : 'No',
                            ],
                            link: `/venues/${venue.id}`,
                        })),
                    }}
                    addNewPermission={'VENUES_CREATE'}
                    addNewLink={`venues/create`}
                    searchFields={[
                        {
                            options: citiesOptions,
                            name: 'city',
                            placeholder: this.props.intl.formatMessage({
                                id: 'VENUES.VENUE_FILTER_BY_CITY',
                            }),
                            value: this.state.city,
                        },
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(VenueList);
