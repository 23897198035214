import {API} from '../API';

const reports = {
    getIncomeReport: async (filters = {}) => {
        const {data: response} = await API.get(`/reports/income`, {
            params: filters,
        });

        return response;
    },
    getBoxesReport: async (filters = {}) => {
        const {data: response} = await API.get(`/reports/boxes`, {
            params: filters,
        });
        return response;
    },
    getUserAgesReport: async () => {
        const {data: response} = await API.get(`/reports/users/age`);
        return response;
    },
    getActiveUsersReport: async () => {
        const {data: response} = await API.get(`/reports/users/active`);
        return response;
    },
    getUserSignUpsReport: async (filters = {}) => {
        const {data: response} = await API.get(`/reports/users/count`, {
            params: filters,
        });
        return response;
    },
    getFutureBookings: async () => {
        const {data: response} = await API.get(`/reports/bookings/future`);
        return response;
    },
};

export default reports;
