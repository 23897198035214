import {API} from '../API';
import PaymentMethod from '../../models/PaymentMethod';

const paymentMethods = {
    get: async () => {
        const {data: response} = await API.get('/payment-methods');

        return {
            paymentMethods: response.data.map(
                (paymentMethod) => new PaymentMethod(paymentMethod),
            ),
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/payment-methods/${id}`);

        return {
            paymentMethod: new PaymentMethod(response.data),
        };
    },
    activate: async (id) => {
        await API.put(`/payment-methods/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/payment-methods/${id}/deactivate`);

        return {};
    },
};

export default paymentMethods;
