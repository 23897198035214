import React from 'react';
import LoadingButton from './LoadingButton';
import {Button, Modal} from 'react-bootstrap';

import * as Fi from 'react-icons/fi';

function ConfirmModal(props) {
    return (
        <>
            <Button
                disabled={props.disabled}
                variant={props.variant}
                className={props.buttonClassName}
                onClick={props.handleShow}
            >
                {props.buttonLabel}
            </Button>

            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeLabel={props.buttonCloseLabel}>
                    <Modal.Title as='span'>{props.titleModal}</Modal.Title>
                    <Button variant='link' onClick={props.handleClose}>
                        <Fi.FiX />
                    </Button>
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        className='btn-bold btn-light-dark'
                        onClick={props.handleClose}
                    >
                        {props.buttonCloseLabel}
                    </Button>
                    <LoadingButton
                        disabled={props.disabledConfirmButton}
                        variant={props.variant}
                        onClick={props.handleConfirm}
                        loading={props.buttonAcceptLoading}
                        className={props.buttonClassName}
                    >
                        {props.buttonAcceptLabel}
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ConfirmModal;
