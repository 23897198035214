import React, {useState, useRef} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {compose, withProps} from 'recompose';
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs,
} from 'react-google-maps';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteVenueModalOpen, setIsDeleteVenueModalOpen] = useState(false);
    const mapRef = useRef();

    const MyMapComponent = compose(
        withProps({
            googleMapURL:
                'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAvjLB_6O78Gp2BJG9n3joz4d25pKqL7hs',
            loadingElement: <div style={{height: `100%`, width: '100%'}} />,
            containerElement: <div style={{height: `400px`, width: '100%'}} />,
            mapElement: <div style={{height: `100%`, width: '100%'}} />,
        }),
        withScriptjs,
        withGoogleMap,
    )((props) => (
        <GoogleMap
            ref={props.mapRef}
            defaultZoom={15}
            defaultCenter={{
                lat: Number(props.latitude),
                lng: Number(props.longitude),
            }}
        >
            <Marker
                draggable={true}
                onDragEnd={(e) => {
                    props.setFieldValue('latitude', e.latLng.lat());
                    props.setFieldValue('longitude', e.latLng.lng());
                }}
                position={{
                    lat: Number(props.latitude),
                    lng: Number(props.longitude),
                }}
            />
        </GoogleMap>
    ));

    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({id: 'VENUES.VALIDATION.NAME_MISSING'}),
        ),
        city: Yup.string().required(
            intl.formatMessage({id: 'VENUES.VALIDATION.CITY_MISSING'}),
        ),
        address: Yup.string().required(
            intl.formatMessage({id: 'VENUES.VALIDATION.ADDRESS_MISSING'}),
        ),
        contact: Yup.string()
            .matches(
                new RegExp(
                    '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
                ),
                intl.formatMessage({
                    id: 'VENUES.VALIDATION.CONTACT_INVALID_FORMAT',
                }),
            )
            .required(
                intl.formatMessage({id: 'VENUES.VALIDATION.CONTACT_MISSING'}),
            ),
        picture: Yup.array().min(0).required(),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const onDrop = (
        acceptedFiles,
        rejectedFiles,
        setFieldValue,
        setFieldError,
    ) => {
        const _URL = window.URL || window.webkitURL;
        /* istanbul ignore else */
        if (!rejectedFiles.length) {
            // validate min width for image
            const img = new Image();
            img.src = _URL.createObjectURL(acceptedFiles[0]);
            img.onload = function () {
                if (this.width >= 1000) {
                    const picture = acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        }),
                    );
                    setFieldError('picture', null);
                    setFieldValue('picture', [...picture]);
                    setFieldValue('pictureWidth', this.width);
                    setFieldValue('pictureHeight', this.height);
                } else {
                    setFieldError(
                        'picture',
                        intl.formatMessage({
                            id: 'VENUES.VALIDATION.IMAGE_WIDTH',
                        }),
                    );
                }
            };
        }
    };

    const handleToggleIsDeleteVenueModal = () => {
        setIsDeleteVenueModalOpen(!isDeleteVenueModalOpen);
    };

    return (
        <Formik
            data-test='venueFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
                setFieldError,
            }) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'VENUES.GENERAL_INFORMATION_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='3'>
                                        <Dropzone
                                            data-test='dropzone'
                                            accept='image/*'
                                            onDrop={(
                                                acceptedFiles,

                                                rejectedFiles,
                                            ) =>
                                                onDrop(
                                                    acceptedFiles,

                                                    rejectedFiles,

                                                    setFieldValue,

                                                    setFieldError,
                                                )
                                            }
                                            multiple={false}
                                        >
                                            {({
                                                getRootProps,

                                                getInputProps,

                                                isDragActive,
                                            }) => (
                                                <section>
                                                    <div
                                                        {...getRootProps()}
                                                        className={classNames(
                                                            'dropzone',

                                                            {
                                                                'dropzone--isActive': isDragActive,
                                                            },
                                                        )}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />

                                                        {!values.picture
                                                            .length &&
                                                        !props.preview ? (
                                                            isDragActive ? (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'VENUES.IMAGE_DRAG_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'VENUES.IMAGE_DROP_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            )
                                                        ) : null}

                                                        {values.picture
                                                            .length ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    {values.picture.map(
                                                                        (
                                                                            file,

                                                                            i,
                                                                        ) => (
                                                                            <img
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className='img-fluid'
                                                                                src={
                                                                                    file.preview
                                                                                }
                                                                                alt={`${values.name}`}
                                                                            />
                                                                        ),
                                                                    )}
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : props.preview ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    <img
                                                                        className='img-fluid'
                                                                        src={
                                                                            props.preview
                                                                        }
                                                                        alt={`${values.name}`}
                                                                    />
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : null}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {errors.picture ? (
                                            <small className='text-danger form-text'>
                                                {errors.picture}
                                            </small>
                                        ) : (
                                            ''
                                        )}
                                    </Col>

                                    <Col md='9'>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'VENUES.VENUE_NAME_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'VENUES.VENUE_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='name'
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name}
                                                isInvalid={errors.name}
                                            />

                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'VENUES.VENUE_ADDRESS_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'VENUES.VENUE_ADDRESS_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='address'
                                                value={values.address}
                                                onChange={handleChange}
                                                error={errors.address}
                                                isInvalid={errors.address}
                                            />
                                        </BaseForm.Row>

                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='email'
                                                label={intl.formatMessage({
                                                    id:
                                                        'VENUES.VENUE_CONTACT_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'VENUES.VENUE_CONTACT_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='contact'
                                                value={values.contact}
                                                onChange={handleChange}
                                                error={errors.contact}
                                                isInvalid={errors.contact}
                                            />
                                            <Input
                                                md='6'
                                                type='select'
                                                label={intl.formatMessage({
                                                    id:
                                                        'VENUES.VENUE_CITY_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'VENUES.VENUE_CITY_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='city'
                                                value={values.city}
                                                onChange={handleChange}
                                                error={errors.city}
                                                isInvalid={errors.city}
                                                options={props.cities}
                                            />
                                        </BaseForm.Row>

                                        <BaseForm.Row>
                                            <label className='form-label'>
                                                {intl.formatMessage({
                                                    id:
                                                        'VENUES.VENUE_LOCATION_FIELD_LABEL',
                                                })}
                                            </label>
                                            <MyMapComponent
                                                mapRef={mapRef}
                                                latitude={values.latitude}
                                                longitude={values.longitude}
                                                setFieldValue={setFieldValue}
                                            />
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <div>
                                <div>
                                    <Can run='VENUES_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'VENUES.DELETE_VENUE_MODAL_TITLE',
                                            })}
                                            handleConfirm={
                                                props.handleDeleteVenue
                                            }
                                            handleShow={
                                                handleToggleIsDeleteVenueModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteVenueModal
                                            }
                                            show={isDeleteVenueModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id:
                                                    'VENUES.DELETE_VENUE_BUTTON_LABEL',
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'VENUES.CONFIRM_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'VENUES.CANCEL_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'VENUES.DELETE_VENUE_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'VENUES.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
