import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

import API from '../../common/utils/API';
import {socket} from '../../common/utils/socket';
import StarterList from '../../components/starter/StarterList';

export class NotificationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],

            // Socket for live rendering
            socket: null,

            // Meta Pagination
            page: 1,
            pageSize: 12,
            total: 0,

            // Search
            searchTimeout: 0,
            search: '',
            createdAt: '',

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        if (!this.state.socket) {
            socket.on('notificationStored', () => {
                return this.fetchNotifications();
            });

            this.setState({socket});
        }

        await this.fetchNotifications();
    }

    fetchNotifications = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const result = await API.notifications.get(pageNumber, {
                search: this.state.search,
                date: this.state.createdAt,
            });

            this.setState({
                notifications: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchNotifications(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchNotifications(1);
            }, 300),
        });
    };

    handleDateSearchChange = (value) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            createdAt: value || '',
            searchTimeout: setTimeout(() => {
                return this.fetchNotifications(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field) => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchNotifications(1);
            },
        );
    };

    toggleNotification = async (id) => {
        this.setState({loading: true});

        try {
            await API.notifications.markAsRead(id);
        } catch (e) {
            this.setState({loading: false, error: true});
        }
    };

    render() {
        return (
            <div data-test='notificationListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onAutocompleteOrDateSearchChange={
                        this.handleDateSearchChange
                    }
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    notifications={true}
                    toggleNotification={this.toggleNotification}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id:
                                    'NOTIFICATIONS.NOTIFICATION_TITLE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'NOTIFICATIONS.NOTIFICATION_USER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'NOTIFICATIONS.NOTIFICATION_CREATED_AT_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.notifications.map((notification) => ({
                            content: [
                                notification.id,
                                notification.title,
                                notification.userFullName,
                                (notification.createdAt &&
                                    moment(notification.createdAt).format(
                                        'D/M/YYYY h:mm:ss A',
                                    )) ||
                                    '',
                            ],
                            link: `/notifications/${notification.id}`,
                            readAt: notification.readAt,
                        })),
                    }}
                    searchFields={[
                        {
                            name: 'createdAt',
                            value: this.state.createdAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id:
                                    'NOTIFICATIONS.NOTIFICATION_CREATED_AT_FIELD_PLACEHOLDER',
                            }),
                        },
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(NotificationList);
