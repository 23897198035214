import {API} from '../API';
import Notification from '../../models/Notification';
import PaginatedList from '../PaginatedList';

const notifications = {
    get: async (page = 1, filters = {}) => {
        const {data: response} = await API.get(`/notifications/?page=${page}`, {
            params: filters,
        });

        const list = new PaginatedList();
        list.items = response.data.map(
            (notification) => new Notification(notification),
        );
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/notifications/${id}`);

        return {
            notification: new Notification(response.data),
        };
    },

    markAsRead: async (id) => {
        await API.put(`/notifications/${id}/read`);

        return {};
    },
    count: async () => {
        const {data: response} = await API.get('/notifications/count');

        return response.data;
    },
};

export default notifications;
