import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Service from '../../models/Service';

const services = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/services', {
            params: filters,
        });

        const list = new PaginatedList();

        list.items = response.data.map((service) => new Service(service));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    getAll: async () => {
        const {data: response} = await API.get('/services/all');

        return {
            services: response.data.map((service) => new Service(service)),
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/services/${id}`);

        return {
            service: new Service(response.data),
        };
    },
    save: async (params) => {
        await API.post('/services', params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {};
    },
    update: async (id, params) => {
        await API.put(`/services/${id}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {};
    },
    delete: async (id) => {
        await API.delete(`/services/${id}`);

        return {};
    },
};

export default services;
