import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';
import Form from '../../components/form/services/ServiceForm';
import Service from '../../common/models/Service';
import ErrorModal from '../../components/ui/ErrorModal';

export class EditService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            service: new Service(),

            // Redirects
            shouldRedirectToIndex: false,
            shouldRedirectToDetail: false,
            redirectSuccessMessage: '',

            // Errors
            isLoadErrorModalOpen: false,
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchService();
    }

    fetchService = async () => {
        this.setState({loading: true});

        try {
            const {service} = await API.services.detail(
                this.props.match.params.id,
            );

            this.setState({
                service,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        const formData = new FormData();

        this.setState({loading: true});

        formData.append('name', input.name);
        formData.append('order', input.order);

        /* istanbul ignore else */
        if (input.picture?.length) {
            formData.append('picture', input.picture[0]);
        }

        try {
            await API.services.update(this.props.match.params.id, formData);

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'SERVICES.SERVICE_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.UPDATING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    handleDeleteService = async () => {
        this.setState({loading: true});

        try {
            await API.services.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'SERVICES.SERVICE_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        const {
            shouldRedirectToDetail,
            shouldRedirectToIndex,
            redirectSuccessMessage: successMessage,
        } = this.state;

        const initialValues = {
            name: this.state.service?.name || '',
            order: this.state.service?.order || 0,
            picture: [],
        };

        return shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/services/${this.props.match.params.id}`,
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/services',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id:
                                'SERVICES.SERVICE_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='updateServiceComponent'
                    initialValues={initialValues}
                    backRedirectUrl={`/services/${this.props.match.params.id}`}
                    backText={this.props.intl.formatMessage({
                        id: 'GENERAL.BACK_TO_DETAIL',
                    })}
                    preview={this.state.service.iconUrl}
                    isEdit={true}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                    handleDeleteService={this.handleDeleteService}
                />
                <ErrorModal
                    isLoadErrorModalOpen={this.state.isLoadErrorModalOpen}
                    toggleLoadErrorModal={this.toggleLoadErrorModal}
                    errorMessage={this.props.intl.formatMessage({
                        id: 'SERVICES.ERROR_MODAL_TEXT',
                    })}
                    returnUrl={`/services/${this.props.match.params.id}`}
                    loading={this.state.loading}
                    handleRetryClick={this.fetchService}
                />
            </>
        );
    }
}

export default injectIntl(EditService);
