import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import {NavLink} from 'react-router-dom';

import State from '../../common/models/State';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import LoadingButton from '../../components/form/LoadingButton';

export class StateDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            state: new State(),

            // Enable/Disable Modal
            isStateAvailabilityModalOpen: false,

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchState();
    }

    fetchState = async () => {
        this.setState({loading: true});

        try {
            const {state} = await API.states.detail(this.props.match.params.id);

            this.setState({
                state,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleStateAvailabilityModal = () => {
        this.setState({
            isStateAvailabilityModalOpen: !this.state
                .isStateAvailabilityModalOpen,
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleStateAvailability = async () => {
        const {state} = this.state;

        this.setState({loading: true});

        try {
            if (state.isActive) {
                await API.states.deactivate(this.props.match.params.id);

                state.isActive = false;
            } else {
                await API.states.activate(this.props.match.params.id);

                state.isActive = true;
            }

            this.setState({
                state,
                isStateAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `STATES.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isStateAvailabilityModalOpen: false,
            });
        }
    };

    render() {
        const {
            state: {isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/states'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='stateDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'STATES.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>Id</label>
                                <p>{this.state.state.id}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'STATES.STATE_CODE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.state.code}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'STATES.STATE_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.state.name}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'STATES.STATE_COUNTRY_LABEL',
                                    })}
                                </label>
                                <p>{this.state.state.country}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'STATES.STATE_IS_ACTIVE_LABEL',
                                    })}
                                </label>
                                <p>
                                    {isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No'}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'STATES.STATE_CREATED_AT_LABEL',
                                    })}
                                </label>
                                <p>{this.state.state.createdAt}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'STATES.STATE_UPDATED_AT_LABEL',
                                    })}
                                </label>
                                <p>{this.state.state.updatedAt}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='STATES_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'STATES.BLOCK_STATE_MODAL'
                                        : 'STATES.UNBLOCK_STATE_MODAL',
                                })}
                                handleConfirm={this.toggleStateAvailability}
                                handleShow={this.toggleStateAvailabilityModal}
                                handleClose={this.toggleStateAvailabilityModal}
                                show={this.state.isStateAvailabilityModalOpen}
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'STATES.BLOCK_STATE_MODAL'
                                        : 'STATES.UNBLOCK_STATE_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'STATES.CONFIRM_BLOCK_STATE'
                                            : 'STATES.CONFIRM_UNBLOCK_STATE',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'STATES.BLOCK_STATE_CONFIRMATION_MESSAGE'
                                            : 'STATES.UNBLOCK_STATE_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div>
                        <Can run='STATES_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/states/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'STATES.EDIT_STATE_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'STATES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/states'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchState}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(StateDetail);
