export default class Booking {
    constructor({
        id = 0,
        key = '',
        boxType = '',
        status = 'pending',
        ticketsDeliveredAt = '',
        ticketsSentAt = '',
        total = 0,
        folio = '',
        startsAt = '',
        endsAt = '',
        ownerTotal = 0,
        ownerPaymentReference = '',
        buyer = {},
        box = {},
        event = {},
        venue = {},
        payment = {},
        payments = [],
        address = {},
        createdAt = '',
        updatedAt = '',
        canceledAt = '',
    } = {}) {
        this.id = id;
        this.key = key;
        this.boxType = boxType;
        this.status = status;
        this.ticketsDeliveredAt = ticketsDeliveredAt;
        this.ticketsSentAt = ticketsSentAt;
        this.total = total;
        this.folio = folio;
        this.startsAt = startsAt;
        this.endsAt = endsAt;
        this.buyer = buyer;
        this.payments = payments;
        this.box = box;
        this.venue = venue;
        this.event = event;
        this.payment = payment;
        this.address = address;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.canceledAt = canceledAt;
        this.ownerTotal = ownerTotal;
        this.ownerPaymentReference = ownerPaymentReference;
    }
}
