export default class Category {
    constructor({
        id = 0,
        name = '',
        pictureUrl = '',
        duration = '',
        startsAt = '',
        venue = {},
        isActive = false,
        categories = [],
        createdAt = '',
        updatedAt = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.pictureUrl = pictureUrl;
        this.duration = duration;
        this.startsAt = startsAt;
        this.venue = venue;
        this.isActive = isActive;
        this.categories = categories;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}
