import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Formik} from 'formik';
import * as Yup from 'yup';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Input from '../Input';
import LoadingButton from '../LoadingButton';

export default function Form(props) {
    const intl = useIntl();
    const validationSchema = Yup.object({
        guest_fee: Yup.number().min(0).max(1),
        host_fee: Yup.number().min(0).max(1),
        iva: Yup.number().min(0).max(1),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    return (
        <Formik
            data-test='serviceFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id: 'SETTINGS.FEES_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='12'>
                                        <BaseForm.Row>
                                            <Input
                                                md='12'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'SETTINGS.GUEST_FEE_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'SETTINGS.GUEST_FEE_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='guest'
                                                value={values.guest}
                                                onChange={handleChange}
                                                error={errors.guest}
                                                isInvalid={errors.guest}
                                            />
                                            <Input
                                                md='12'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'SETTINGS.HOST_FEE_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'SETTINGS.HOST_FEE_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='host'
                                                value={values.host}
                                                onChange={handleChange}
                                                error={errors.host}
                                                isInvalid={errors.host}
                                            />
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id: 'SETTINGS.TAXES_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Input
                                        md='12'
                                        type='text'
                                        label='IVA'
                                        placeholder={intl.formatMessage({
                                            id:
                                                'SETTINGS.IVA_FIELD_PLACEHOLDER',
                                        })}
                                        name='IVA'
                                        value={values.IVA}
                                        onChange={handleChange}
                                        error={errors.IVA}
                                        isInvalid={errors.IVA}
                                    />
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>

                        <div className='text-center'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'GENERAL.SAVE',
                                })}
                            </LoadingButton>
                        </div>
                    </BaseForm>
                </>
            )}
        </Formik>
    );
}
