import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Box from '../../models/Box';

const boxes = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/boxes', {
            params: filters,
        });

        const list = new PaginatedList();

        list.items = response.data.map((box) => new Box(box));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/boxes/${id}`);
        return {
            box: new Box(response.data),
        };
    },
    changeStatus: async (id, input) => {
        await API.put(`/boxes/${id}/status`, input);

        return {};
    },
    activate: async (id) => {
        await API.put(`/boxes/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/boxes/${id}/deactivate`);

        return {};
    },
};

export default boxes;
