import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import Form from '../../components/form/venues/VenueForm';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';

export class CreateVenue extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cities: [],

            // Redirects
            shouldRedirectToIndex: false,

            // Errors
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        return this.fetchCities();
    }

    fetchCities = async () => {
        this.setState({loading: true});

        try {
            const {cities} = await API.cities.getAll();

            this.setState({
                cities,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});
        const formData = new FormData();

        formData.append('name', input.name);
        formData.append('city', input.city);
        formData.append('address', input.address);
        formData.append('contact', input.contact);
        formData.append('latitude', input.latitude);
        formData.append('longitude', input.longitude);

        /* istanbul ignore else */
        if (input.picture?.length) {
            formData.append('picture', input.picture[0]);
            formData.append('pictureWidth', input.pictureWidth);
            formData.append('pictureHeight', input.pictureHeight);
        }

        try {
            await API.venues.save(formData);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.STORING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    render() {
        const {shouldRedirectToIndex} = this.state;
        const cities = this.state.cities.map((city) => ({
            value: city.id,
            label: city.name,
        }));
        const initialValues = {
            name: '',
            city: '',
            address: '',
            contact: '',
            latitude: '20.684697',
            longitude: '-103.381762',
            picture: [],
            pictureWidth: 0,
            pictureHeight: 0,
        };

        return shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/venues',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'VENUES.VENUE_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='createVenueComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/venues'
                    backText={this.props.intl.formatMessage({
                        id: 'LIST.BACK_TO_LIST',
                    })}
                    cities={cities}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                />
            </>
        );
    }
}

export default injectIntl(CreateVenue);
