export default class Owner {
    constructor({
        id = 0,
        fullName = '',
        email = '',
        phone = '',
        phoneCountry = '',
        placeOfBirth = '',
        rfc = '',
        documentType = '',
        ownerIdPictureUrl = {front: '', back: ''},
        birthAt = '',
        isActive = false,
        status = 'pending',
        readyForReview = false,
        address = '',
        proofOfResidencyPictureUrl = '',
        review = '',
        bankAccount = {
            accountNumber: '',
            clabeNumber: '',
            holderName: '',
        },
    } = {}) {
        this.id = id;
        this.fullName = fullName;
        this.email = email;
        this.phone = phone;
        this.phoneCountry = phoneCountry;
        this.placeOfBirth = placeOfBirth;
        this.rfc = rfc;
        this.ownerIdPictureUrl = ownerIdPictureUrl;
        this.birthAt = birthAt;
        this.isActive = isActive;
        this.documentType = documentType;
        this.status = status;
        this.readyForReview = readyForReview;
        this.address = address;
        this.proofOfResidencyPictureUrl = proofOfResidencyPictureUrl;
        this.review = review;
        this.bankAccount = bankAccount;
    }
}
