import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import BoxList from './BoxList';
import BoxDetail from './BoxDetail';

function Boxes() {
    const intl = useIntl();

    return (
        <Switch data-test='boxesComponent'>
            <PrivateRoute
                permission='BOXES_INDEX'
                exact
                path='/boxes'
                component={withLayout(
                    BoxList,
                    intl.formatMessage({id: 'ROUTES.BOXES.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='BOXES_SHOW'
                exact
                path='/boxes/:id'
                component={withLayout(
                    BoxDetail,
                    intl.formatMessage({id: 'ROUTES.BOXES.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Boxes;
