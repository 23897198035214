import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import {NavLink} from 'react-router-dom';

import Venue from '../../common/models/Venue';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import LoadingButton from '../../components/form/LoadingButton';
import {compose, withProps} from 'recompose';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";

export class VenueDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            venue: new Venue(),

            // Enable/Disable Modal
            isVenueAvailabilityModalOpen: false,

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchVenue();
    }

    fetchVenue = async () => {
        this.setState({loading: true});

        try {
            const {venue} = await API.venues.detail(this.props.match.params.id);

            this.setState({
                venue,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleVenueAvailabilityModal = () => {
        this.setState({
            isVenueAvailabilityModalOpen: !this.state
                .isVenueAvailabilityModalOpen,
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleVenueAvailability = async () => {
        const {venue} = this.state;

        this.setState({loading: true});

        try {
            if (venue.isActive) {
                await API.venues.deactivate(this.props.match.params.id);

                venue.isActive = false;
            } else {
                await API.venues.activate(this.props.match.params.id);

                venue.isActive = true;
            }

            this.setState({
                venue,
                isVenueAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `VENUES.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isStateAvailabilityModalOpen: false,
            });
        }
    };

    render() {
        const {
            venue: {isActive},
        } = this.state;

        const MyMapComponent = compose(
            withProps({
                googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAvjLB_6O78Gp2BJG9n3joz4d25pKqL7hs",
                loadingElement: <div style={{ height: `100%`, width: '100%' }} />,
                containerElement: <div style={{ height: `400px`, width: '100%' }} />,
                mapElement: <div style={{ height: `100%`, width: '100%' }} />,
            }),
            withScriptjs,
            withGoogleMap,
        )((props) => (
            <GoogleMap
                defaultZoom={15}
                defaultCenter={{
                    lat: Number(props.latitude),
                    lng: Number(props.longitude),
                }}
            >
                <Marker
                    draggable={false}
                    position={{
                        lat: Number(props.latitude),
                        lng: Number(props.longitude),
                    }}
                />
            </GoogleMap>
        ));

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/venues'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='venueDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'VENUES.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <img
                                    className='img-fluid'
                                    src={this.state.venue.pictureUrl}
                                    alt={`${this.state.venue.name}`}
                                />
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            Id
                                        </label>
                                        <p>{this.state.venue.id}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_ADDRESS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.address}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_CONTACT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.contact}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_EVENTS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.state.venue.events.length > 0
                                                ? this.state.venue.events
                                                      .map(
                                                          (event) => event.name,
                                                      )
                                                      .join(', ')
                                                : this.props.intl.formatMessage(
                                                      {
                                                          id:
                                                              'VENUES.VENUE_NO_EVENT_TEXT',
                                                      },
                                                  )}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_BOXES_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.boxes}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_COUNTRY_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.country.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_STATE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.state.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_CITY_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.city.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_IS_ACTIVE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {isActive
                                                ? this.props.intl.formatMessage(
                                                      {
                                                          id: 'GENERAL.YES',
                                                      },
                                                  )
                                                : 'No'}
                                        </p>
                                    </Col>
                                    <Col md={12}>
                                        <label className='field-title'>
                                           Ubicación
                                        </label>
                                        <MyMapComponent
                                            latitude={this.state.venue.latitude}
                                            longitude={this.state.venue.longitude}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_CREATED_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.createdAt}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'VENUES.VENUE_UPDATED_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.venue.updatedAt}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='VENUE_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'VENUES.BLOCK_VENUE_MODAL'
                                        : 'VENUES.UNBLOCK_VENUE_MODAL',
                                })}
                                handleConfirm={this.toggleVenueAvailability}
                                handleShow={this.toggleVenueAvailabilityModal}
                                handleClose={this.toggleVenueAvailabilityModal}
                                show={this.state.isVenueAvailabilityModalOpen}
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'VENUES.BLOCK_VENUE_MODAL'
                                        : 'VENUES.UNBLOCK_VENUE_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'VENUES.CONFIRM_BLOCK_VENUE'
                                            : 'VENUES.CONFIRM_UNBLOCK_VENUE',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'VENUES.BLOCK_VENUE_CONFIRMATION_MESSAGE'
                                            : 'VENUES.BLOCK_VENUE_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div>
                        <Can run='VENUES_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/venues/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'VENUES.EDIT_VENUE_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'VENUES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/venues'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchVenue}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(VenueDetail);
