import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';
import Form from '../../components/form/categories/CategoryForm';

export class CreateCategory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // Categories orders available
            categoriesOrder: [],

            // Redirects
            shouldRedirectToIndex: false,

            // Errors
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchCategories();
    }

    fetchCategories = async () => {
        this.setState({loading: true});

        try {
            const {categories} = await API.categories.getAll();
            let categoriesOrder = [{value: 1, label: 1}];

            /* istanbul ignore else */
            if (categories.length > 0) {
                categoriesOrder = categories.map((category) => ({
                    value: category.order,
                    label: category.order,
                }));

                categoriesOrder.push({
                    value: categories[categories.length - 1].order + 1,
                    label: categories[categories.length - 1].order + 1,
                });
            }
            this.setState({
                categoriesOrder,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        const formData = new FormData();

        this.setState({loading: true});

        formData.append('name', input.name);
        formData.append('order', input.order);

        /* istanbul ignore else */
        if (input.picture?.length) {
            formData.append('picture', input.picture[0]);
        }

        try {
            await API.categories.save(formData);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.STORING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    render() {
        const {shouldRedirectToIndex} = this.state;
        const initialValues = {
            name: '',
            order: '',
            picture: [],
        };

        return shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/categories',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id:
                                'CATEGORIES.CATEGORY_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='createCategoryComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/categories'
                    backText={this.props.intl.formatMessage({
                        id: 'LIST.BACK_TO_LIST',
                    })}
                    categoriesOrder={this.state.categoriesOrder}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                />
            </>
        );
    }
}

export default injectIntl(CreateCategory);
