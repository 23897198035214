import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API';

export class CountryList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            countries: [],

            // Meta Pagination
            page: 1,
            pageSize: 12,
            total: 0,

            // Search
            searchTimeout: 0,
            search: '',

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchCountries();
    }

    fetchCountries = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const result = await API.countries.get({
                page: pageNumber,
                search: this.state.search,
            });

            this.setState({
                countries: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchCountries(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchCountries(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchCountries(1);
            },
        );
    };

    render() {
        return (
            <div data-test='countryListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'COUNTRIES.COUNTRY_CODE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COUNTRIES.COUNTRY_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COUNTRIES.COUNTRY_IS_ACTIVE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.countries.map((country) => ({
                            content: [
                                country.id,
                                country.code,
                                country.name,
                                country.isActive
                                    ? this.props.intl.formatMessage({
                                          id: 'GENERAL.YES',
                                      })
                                    : 'No',
                            ],
                            link: `/countries/${country.id}`,
                        })),
                    }}
                    addNewPermission={'COUNTRIES_CREATE'}
                    addNewLink={`/countries/create`}
                />
            </div>
        );
    }
}

export default injectIntl(CountryList);
