import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import API from '../../common/utils/API';
import LoadingButton from '../../components/form/LoadingButton';
import User from '../../common/models/User';
import Form from '../../components/form/users/UserForm';

export class EditUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            user: new User(),
            errorMessage: '',
            roles: [],
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchUser(), this.fetchRoles()]);
    }

    fetchRoles = async () => {
        this.setState({loading: true});

        try {
            const {roles} = await API.roles.getAll();

            this.setState({
                roles,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    fetchUser = async () => {
        this.setState({
            loading: true,
        });

        try {
            const {user} = await API.users.detail(this.props.match.params.id);

            this.setState({
                user,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});
        const formData = new FormData();

        formData.append('firstName', input.firstName);
        formData.append('lastName', input.lastName);
        formData.append('email', input.email);
        formData.append('phone', input.phone);

        /* istanbul ignore else */
        if (input.password) {
            formData.append('password', input.password);
        }

        input.roles.map((role) => formData.append('roles[]', role));

        /* istanbul ignore else */
        if (input.picture.length) {
            formData.append('picture', input.picture[0]);
        }

        try {
            const {user} = await API.users.update(
                this.props.match.params.id,
                formData,
            );

            if (
                this.props.sessionUser.id === user.id &&
                this.props.sessionUser.pictureUrl !== user.pictureUrl
            ) {
                this.props.updateUserSessionImage(user.pictureUrl);
            }

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'USERS.USER_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.UPDATING_DATA',
                    }),
                });
            }
        }
    };

    handleDeleteUser = async () => {
        this.setState({loading: true});

        try {
            await API.users.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'USERS.USER_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        const initialValues = {
            firstName: this.state.user.firstName || '',
            lastName: this.state.user.lastName || '',
            email: this.state.user.email || '',
            phone: this.state.user.phone || '',
            password: '',
            passwordConfirmation: '',
            roles:
                (this.state.user.roles !== '' &&
                    this.state.user.roles.map(
                        (role) => role.id?.toString() || '',
                    )) ||
                [],
            picture: [],
        };

        return this.state.shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/users/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/users',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='updateUserComponent'
                    initialValues={initialValues}
                    backRedirectUrl={`/users/${this.props.match.params.id}`}
                    backText={this.props.intl.formatMessage({
                        id: 'USERS.BACK_TO_USER_DETAIL',
                    })}
                    roles={this.state.roles}
                    loading={this.state.loading}
                    preview={this.state.user.pictureUrl}
                    isEdit={true}
                    handleSubmit={this.onSubmit}
                    handleDeleteUser={this.handleDeleteUser}
                />
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href={`/users/${this.props.match.params.id}`}
                        >
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchUser}
                        >
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const updateUserSessionImage = function (media) {
    return {
        type: 'UPDATE_MEDIA',
        payload: {
            media,
        },
    };
};

const mapDispatchToProps = {
    updateUserSessionImage,
};

const mapStateToProps = function (state) {
    return {sessionUser: state.sessionReducer.user};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(EditUser));
