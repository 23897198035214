import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import City from '../../common/models/City';
import API from '../../common/utils/API';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';
import Form from '../../components/form/cities/CityForm';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LoadingButton from "../../components/form/LoadingButton";

export class EditCity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            city: new City(),
            states: [],

            // Redirects
            shouldRedirectToIndex: false,
            shouldRedirectToDetail: false,
            redirectSuccessMessage: '',

            // Errors
            isLoadErrorModalOpen: false,
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchCity(), this.fetchStates()]);
    }

    fetchCity = async () => {
        this.setState({loading: true});

        try {
            const {city} = await API.cities.detail(this.props.match.params.id);

            this.setState({
                city,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    fetchStates = async () => {
        this.setState({loading: true});

        try {
            const {states} = await API.states.getAll();

            this.setState({
                states,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        try {
            await API.cities.update(this.props.match.params.id, input);

            this.setState({loading: false, shouldRedirectToDetail: true});
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.UPDATING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    handleDeleteCity = async () => {
        this.setState({loading: true});

        try {
            await API.cities.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'CITIES.CITY_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        const {
            shouldRedirectToDetail,
            shouldRedirectToIndex,
            redirectSuccessMessage: successMessage,
        } = this.state;
        const cityState = this.state.states.find(
            (state) => state.name === this.state.city.state,
        );
        const states = this.state.states.map((state) => ({
            value: state.id,
            label: state.name,
        }));
        const initialValues = {
            name: this.state.city?.name || '',
            code: this.state.city?.code || '',
            state: cityState?.id || '',
            timeZone: this.state.city?.timeZone || '',
        };

        return shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/cities/${this.props.match.params.id}`,
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/cities',
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='updateCityComponent'
                    initialValues={initialValues}
                    backRedirectUrl={`/cities/${this.props.match.params.id}`}
                    backText={this.props.intl.formatMessage({
                        id: 'GENERAL.BACK_TO_DETAIL',
                    })}
                    states={states}
                    loading={this.state.loading}
                    isEdit={true}
                    handleSubmit={this.onSubmit}
                    handleDeleteCity={this.handleDeleteCity}
                />
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'CITIES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/cities'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchVenue}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(EditCity);
