import {API} from '../API';
import PaginatedList from '../PaginatedList';
import State from '../../models/State';

const states = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/states', {
            params: filters,
        });

        const list = new PaginatedList();

        list.items = response.data.map((state) => new State(state));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    getAll: async () => {
        const {data: response} = await API.get('/states/all');

        return {
            states: response.data.map((state) => new State(state)),
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`states/${id}`);

        return {
            state: new State(response.data),
        };
    },
    save: async (input) => {
        await API.post('/states', input);

        return {};
    },
    update: async (id, input) => {
        await API.put(`/states/${id}`, input);

        return {};
    },
    delete: async (id) => {
        await API.delete(`/states/${id}`);

        return {};
    },
    activate: async (id) => {
        await API.put(`/states/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/states/${id}/deactivate`);

        return {};
    },
};

export default states;
