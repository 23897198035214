import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from './Loader';
import {
    Cell,
    Tooltip,
    Legend,
    PieChart,
    Pie,
    ResponsiveContainer,
} from 'recharts';

const DEFAULT_COLORS = [
    '#28BBFF',
    '#00BB04',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#8884d8',
    '#F08CFF',
];

class DashboardPieGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
        };
    }

    async componentDidMount() {
        await this.getReport();
    }

    async getReport() {
        this.setState({loading: true});
        const response = await this.props.getReport();
        this.setState({
            data: response,
            loading: false,
        });
    }

    render() {
        const data = this.state.data;
        const header = this.props.header;
        const COLORS = this.props.colors || DEFAULT_COLORS;

        return (
            <Row>
                <Col sm={12}>
                    <Card className='mb-4'>
                        <Card.Header>{header}</Card.Header>
                        <Card.Body
                            style={{
                                width: '100%',
                                height: 300,
                                position: 'relative',
                            }}
                        >
                            <Loader loading={this.state.loading} />
                            <ResponsiveContainer>
                                <PieChart width={400} height={400}>
                                    <Pie
                                        data={data}
                                        dataKey='value'
                                        fill='#8884d8'
                                        label
                                    >
                                        {data.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    COLORS[
                                                        index % COLORS.length
                                                    ]
                                                }
                                            />
                                        ))}
                                    </Pie>
                                    <Legend />
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default DashboardPieGraph;
