import React from 'react';
import ConfirmModal from '../ConfirmModal';
import {injectIntl} from 'react-intl';
import API from '../../../common/utils/API';
import moment from 'moment';

class ChangeTicketStatusForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            loading: false,
        };
        this.toggleShow = this.toggleShow.bind(this);
        this.setField = this.setField.bind(this);
    }

    toggleShow() {
        this.setState({isOpen: !this.state.isOpen});
    }

    async setField() {
        this.setState({loading: true});
        const data = {};
        data[this.props.fieldName] = moment().format();
        await API.bookings.updateBooking(this.props.id, data);
        this.props.setField(this.props.fieldName, data[this.props.fieldName]);
        this.setState({loading: false}, () => {
            this.setState({isOpen: false});
        });
    }

    render() {
        if (!this.props.show) return '';

        return (
            <ConfirmModal
                disabledConfirmButton={false}
                disabled={false}
                titleModal={this.props.buttonLabel}
                handleConfirm={this.setField}
                handleShow={this.toggleShow}
                handleClose={this.toggleShow}
                show={this.state.isOpen}
                variant='primary'
                buttonClassName='btn-bold btn-light-primary'
                buttonLabel={this.props.buttonLabel}
                buttonAcceptLabel={this.props.intl.formatMessage({
                    id: 'GENERAL.YES',
                })}
                buttonAcceptLoading={this.state.loading}
                buttonCloseLabel={this.props.intl.formatMessage({
                    id: 'GENERAL.CANCEL',
                })}
            >
                <p>
                    {this.props.intl.formatMessage(
                        {id: 'BOOKINGS.UPDATE_TICKETS_CONFIRMATION_MESSAGE'},
                        {
                            action: this.props.buttonLabel.toLowerCase(),
                        },
                    )}
                </p>
            </ConfirmModal>
        );
    }
}

export default injectIntl(ChangeTicketStatusForm);
