import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import * as FeatherIcons from 'react-icons/fi';
import {useIntl} from 'react-intl';

import {socket} from '../../common/utils/socket';
import API from '../../common/utils/API';

import mp3_file from '../../common/sounds/notification-audio.mp3';
import './Menu.scss';

let pastCount;

function Menu() {
    const intl = useIntl();
    const [count, setCount] = useState(0);

    useEffect(() => {
        async function notificationsCountHandler() {
            return fetchNotificationsCount();
        }

        socket.on('notificationStored', async () => {
            const audio = new Audio(mp3_file);
            const count = await fetchNotificationsCount();

            pastCount = count;
            setCount(count);

            if (!document.hasFocus()) {
                window.focus();
            }

            await audio.play();
        });

        notificationsCountHandler().then((count) => {
            pastCount = count;
            setCount(count);
        });

        return function cleanup() {
            socket.off('notificationStored');
        };
    }, []);

    const fetchNotificationsCount = async () => {
        return await API.notifications.count();
    };

    return (
        <Nav className='menu'>
            <Nav.Link as={NavLink} to='/' exact={true}>
                <FeatherIcons.FiGrid />
                <span>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</span>
            </Nav.Link>
            <Nav.Item as='a' className='menu-separator'>
                <FeatherIcons.FiMoreHorizontal /> <span>Configuración</span>
            </Nav.Item>
            <Nav.Link as={NavLink} to='/users'>
                <FeatherIcons.FiUsers />
                <span>{intl.formatMessage({id: 'MENU.USERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/roles'>
                <FeatherIcons.FiUserCheck />
                <span>{intl.formatMessage({id: 'MENU.ROLES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/notifications'>
                <FeatherIcons.FiAlertCircle />
                <span>{intl.formatMessage({id: 'MENU.NOTIFICATIONS'})}</span>
                {count === 0
                    ? null
                    : pastCount && (
                          <span className='notification'>{pastCount}</span>
                      )}
            </Nav.Link>
            <Nav.Link as={NavLink} to='/venues'>
                <FeatherIcons.FiMapPin />
                <span>{intl.formatMessage({id: 'MENU.VENUES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/events'>
                <FeatherIcons.FiHeadphones />
                <span>{intl.formatMessage({id: 'MENU.EVENTS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/categories'>
                <FeatherIcons.FiList />
                <span>{intl.formatMessage({id: 'MENU.CATEGORIES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/owners'>
                <FeatherIcons.FiUsers />
                <span>{intl.formatMessage({id: 'MENU.OWNERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/boxes'>
                <FeatherIcons.FiBox />
                <span>{intl.formatMessage({id: 'MENU.BOXES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/services'>
                <FeatherIcons.FiTv />
                <span>{intl.formatMessage({id: 'MENU.SERVICES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/bookings'>
                <FeatherIcons.FiDollarSign />
                <span>{intl.formatMessage({id: 'MENU.BOOKINGS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/payment-providers'>
                <FeatherIcons.FiCreditCard />
                <span>
                    {intl.formatMessage({id: 'MENU.PAYMENT_PROVIDERS'})}
                </span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/payment-methods'>
                <FeatherIcons.FiList />
                <span>{intl.formatMessage({id: 'MENU.PAYMENT_METHODS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/countries'>
                <FeatherIcons.FiMap />
                <span>{intl.formatMessage({id: 'MENU.COUNTRIES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/states'>
                <FeatherIcons.FiMap />
                <span>{intl.formatMessage({id: 'MENU.STATES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/cities'>
                <FeatherIcons.FiMap />
                <span>{intl.formatMessage({id: 'MENU.CITIES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/settings'>
                <FeatherIcons.FiSettings />
                <span>{intl.formatMessage({id: 'MENU.SETTINGS'})}</span>
            </Nav.Link>
        </Nav>
    );
}

export default Menu;
