import {API} from '../API';
import PaymentProvider from '../../models/PaymentProvider';

const paymentProviders = {
    get: async () => {
        const {data: response} = await API.get('/payment-providers');

        return {
            paymentProviders: response.data.map(
                (paymentProvider) => new PaymentProvider(paymentProvider),
            ),
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/payment-providers/${id}`);

        return {
            paymentProvider: new PaymentProvider(response.data),
        };
    },
    activate: async (id) => {
        await API.put(`/payment-providers/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/payment-providers/${id}/deactivate`);

        return {};
    },
};

export default paymentProviders;
