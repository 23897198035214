import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

import API from '../../common/utils/API';
import StarterList from '../../components/starter/StarterList';

export class EventList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            states: [],
            cities: [],
            venues: [],

            // Meta pagination
            page: 1,
            pageSize: 12,
            total: 0,

            // Search
            searchTimeout: 0,
            search: '',
            startsAt: '',
            state: '',
            city: '',
            venue: '',

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchEvents(),
            this.fetchStates(),
            this.fetchCities(),
            this.fetchVenues(),
        ]);
    }

    fetchEvents = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const {search, startsAt, state, city, venue} = this.state;

            const result = await API.events.get({
                page: pageNumber,
                search,
                startsAt,
                state,
                city,
                venue,
            });

            this.setState({
                events: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchStates = async () => {
        this.setState({loading: true});

        try {
            const {states} = await API.states.getAll();

            this.setState({
                states,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchCities = async () => {
        this.setState({loading: true});

        try {
            const {cities} = await API.cities.getAll();

            this.setState({
                cities,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchVenues = async () => {
        this.setState({loading: true});

        try {
            const {venues} = await API.venues.getAll();

            this.setState({
                venues,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchEvents(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        let {state, city, venue, search} = this.state;

        if (event.target.name === 'search') {
            search = event.target.value;
        }

        if (event.target.name === 'state') {
            state = event.target.value;
            city = '';
            venue = '';
        }

        if (event.target.name === 'city') {
            const selectedCity = this.state.cities.find(
                (findCity) => findCity.id === parseInt(event.target.value),
            );
            city = event.target.value;
            state = selectedCity.stateId.toString();
            venue = '';
        }

        if (event.target.name === 'venue') {
            const selectedVenue = this.state.venues.find(
                (findVenue) => findVenue.id === parseInt(event.target.value),
            );
            city = selectedVenue.city.id.toString();
            state = selectedVenue.state.id.toString();
            venue = event.target.value;
        }

        this.setState({
            search,
            state,
            city,
            venue,
            searchTimeout: setTimeout(() => this.fetchEvents(1), 300),
        });
    };

    handleDateSearchChange = (value) => {
        this.setState({
            startsAt: value || '',
            searchTimeout: setTimeout(() => {
                return this.fetchEvents(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchEvents(1);
            },
        );
    };

    render() {
        const statesOptions = this.state.states.map((state) => ({
            value: state.id,
            label: state.name,
        }));
        const citiesOptions = this.state.cities.map((city) => ({
            value: city.id,
            label: city.name,
        }));
        const venuesOptions = this.state.venues.map((venue) => ({
            value: venue.id,
            label: venue.name,
        }));

        return (
            <div data-test='eventListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onAutocompleteOrDateSearchChange={
                        this.handleDateSearchChange
                    }
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_STARTS_AT_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_STATE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_CITY_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_VENUE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_IS_ACTIVE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.events.map((event) => ({
                            content: [
                                event.id,
                                event.name,
                                (event.startsAt &&
                                    moment(event.startsAt).format(
                                        'D/M/YYYY h:mm:ss A',
                                    )) ||
                                    '',
                                event.venue?.city?.state?.name || '',
                                event.venue?.city?.name || '',
                                event.venue?.name || '',
                                event.isActive
                                    ? this.props.intl.formatMessage({
                                          id: 'GENERAL.YES',
                                      })
                                    : 'No',
                            ],
                            link: `/events/${event.id}`,
                        })),
                    }}
                    addNewPermission={'EVENTS_CREATE'}
                    addNewLink={`events/create`}
                    searchFields={[
                        {
                            options: statesOptions,
                            name: 'state',
                            placeholder: this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_STATE_FIELD_PLACEHOLDER',
                            }),
                            value: this.state.state,
                        },
                        {
                            options: citiesOptions,
                            name: 'city',
                            placeholder: this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_CITY_FIELD_PLACEHOLDER',
                            }),
                            value: this.state.city,
                        },
                        {
                            options: venuesOptions,
                            name: 'venue',
                            placeholder: this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_VENUE_FIELD_PLACEHOLDER',
                            }),
                            value: this.state.venue,
                        },
                        {
                            name: 'startsAt',
                            value: this.state.startsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'EVENTS.EVENT_STARTS_AT_FIELD_PLACEHOLDER',
                            }),
                        },
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(EventList);
