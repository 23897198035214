import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Table from 'react-bootstrap/Table';
import API from '../../common/utils/API';
import Loader from './Loader';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import {OPEN_UP} from 'react-dates/constants';
import 'react-dates/lib/css/_datepicker.css';

const monthOptions = [
    {
        name: 'Mensual',
        value: '1',
    },
    {
        name: 'Semestral',
        value: '6',
    },
    {
        name: 'Anual',
        value: '12',
    },
];

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class IncomeGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radioValue: '1',
            data: [],
            loading: true,
            focusedInput: null,
            startDate: moment().subtract('30', 'days'),
            endDate: moment(),
            minDate: moment().subtract('30', 'years'),
            pendingPaymentOwners: 0,
            ownersPaid: 0,
        };

        this.setRadioValue = this.setRadioValue.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
    }

    async componentDidMount() {
        await this.getReport();
    }

    async getReport() {
        if (!this.state.startDate || !this.state.endDate) {
            return;
        }
        this.setState({loading: true});
        const startDate = this.state.startDate.format('YYYY-MM-DD');
        const endDate = this.state.endDate.format('YYYY-MM-DD');
        const type = this.state.radioValue;
        const locale = this.props.intl.locale;
        const response = await API.reports.getIncomeReport({
            startDate,
            endDate,
            type,
            locale,
        });
        // sum owner payments
        let pendingPaymentOwners = 0;
        let ownersPaid = 0;
        response.forEach((rData) => {
            pendingPaymentOwners += Number(rData.pendingPaymentsOwner);
            ownersPaid += Number(rData.paidToOwners);
        });
        this.setState({
            data: response,
            loading: false,
            pendingPaymentOwners,
            ownersPaid,
        });
    }

    async setRadioValue(value) {
        this.setState(
            {
                radioValue: value,
            },
            async () => {
                await this.getReport();
            },
        );
    }

    async onDatesChange({startDate, endDate}) {
        this.setState({startDate, endDate}, async () => {
            await this.getReport();
        });
    }

    render() {
        const data = this.state.data;

        return (
            <Row>
                <Col sm={12}>
                    <Card className='mb-4'>
                        <Card.Header>Cobros y comisiones</Card.Header>
                        <Card.Body className='text-center'>
                            <ButtonGroup toggle>
                                {monthOptions.map((option) => (
                                    <ToggleButton
                                        key={`filter-${option.value}`}
                                        type='radio'
                                        variant='secondary'
                                        name='filter'
                                        value={option.value}
                                        checked={
                                            this.state.radioValue ===
                                            option.value
                                        }
                                        onChange={(e) =>
                                            this.setRadioValue(
                                                e.currentTarget.value,
                                            )
                                        }
                                    >
                                        {option.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Card.Body>
                        <Card.Body
                            style={{
                                width: '100%',
                                height: 300,
                                position: 'relative',
                            }}
                        >
                            <Loader loading={this.state.loading} />
                            <ResponsiveContainer>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='name' />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey='income'
                                        name='Cobros'
                                        stackId='a'
                                        fill='#82ca9d'
                                    />
                                    <Bar
                                        dataKey='fee'
                                        name='Comisión de Oasis'
                                        stackId='b'
                                        fill='#8884d8'
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                        <Card.Body>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th
                                            style={{width: '200px'}}
                                            className='text-left'
                                        >
                                            Pagado a owners
                                        </th>
                                        <td className='text-left'>
                                            {currencyFormatter.format(
                                                this.state.ownersPaid,
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            style={{width: '200px'}}
                                            className='text-left'
                                        >
                                            Por pagar a owners
                                        </th>
                                        <td className='text-left'>
                                            {currencyFormatter.format(
                                                this.state.pendingPaymentOwners,
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            style={{width: '200px'}}
                                            className='text-left'
                                        >
                                            Total
                                        </th>
                                        <th className='text-left'>
                                            {currencyFormatter.format(
                                                this.state.ownersPaid +
                                                    this.state
                                                        .pendingPaymentOwners,
                                            )}
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <DateRangePicker
                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                small={true}
                                displayFormat='YYYY-MM-DD'
                                openDirection={OPEN_UP}
                                minDate={this.state.minDate}
                                startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
                                onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                                isOutsideRange={() => false}
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={(focusedInput) =>
                                    this.setState({focusedInput})
                                } // PropTypes.func.isRequired,
                            />
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default IncomeGraph;
