export default class City {
    constructor({
        id = 0,
        name = '',
        code = '',
        country = '',
        timeZone = '',
        isActive = false,
        createdAt = '',
        updatedAt = '',
        state = '',
        stateId = 1,
    } = {}) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.country = country;
        this.timeZone = timeZone;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.state = state;
        this.stateId = stateId;
    }
}
