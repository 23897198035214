import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import EventList from './EventList';
import EventDetail from './EventDetail';
import CreateEvent from './CreateEvent';
import EditEvent from './EditEvent';

function Categories() {
    const intl = useIntl();

    return (
        <Switch data-test='eventsComponent'>
            <PrivateRoute
                permission='EVENTS_INDEX'
                exact
                path='/events'
                component={withLayout(
                    EventList,
                    intl.formatMessage({id: 'ROUTES.EVENTS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='EVENTS_CREATE'
                exact
                path='/events/create'
                component={withLayout(
                    CreateEvent,
                    intl.formatMessage({id: 'ROUTES.EVENTS.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='EVENTS_EDIT'
                exact
                path='/events/:id/edit'
                component={withLayout(
                    EditEvent,
                    intl.formatMessage({id: 'ROUTES.EVENTS.EDIT'}),
                )}
            />
            <PrivateRoute
                permission='EVENTS_SHOW'
                exact
                path='/events/:id'
                component={withLayout(
                    EventDetail,
                    intl.formatMessage({id: 'ROUTES.EVENTS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Categories;
