import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import StarterList from '../../components/starter/StarterList';

export class BoxList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            boxes: [],
            venues: [],
            cities: [],

            // Meta pagination
            page: 1,
            pageSize: 12,
            total: 0,

            // Search
            searchTimeout: 0,
            search: '',
            venue: '',
            city: '',

            // Error
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchBoxes(),
            this.fetchCities(),
            this.fetchVenues(),
        ]);
    }

    fetchBoxes = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const result = await API.boxes.get({
                page: pageNumber,
                search: this.state.search,
                venue: this.state.venue,
                city: this.state.city,
            });

            this.setState({
                boxes: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchVenues = async () => {
        this.setState({loading: false});

        try {
            const {venues} = await API.venues.getAll();

            this.setState({
                venues,
                loading: false,
                error: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchCities = async () => {
        this.setState({loading: false});

        try {
            const {cities} = await API.cities.getAll();

            this.setState({
                cities,
                loading: false,
                error: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchBoxes(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => this.fetchBoxes(1), 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchBoxes(1);
            },
        );
    };

    render() {
        const venuesOptions = this.state.venues.map((venues) => ({
            value: venues.id,
            label: venues.name,
        }));

        const citiesOptions = this.state.cities.map((cities) => ({
            value: cities.id,
            label: cities.name,
        }));

        return (
            <div data-test='boxListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_NUMBER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_ZONE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_TYPE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_OWNER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_VENUE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_CITY_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_STATUS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'BOXES.BOX_IS_ACTIVE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.boxes.map((box) => ({
                            content: [
                                box.id,
                                box.number,
                                box.boxZone,
                                this.props.intl.formatMessage({
                                    id: `BOXES.BOX_TYPE_${box.type.toUpperCase()}`,
                                }),
                                box.userName,
                                box.venue,
                                box.city,
                                this.props.intl.formatMessage({
                                    id: `BOXES.BOX_STATUS_${box.status.toUpperCase()}`,
                                }),
                                box.isActive
                                    ? this.props.intl.formatMessage({
                                          id: 'GENERAL.YES',
                                      })
                                    : 'No',
                            ],
                            link: `/boxes/${box.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            options: venuesOptions,
                            name: 'venue',
                            placeholder: this.props.intl.formatMessage({
                                id: 'BOXES.BOX_FILTER_BY_VENUE_PLACEHOLDER',
                            }),
                            value: this.state.venue,
                        },
                        {
                            options: citiesOptions,
                            name: 'city',
                            placeholder: this.props.intl.formatMessage({
                                id: 'BOXES.BOX_FILTER_BY_CITY_PLACEHOLDER',
                            }),
                            value: this.state.city,
                        },
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(BoxList);
