import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import Loader from './Loader';

import {CubeSpinner} from 'react-spinners-kit';

import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import {OPEN_UP} from 'react-dates/constants';
import 'react-dates/lib/css/_datepicker.css';

class DashboardBarGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radioValue: '1',
            data: [],
            loading: true,
            focusedInput: null,
            startDate: moment().subtract('30', 'days'),
            endDate: moment(),
            minDate: moment().subtract('30', 'years'),
        };

        this.setRadioValue = this.setRadioValue.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
    }

    async componentDidMount() {
        await this.getReport();
    }

    async getReport() {
        if (!this.state.startDate || !this.state.endDate) {
            return;
        }
        this.setState({loading: true});
        const startDate = this.state.startDate.format('YYYY-MM-DD');
        const endDate = this.state.endDate.format('YYYY-MM-DD');
        const type = this.state.radioValue;
        const locale = this.props.intl.locale;
        const response = await this.props.getReport({
            startDate,
            endDate,
            type,
            locale,
        });
        this.setState({
            data: response,
            loading: false,
        });
    }

    async setRadioValue(value) {
        this.setState(
            {
                radioValue: value,
            },
            async () => {
                await this.getReport();
            },
        );
    }

    async onDatesChange({startDate, endDate}) {
        this.setState({startDate, endDate}, async () => {
            await this.getReport();
        });
    }

    render() {
        const data = this.state.data;
        const monthOptions = this.props.monthOptions;
        const header = this.props.header;

        return (
            <Row>
                <Col sm={12}>
                    <Card className='mb-4'>
                        <Card.Header>{header}</Card.Header>
                        <Card.Body className='text-center'>
                            <ButtonGroup toggle>
                                {monthOptions.map((option) => (
                                    <ToggleButton
                                        key={`filter-${option.value}`}
                                        type='radio'
                                        variant='secondary'
                                        name='filter'
                                        value={option.value}
                                        checked={
                                            this.state.radioValue ===
                                            option.value
                                        }
                                        onChange={(e) =>
                                            this.setRadioValue(
                                                e.currentTarget.value,
                                            )
                                        }
                                    >
                                        {option.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Card.Body>
                        <Card.Body
                            style={{
                                width: '100%',
                                height: 300,
                                position: 'relative',
                            }}
                        >
                            <Loader loading={this.state.loading} />
                            <ResponsiveContainer>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='name' />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey={this.props.dataKey}
                                        name={this.props.dataName}
                                        stackId='a'
                                        fill='#82ca9d'
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                        <Card.Footer>
                            <DateRangePicker
                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                small={true}
                                displayFormat='YYYY-MM-DD'
                                openDirection={OPEN_UP}
                                minDate={this.state.minDate}
                                startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
                                onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                                isOutsideRange={() => false}
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={(focusedInput) =>
                                    this.setState({focusedInput})
                                } // PropTypes.func.isRequired,
                            />
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default DashboardBarGraph;
