import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import OwnerList from './OwnerList';
import OwnerDetail from './OwnerDetail';

function Owners() {
    const intl = useIntl();

    return (
        <Switch data-test='ownersComponent'>
            <PrivateRoute
                permission='OWNERS_INDEX'
                exact
                path='/owners'
                component={withLayout(
                    OwnerList,
                    intl.formatMessage({id: 'ROUTES.OWNERS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='OWNERS_SHOW'
                exact
                path='/owners/:id'
                component={withLayout(
                    OwnerDetail,
                    intl.formatMessage({id: 'ROUTES.OWNERS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Owners;
