import React from 'react';
import {injectIntl} from 'react-intl';
import API from '../../common/utils/API';
import Form from '../../components/form/settings/SettingsForm';

export class SettingsIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            IVA: 0,
            host: 0,
            guest: 0,
            loading: true,
        };
    }

    async componentDidMount() {
        const fees = await API.settings.getAllFees();
        fees.forEach((fee) => {
            if (fee.code === 'guest') {
                this.setState({guest: fee.value});
            } else if (fee.code === 'host') {
                this.setState({host: fee.value});
            } else if (fee.code === 'IVA') {
                this.setState({IVA: fee.value});
            }
        });
        this.setState({loading: false});
    }

    onSubmit = async (input) => {
        this.setState({loading: true});
        const fees = await API.settings.updateFees(input);
        fees.forEach((fee) => {
            if (fee.code === 'guest') {
                this.setState({guest: fee.value});
            } else if (fee.code === 'host') {
                this.setState({host: fee.value});
            } else if (fee.code === 'IVA') {
                this.setState({IVA: fee.value});
            }
        });
        this.setState({loading: false});
    };

    render() {
        const initialValues = {
            guest: this.state.guest,
            host: this.state.host,
            IVA: this.state.IVA,
        };
        return (
            <>
                <Form
                    initialValues={initialValues}
                    handleSubmit={this.onSubmit}
                    loading={this.state.loading}
                />
            </>
        );
    }
}

export default injectIntl(SettingsIndex);
