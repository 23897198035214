import openSocket from 'socket.io-client';

import {store} from '../redux/StoreReducers';

const url =
    process.env.NODE_ENV === 'test'
        ? ''
        : `${process.env.REACT_APP_API_ENDPOINT}`;

const token = store.getState().sessionReducer?.session?.token || '';

export const socket = openSocket(url, {
    withCredentials: false,
    reconnectionDelay: 100,
    query: {token},
});
