import {API} from '../API';

const settings = {
    getAllFees: async () => {
        const {data: response} = await API.get('/settings/fees');

        return response.data;
    },
    updateFees: async (input) => {
        const {data: response} = await API.put('/settings/fees', input);
        return response.data;
    },
};

export default settings;
