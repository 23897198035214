export default class State {
    constructor({
        id = 0,
        name = '',
        code = '',
        country = '',
        isActive = false,
        createdAt = '',
        updatedAt = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.country = country;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}
