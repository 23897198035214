import general from './general/en';
import starterList from './starter_list/en';
import roles from './roles/en';
import users from './users/en';
import routes from './routes/en';
import cities from './cities/en';
import states from './states/en';
import countries from './countries/en';
import owners from './owners/en';
import paymentProviders from './payment_providers/en';
import paymentMethods from './payment_methods/en';
import venues from './venues/en';
import boxes from './boxes/en';
import categories from './categories/en';
import services from './services/en';
import events from './events/en';
import notifications from './notifications/en';
import bookings from './bookings/en';
import settings from './settings/en';

export default {
    ...general,
    ...starterList,
    ...roles,
    ...users,
    ...routes,
    ...states,
    ...cities,
    ...countries,
    ...owners,
    ...paymentProviders,
    ...paymentMethods,
    ...venues,
    ...boxes,
    ...categories,
    ...services,
    ...events,
    ...notifications,
    ...bookings,
    ...settings,
};
