import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API';

export class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            roles: [],
            // Meta for pagination
            page: 1,
            pageSize: 12,
            total: 0,
            // Search parameters
            searchTimeout: 0,
            search: '',
            role: '',

            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchUsers(), this.fetchRoles()]);
    }

    fetchUsers = async (pageNumber = 1) => {
        this.setState({loading: true});

        try {
            const result = await API.users.get({
                page: pageNumber,
                search: this.state.search,
                role: this.state.role,
            });

            this.setState({
                users: result.items,
                page: result.page,
                pageSize: result.pageSize,
                total: result.total,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    fetchRoles = async () => {
        this.setState({loading: true});

        try {
            const {roles} = await API.roles.getAll();

            this.setState({
                roles,
                error: false,
                loading: false,
            });
        } catch (e) {
            this.setState(
                {
                    error: true,
                    loading: false,
                },
                this.clearErrorMessage,
            );
        }
    };

    clearErrorMessage = () => {
        setTimeout(() => {
            if (this.state.error) {
                this.setState({error: false});
            }
        }, 5000);
    };

    handlePageChange = (pageNumber) => {
        return this.fetchUsers(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchUsers(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchUsers(1);
            },
        );
    };

    render() {
        const rolesOptions = this.state.roles.map((role) => ({
            value: role.id,
            label: role.name,
        }));

        return (
            <div data-test='userListComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>
                </Alert>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    loading={this.state.loading}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_FIRST_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_LAST_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_ROLE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_EMAIL_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.users.map((user) => ({
                            content: [
                                user.id,
                                user.firstName,
                                user.lastName,
                                user.roles,
                                user.email,
                            ],
                            link: `/users/${user.id}`,
                        })),
                    }}
                    addNewPermission={'USERS_CREATE'}
                    addNewLink={`users/create`}
                    searchFields={[
                        {
                            options: rolesOptions,
                            name: 'role',
                            placeholder: this.props.intl.formatMessage({
                                id: 'USERS.USER_FILTER_BY_ROLE',
                            }),
                            value: this.state.role,
                        },
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(UserList);
