import {API} from '../API';
import PaginatedList from '../PaginatedList';
import City from '../../models/City';

const cities = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/cities', {
            params: filters,
        });

        const list = new PaginatedList();

        list.items = response.data.map((city) => new City(city));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    getAll: async () => {
        const {data: response} = await API.get('/cities/all');

        return {
            cities: response.data.map((city) => new City(city)),
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/cities/${id}`);

        return {
            city: new City(response.data),
        };
    },
    save: async (input) => {
        await API.post('/cities', input);

        return {};
    },
    update: async (id, input) => {
        await API.put(`/cities/${id}`, input);

        return {};
    },
    delete: async (id) => {
        await API.delete(`/cities/${id}`);

        return {};
    },
    activate: async (id) => {
        await API.put(`/cities/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/cities/${id}/deactivate`);

        return {};
    },
};

export default cities;
