import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteCountryModalOpen, setIsDeleteCountryModalOpen] = useState(
        false,
    );

    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({id: 'COUNTRIES.VALIDATION.NAME_MISSING'}),
        ),
        code: Yup.string()
            .matches(
                new RegExp('^[A-Z]{3}$'),
                intl.formatMessage({id: 'COUNTRIES.VALIDATION.CODE_FORMAT'}),
            )
            .required(
                intl.formatMessage({id: 'COUNTRIES.VALIDATION.CODE_MISSING'}),
            ),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const handleToggleIsDeleteCountryModal = () => {
        setIsDeleteCountryModalOpen(!isDeleteCountryModalOpen);
    };

    return (
        <Formik
            data-test='countryFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>
                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'COUNTRIES.GENERAL_INFORMATION_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Input
                                        md='6'
                                        type='text'
                                        label={intl.formatMessage({
                                            id:
                                                'COUNTRIES.COUNTRY_NAME_FIELD_LABEL',
                                        })}
                                        placeholder={intl.formatMessage({
                                            id:
                                                'COUNTRIES.COUNTRY_NAME_FIELD_PLACEHOLDER',
                                        })}
                                        name='name'
                                        value={values.name}
                                        onChange={handleChange}
                                        error={errors.name}
                                        isInvalid={errors.name}
                                    />
                                    <Input
                                        md='6'
                                        type='text'
                                        label={intl.formatMessage({
                                            id:
                                                'COUNTRIES.COUNTRY_CODE_FIELD_LABEL',
                                        })}
                                        placeholder={intl.formatMessage({
                                            id:
                                                'COUNTRIES.COUNTRY_CODE_FIELD_PLACEHOLDER',
                                        })}
                                        name='code'
                                        value={values.code}
                                        onChange={handleChange}
                                        error={errors.code}
                                        isInvalid={errors.code}
                                    />
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>
                    <div
                        className={`d-flex ${
                            props.isEdit
                                ? 'justify-content-between'
                                : 'justify-content-end'
                        }`}
                    >
                        {props.isEdit && (
                            <div>
                                <div>
                                    <Can run='COUNTRY_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'COUNTRIES.DELETE_COUNTRY_TEXT',
                                            })}
                                            handleConfirm={
                                                props.handleDeleteCountry
                                            }
                                            handleShow={
                                                handleToggleIsDeleteCountryModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteCountryModal
                                            }
                                            show={isDeleteCountryModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id:
                                                    'COUNTRIES.DELETE_COUNTRY_TEXT',
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'COUNTRIES.CONFIRM_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                {
                                                    id: 'GENERAL.CANCEL',
                                                },
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'COUNTRIES.DELETE_COUNTRY_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        )}
                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'GENERAL.SAVE',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
