import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom';

import Country from '../../common/models/Country';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import Modal from 'react-bootstrap/Modal';
import LoadingButton from '../../components/form/LoadingButton';

export class CountryDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            country: new Country(),

            // Enable/Disable Modal
            isCountryAvailabilityModalOpen: false,

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchCountry();
    }

    fetchCountry = async () => {
        this.setState({loading: true});

        try {
            const {country} = await API.countries.detail(
                this.props.match.params.id,
            );

            this.setState({
                country,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleCountryAvailabilityModal = () => {
        this.setState({
            isCountryAvailabilityModalOpen: !this.state
                .isCountryAvailabilityModalOpen,
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleCountryAvailability = async () => {
        const {country} = this.state;

        this.setState({loading: true});

        try {
            if (country.isActive) {
                await API.countries.deactivate(this.props.match.params.id);

                country.isActive = false;
            } else {
                await API.countries.activate(this.props.match.params.id);

                country.isActive = true;
            }

            this.setState({
                country,
                isCountryAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `COUNTRIES.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isCountryAvailabilityModalOpen: false,
            });
        }
    };

    render() {
        const {
            country: {isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/countries'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='countryDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'COUNTRIES.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>Id</label>
                                <p>{this.state.country.id}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'COUNTRIES.COUNTRY_CODE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.country.code}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'COUNTRIES.COUNTRY_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.country.name}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'COUNTRIES.COUNTRY_IS_ACTIVE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No'}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'COUNTRIES.COUNTRY_CREATED_AT_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.country.createdAt}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'COUNTRIES.COUNTRY_UPDATED_AT_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.country.updatedAt}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='COUNTRIES_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'COUNTRIES.BLOCK_COUNTRY_MODAL'
                                        : 'COUNTRIES.UNBLOCK_COUNTRY_MODAL',
                                })}
                                handleConfirm={this.toggleCountryAvailability}
                                handleShow={this.toggleCountryAvailabilityModal}
                                handleClose={
                                    this.toggleCountryAvailabilityModal
                                }
                                show={this.state.isCountryAvailabilityModalOpen}
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'COUNTRIES.BLOCK_COUNTRY_MODAL'
                                        : 'COUNTRIES.UNBLOCK_COUNTRY_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'COUNTRIES.CONFIRM_BLOCK_COUNTRY'
                                            : 'COUNTRIES.CONFIRM_UNBLOCK_COUNTRY',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'COUNTRIES.BLOCK_COUNTRY_CONFIRMATION_MESSAGE'
                                            : 'COUNTRIES.UNBLOCK_COUNTRY_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div>
                        <Can run='COUNTRIES_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/countries/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'COUNTRIES.EDIT_COUNTRY_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'COUNTRIES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/countries'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchCountry}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(CountryDetail);
