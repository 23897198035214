export default class Venue {
    constructor({
        id = 0,
        name = '',
        pictureUrl = '',
        address = '',
        latitude = '',
        longitude = '',
        contact = '',
        isActive = false,
        events = [],
        boxes = 0,
        city = {},
        state = {},
        country = {},
        createdAt = '',
        updatedAt = '',
        pictureWidth = 0,
        pictureHeight = 0,
    } = {}) {
        this.id = id;
        this.name = name;
        this.pictureUrl = pictureUrl;
        this.address = address;
        this.contact = contact;
        this.latitude = latitude;
        this.longitude = longitude;
        this.isActive = isActive;
        this.events = events;
        this.boxes = boxes;
        this.city = city;
        this.state = state;
        this.country = country;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.pictureWidth = pictureWidth;
        this.pictureHeight = pictureHeight;
    }
}
