import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import moment from 'moment';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';
import Datepicker from '../DatePicker';

import './EventForm.scss';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false);
    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({
                id: 'EVENTS.VALIDATION.NAME_MISSING',
            }),
        ),
        picture: props.isEdit
            ? Yup.array().min(0).required()
            : Yup.array()
                  .min(
                      1,
                      intl.formatMessage({
                          id: 'EVENTS.VALIDATION.PICTURE_MISSING',
                      }),
                  )
                  .required(),
        duration: Yup.number(
            intl.formatMessage({id: 'EVENTS.VALIDATION.NUMBER_FORMAT'}),
        )
            .min(1)
            .required(
                intl.formatMessage({
                    id: 'EVENTS.VALIDATION.DURATION_MISSING',
                }),
            ),
        startsAt: Yup.date().required(
            intl.formatMessage({
                id: 'EVENTS.VALIDATION.STARTS_AT_MISSING',
            }),
        ),
        venue: Yup.number().required(
            intl.formatMessage({
                id: 'EVENTS.VALIDATION.VENUE_MISSING',
            }),
        ),
        categories: Yup.array().min(0).of(Yup.number()).required(),
    });
    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const onDrop = (acceptedFiles, rejectedFiles, setFieldValue) => {
        /* istanbul ignore else */
        if (!rejectedFiles.length) {
            const picture = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );

            setFieldValue('picture', [...picture]);
        }
    };

    const handleDateChange = (val, setFieldValue) => {
        val = val ? moment(val) : '';

        if (val) {
            const minEventDate = moment().add(72, 'hour');

            val = val.isBefore(minEventDate) ? minEventDate : val;
        }

        setFieldValue('startsAt', val ? val.format('YYYY-MM-DD HH:mm:ss') : '');
    };

    const handleToggleIsDeleteEventModal = () => {
        setIsDeleteEventModalOpen(!isDeleteEventModalOpen);
    };

    const addCategory = (category, categories, setFieldValue) => {
        if (!categories.includes(+category)) {
            const updatedCategories = [...categories];
            updatedCategories.push(+category);
            setFieldValue('categories', updatedCategories);
        }
    };

    const removeCategory = (category, categories, setFieldValue) => {
        const index = categories.indexOf(category);

        if (index > -1) {
            const updatedCategories = [...categories];
            updatedCategories.splice(index, 1);
            setFieldValue('categories', updatedCategories);
        }
    };

    return (
        <Formik
            data-test='eventFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'EVENTS.GENERAL_INFORMATION_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='3'>
                                        <Dropzone
                                            data-test='dropzone'
                                            accept='image/*'
                                            onDrop={(
                                                acceptedFiles,

                                                rejectedFiles,
                                            ) =>
                                                onDrop(
                                                    acceptedFiles,

                                                    rejectedFiles,

                                                    setFieldValue,
                                                )
                                            }
                                            multiple={false}
                                        >
                                            {({
                                                getRootProps,

                                                getInputProps,

                                                isDragActive,
                                            }) => (
                                                <section>
                                                    <div
                                                        {...getRootProps()}
                                                        className={classNames(
                                                            'dropzone',

                                                            {
                                                                'dropzone--isActive': isDragActive,
                                                            },
                                                        )}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />

                                                        {!values.picture
                                                            .length &&
                                                        !props.preview ? (
                                                            isDragActive ? (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'EVENTS.IMAGE_DRAG_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'EVENTS.IMAGE_DROP_PLACEHOLDER',
                                                                        },
                                                                    )}
                                                                </p>
                                                            )
                                                        ) : null}

                                                        {values.picture
                                                            .length ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    {values.picture.map(
                                                                        (
                                                                            file,

                                                                            i,
                                                                        ) => (
                                                                            <img
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className='img-fluid'
                                                                                src={
                                                                                    file.preview
                                                                                }
                                                                                alt={`${values.name}`}
                                                                            />
                                                                        ),
                                                                    )}
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : props.preview ? (
                                                            <BaseForm.Row>
                                                                <Col>
                                                                    <img
                                                                        className='img-fluid'
                                                                        src={
                                                                            props.preview
                                                                        }
                                                                        alt={`${values.name}`}
                                                                    />
                                                                </Col>
                                                            </BaseForm.Row>
                                                        ) : null}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {errors.picture ? (
                                            <BaseForm.Text className='text-danger'>
                                                {errors.picture}
                                            </BaseForm.Text>
                                        ) : null}
                                    </Col>
                                    <Col md='9'>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'EVENTS.EVENT_NAME_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'EVENTS.EVENT_NAME_PLACEHOLDER',
                                                    },
                                                )}
                                                name='name'
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name}
                                                isInvalid={errors.name}
                                            />
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'EVENTS.EVENT_DURATION_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'EVENTS.EVENT_DURATION_PLACEHOLDER',
                                                    },
                                                )}
                                                name='duration'
                                                value={values.duration}
                                                onChange={handleChange}
                                                error={errors.duration}
                                                isInvalid={errors.duration}
                                            />
                                        </BaseForm.Row>
                                        <BaseForm.Row>
                                            <BaseForm.Group
                                                as={Col}
                                                md='6'
                                                className='custom-datepicker'
                                            >
                                                <BaseForm.Label>
                                                    {intl.formatMessage({
                                                        id:
                                                            'EVENTS.EVENT_STARTS_AT_FIELD_LABEL',
                                                    })}
                                                </BaseForm.Label>
                                                <Datepicker
                                                    data-test='testDate'
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id:
                                                                'EVENTS.EVENT_STARTS_AT_PLACEHOLDER',
                                                        },
                                                    )}
                                                    name='startsAt'
                                                    minDate={3}
                                                    onChange={(val) =>
                                                        handleDateChange(
                                                            val,
                                                            setFieldValue,
                                                        )
                                                    }
                                                    value={values.startsAt}
                                                    isClearable
                                                    showTimeSelect
                                                    isInvalid={errors.startsAt}
                                                />
                                                {errors.startsAt ? (
                                                    <BaseForm.Text className='text-danger'>
                                                        {errors.startsAt}
                                                    </BaseForm.Text>
                                                ) : null}
                                            </BaseForm.Group>
                                            <Input
                                                md='6'
                                                type='select'
                                                label={intl.formatMessage({
                                                    id:
                                                        'EVENTS.EVENT_VENUE_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'EVENTS.EVENT_VENUE_PLACEHOLDER',
                                                    },
                                                )}
                                                name='venue'
                                                options={props.venues}
                                                value={values.venue}
                                                onChange={handleChange}
                                                error={errors.venue}
                                                isInvalid={errors.venue}
                                            />
                                        </BaseForm.Row>
                                        <BaseForm.Row>
                                            <BaseForm.Group as={Col} sm={6}>
                                                <BaseForm.Label>
                                                    {intl.formatMessage({
                                                        id:
                                                            'EVENTS.EVENT_CATEGORIES_FIELD_LABEL',
                                                    })}
                                                </BaseForm.Label>
                                                <BaseForm.Control
                                                    as='select'
                                                    custom
                                                    value=''
                                                    onChange={(e) =>
                                                        addCategory(
                                                            e.target.value,
                                                            values.categories,
                                                            setFieldValue,
                                                        )
                                                    }
                                                    autoComplete='off'
                                                >
                                                    <option value=''>
                                                        {intl.formatMessage({
                                                            id:
                                                                'EVENTS.EVENT_CATEGORIES_PLACEHOLDER',
                                                        })}
                                                    </option>
                                                    {props.categories.map(
                                                        ({value, label}) => (
                                                            <option
                                                                value={value}
                                                                key={value}
                                                            >
                                                                {label}
                                                            </option>
                                                        ),
                                                    )}
                                                </BaseForm.Control>
                                                {values.categories.map(
                                                    (category, i) => (
                                                        <div
                                                            key={i}
                                                            className='chip'
                                                        >
                                                            {props.categories.find(
                                                                (
                                                                    selectedCategory,
                                                                ) =>
                                                                    category ===
                                                                    selectedCategory.value,
                                                            )?.label || ''}
                                                            <button
                                                                type='button'
                                                                className='button'
                                                                onClick={() =>
                                                                    removeCategory(
                                                                        category,
                                                                        values.categories,
                                                                        setFieldValue,
                                                                    )
                                                                }
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    ),
                                                )}
                                            </BaseForm.Group>
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>
                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <div>
                                <div>
                                    <Can run='EVENTS_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'EVENTS.DELETE_EVENT_MODAL_TITLE',
                                            })}
                                            handleConfirm={
                                                props.handleDeleteEvent
                                            }
                                            handleShow={
                                                handleToggleIsDeleteEventModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteEventModal
                                            }
                                            show={isDeleteEventModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id:
                                                    'EVENTS.DELETE_EVENT_BUTTON_LABEL',
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'EVENTS.CONFIRM_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'EVENTS.CANCEL_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'EVENTS.DELETE_EVENT_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'EVENTS.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
