import React from 'react';
import {CubeSpinner} from 'react-spinners-kit';

const loaderStyle = {
    width: 'calc(100% - 2.5rem)',
    height: 'calc(100% - 2.5rem)',
    position: 'absolute',
    background: 'rgb(243 243 243 / 90%)',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

function Loader(props) {
    return (
        props.loading && (
            <div className='loader' style={loaderStyle}>
                <CubeSpinner
                    size={50}
                    frontColor='#8157fd'
                    backColor='#ccccce'
                />
            </div>
        )
    );
}

export default Loader;
