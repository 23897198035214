import React from 'react';
import Image from 'react-bootstrap/Image';
import {useIntl} from 'react-intl';

import Pin from '../../common/img/stadium_pin.png';

const Stadium = ({
    venuePictureUrl,
    venuePictureWidth,
    venuePictureHeight,
    dimensions,
    position,
}) => {
    const intl = useIntl();
    const canRenderImage =
        venuePictureUrl && venuePictureWidth && venuePictureHeight;

    if (canRenderImage) {
        const totalWidth = dimensions.width;
        const totalHeight =
            (totalWidth * venuePictureHeight) / venuePictureWidth;
        const posX = parseFloat(position.x);
        const posY = parseFloat(position.y);

        return (
            <div style={{width: totalWidth, position: 'relative'}}>
                <Image
                    src={venuePictureUrl}
                    style={{
                        height: totalHeight,
                        width: totalWidth,
                    }}
                    className='img-fluid'
                    alt={intl.formatMessage({
                        id: 'BOXES.BOX_DOCUMENT_TITLE_FIELD_LABEL',
                    })}
                />
                <img
                    src={Pin}
                    alt='Stadium pin'
                    style={{
                        height: 32,
                        width: 25,
                        left: (posX * totalWidth) / 100 - 12.5,
                        top: (posY * totalHeight) / 100 - 20,
                        position: 'absolute',
                    }}
                />
            </div>
        );
    }

    return null;
};

export default Stadium;
