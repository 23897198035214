import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom';

import Service from '../../common/models/Service';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ErrorModal from '../../components/ui/ErrorModal';

export class ServiceDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            service: new Service(),

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchService();
    }

    fetchService = async () => {
        this.setState({loading: true});

        try {
            const {service} = await API.services.detail(
                this.props.match.params.id,
            );

            this.setState({
                service,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/services'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='serviceDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'SERVICES.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                {this.state.service.iconUrl && (
                                    <img
                                        className='img-fluid'
                                        src={this.state.service.iconUrl}
                                        alt={`${this.state.service.name}`}
                                    />
                                )}
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            Id
                                        </label>
                                        <p>{this.state.service.id}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'SERVICES.SERVICE_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.service.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'SERVICES.SERVICE_ORDER_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.service.order}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'SERVICES.SERVICE_CREATED_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.service.createdAt}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'SERVICES.SERVICE_UPDATED_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.service.updatedAt}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-end'>
                    <div>
                        <Can run='SERVICES_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/services/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'SERVICES.EDIT_SERVICE_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>
                <ErrorModal
                    isLoadErrorModalOpen={this.state.isLoadErrorModalOpen}
                    toggleLoadErrorModal={this.toggleLoadErrorModal}
                    errorMessage={this.props.intl.formatMessage({
                        id: 'SERVICES.ERROR_MODAL_TEXT',
                    })}
                    returnUrl={`/services/`}
                    loading={this.state.loading}
                    handleRetryClick={this.fetchServices}
                />
            </>
        );
    }
}

export default injectIntl(ServiceDetail);
