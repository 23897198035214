import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Category from '../../models/Category';

const categories = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/categories', {
            params: filters,
        });

        const list = new PaginatedList();

        list.items = response.data.map((category) => new Category(category));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    getAll: async () => {
        const {data: response} = await API.get('/categories/all');

        return {
            categories: response.data.map((category) => new Category(category)),
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/categories/${id}`);
        return {
            category: new Category(response.data),
        };
    },
    save: async (params) => {
        await API.post('/categories', params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {};
    },
    update: async (id, params) => {
        await API.put(`/categories/${id}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {};
    },
    delete: async (id) => {
        await API.delete(`/categories/${id}`);

        return {};
    },
    activate: async (id) => {
        await API.put(`/categories/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/categories/${id}/deactivate`);

        return {};
    },
};

export default categories;
