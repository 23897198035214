import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom';

import PaymentProvider from '../../common/models/PaymentProvider';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import Modal from "react-bootstrap/Modal";
import LoadingButton from "../../components/form/LoadingButton";

export class PaymentProviderDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentProvider: new PaymentProvider(),

            // Enable/Disable Modal
            isPaymentProviderAvailabilityModalOpen: false,

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchPaymentProvider();
    }

    fetchPaymentProvider = async () => {
        this.setState({loading: true});

        try {
            const {paymentProvider} = await API.paymentProviders.detail(
                this.props.match.params.id,
            );

            this.setState({
                paymentProvider,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    togglePaymentProviderAvailabilityModal = () => {
        this.setState({
            isPaymentProviderAvailabilityModalOpen: !this.state
                .isPaymentProviderAvailabilityModalOpen,
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    togglePaymentProviderAvailability = async () => {
        const {paymentProvider} = this.state;

        this.setState({loading: true});

        try {
            if (paymentProvider.isActive) {
                await API.paymentProviders.deactivate(
                    this.props.match.params.id,
                );

                paymentProvider.isActive = false;
            } else {
                await API.paymentProviders.activate(this.props.match.params.id);

                paymentProvider.isActive = true;
            }

            this.setState({
                paymentProvider,
                isPaymentProviderAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `PAYMENT_PROVIDERS.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isPaymentProviderAvailabilityModalOpen: false,
            });
        }
    };

    render() {
        const {
            paymentProvider: {isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/payment-providers'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card
                    className='mb-4'
                    data-test='paymentProviderDetailComponent'
                >
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'PAYMENT_PROVIDERS.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>Id</label>
                                <p>{this.state.paymentProvider.id}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'PAYMENT_PROVIDERS.PAYMENT_PROVIDER_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.paymentProvider.name}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'PAYMENT_PROVIDERS.PAYMENT_PROVIDER_IS_ACTIVE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No'}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'PAYMENT_PROVIDERS.PAYMENT_PROVIDER_COUNTRIES_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.paymentProvider.countries
                                        .map((country) => country.name)
                                        .join(', ')}
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='PAYMENT_PROVIDERS_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'PAYMENT_PROVIDERS.BLOCK_PAYMENT_PROVIDER_MODAL'
                                        : 'PAYMENT_PROVIDERS.UNBLOCK_PAYMENT_PROVIDER_MODAL',
                                })}
                                handleConfirm={
                                    this.togglePaymentProviderAvailability
                                }
                                handleShow={
                                    this.togglePaymentProviderAvailabilityModal
                                }
                                handleClose={
                                    this.togglePaymentProviderAvailabilityModal
                                }
                                show={
                                    this.state
                                        .isPaymentProviderAvailabilityModalOpen
                                }
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'PAYMENT_PROVIDERS.BLOCK_PAYMENT_PROVIDER_MODAL'
                                        : 'PAYMENT_PROVIDERS.UNBLOCK_PAYMENT_PROVIDER_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'PAYMENT_PROVIDERS.CONFIRM_BLOCK_PAYMENT_PROVIDER'
                                            : 'PAYMENT_PROVIDERS.CONFIRM_UNBLOCK_PAYMENT_PROVIDER',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'PAYMENT_PROVIDERS.BLOCK_PAYMENT_PROVIDER_CONFIRMATION_MESSAGE'
                                            : 'PAYMENT_PROVIDERS.UNBLOCK_PAYMENT_PROVIDER_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'PAYMENT_PROVIDERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/payment-providers'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchPaymentProvider}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(PaymentProviderDetail);
