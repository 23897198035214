import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Booking from '../../models/Booking';

const dummyBookings = {
    status: 'ok',
    data: [
        {
            id: 1,
            user: {
                id: 1,
                firstName: 'Carlos',
                lastName: 'Client',
                email: 'client@pixan.io',
                phone: '4777544933',
            },
            box: {
                boxZone: 'Poniente',
                number: '10A',
                owner: {
                    id: 1,
                    bankAccount: {
                        bank: {
                            name: 'Banamex',
                        },
                        accountNumber: '123456789784',
                        clabeNumber: '123456789874561234',
                    },
                    firstName: 'Carlos',
                    lastName: 'Owner',
                    email: 'owner@pixan.io',
                    phone: '4777544944',
                },
            },
            venue: {
                name: 'Estadio Azteca',
            },
            boxType: 'onetime',
            startsAt: '2021-05-01T18:52:12.630Z',
            endsAt: '2021-05-01T22:52:12.630Z',
            status: 'accepted',
            payment: {
                status: 'capture',
            },
            total: 1000.0,
            ownerTotal: 900.0,
            ownerPayment: 'pending',
            createdAt: '2021-04-20T18:52:12.630Z',
        },
        {
            id: 2,
            user: {
                id: 1,
                firstName: 'Carlos',
                lastName: 'Client',
                email: 'client@pixan.io',
                phone: '4777544933',
            },
            box: {
                boxZone: 'Poniente',
                number: '10A',
                owner: {
                    id: 1,
                    bankAccount: {
                        bank: {
                            name: 'Banamex',
                        },
                        accountNumber: '123456789784',
                        clabeNumber: '123456789874561234',
                    },
                    firstName: 'Carlos',
                    lastName: 'Owner',
                    email: 'owner@pixan.io',
                    phone: '4777544944',
                },
            },
            venue: {
                name: 'Estadio Azteca',
            },
            boxType: 'season',
            startsAt: '2021-05-01T18:52:12.630Z',
            endsAt: '2021-05-30T22:52:12.630Z',
            status: 'pending',
            payment: {
                status: 'capture',
            },
            total: 1000.0,
            ownerTotal: 900.0,
            ownerPayment: 'pending'
        },
        {
            id: 3,
            user: {
                id: 1,
                firstName: 'Carlos',
                lastName: 'Client',
                email: 'client@pixan.io',
                phone: '4777544933',
            },
            box: {
                boxZone: 'Poniente',
                number: '10A',
                owner: {
                    id: 1,
                    bankAccount: {
                        bank: {
                            name: 'Banamex',
                        },
                        accountNumber: '123456789784',
                        clabeNumber: '123456789874561234',
                    },
                    firstName: 'Carlos',
                    lastName: 'Owner',
                    email: 'owner@pixan.io',
                    phone: '4777544944',
                },
            },
            venue: {
                name: 'Estadio Azteca',
            },
            boxType: 'onetime',
            status: 'canceled',
            startsAt: '2021-05-01T18:52:12.630Z',
            endsAt: '2021-05-01T22:52:12.630Z',
            payment: {
                status: 'capture',
            },
            total: 1000.0,
            ownerAmount: 900.0,
            ownerPayment: 'pending',
            createdAt: '2021-04-21T18:52:12.630Z',
        },
    ],
    meta: {
        page: 1,
        count: 3,
        perPage: 12,
        lastPage: 1,
        total: 3,
    },
};

const bookings = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/bookings', {params: filters});

        const list = new PaginatedList();

        list.items = response.data.map((booking) => new Booking(booking));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.pageCount = response.meta.lastPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/bookings/${id}`);

        return {booking: new Booking(response.data)};
    },
    messages: async (bookingId, filters = {}) => {
        const {data: response} = await API.get(
            `/bookings/${bookingId}/messages`,
            {
                params: filters,
            },
        );

        const list = new PaginatedList();
        list.items = response.data;
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.pageCount = response.meta.lastPage;
        list.total = response.meta.total;

        return list;
    },
    sendMessage: async (bookingId, data = {}) => {
        const {data: response} = await API.post(
            `/bookings/${bookingId}/messages`,
            data,
        );

        return response.data;
    },
    updateBooking: async (id, input) => {
        await API.put(`/bookings/${id}`, input);
        return {};
    },
    cancel: async (id) => {
        const {data: response} = await API.post(`/bookings/${id}/cancel`);

        return {booking: new Booking(response.data)};
    },
};

export default bookings;
