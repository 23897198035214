import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import {Carousel, Modal} from 'react-bootstrap';

import Box from '../../common/models/Box';
import API from '../../common/utils/API';
import ConfirmModal from '../../components/form/ConfirmModal';
import Can from '../../common/security/Can';
import Form from 'react-bootstrap/Form';
import Input from '../../components/form/Input';
import LoadingButton from '../../components/form/LoadingButton';
import Stadium from '../../components/stadium/Stadium';

export class BoxDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            box: new Box(),

            // Modals
            isAcceptBoxStatusModalOpen: false,
            isRejectBoxStatusModalOpen: false,
            isBoxAvailabilityModalOpen: false,

            // Reason for rejected status
            review: '',
            reviewError: '',

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,

            dimensions: null,
        };
    }

    async componentDidMount() {
        await this.fetchBox();

        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
                height: this.container.offsetHeight,
            },
        });
    };

    fetchBox = async () => {
        this.setState({loading: true});

        try {
            const {box} = await API.boxes.detail(this.props.match.params.id);

            this.setState({
                box,
                loading: false,
                isLoadErrorModalOpen: false,
                dimensions: {
                    width: this.container.offsetWidth,
                    height: this.container.offsetHeight,
                },
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleBoxAvailabilityModal = () => {
        this.setState({
            isBoxAvailabilityModalOpen: !this.state.isBoxAvailabilityModalOpen,
        });
    };

    toggleAcceptBoxStatusModal = () => {
        this.setState({
            isAcceptBoxStatusModalOpen: !this.state.isAcceptBoxStatusModalOpen,
        });
    };

    toggleRejectBoxStatusModal = () => {
        this.setState({
            isRejectBoxStatusModalOpen: !this.state.isRejectBoxStatusModalOpen,
            reviewError: '',
            review: '',
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleBoxAvailability = async () => {
        const {box} = this.state;

        this.setState({loading: true});

        try {
            if (box.isActive) {
                await API.boxes.deactivate(this.props.match.params.id);

                box.isActive = false;
            } else {
                await API.boxes.activate(this.props.match.params.id);

                box.isActive = true;
            }

            this.setState({
                box,
                error: '',
                isBoxAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `BOXES.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isBoxAvailabilityModalOpen: false,
            });
        }
    };

    acceptBoxHandler = async () => {
        const {box} = this.state;

        this.setState({loading: true});

        try {
            await API.boxes.changeStatus(this.props.match.params.id, {
                status: 'accepted',
                review: '',
            });

            box.status = 'accepted';
            box.isActive = true;
            box.readyForReview = false;

            this.setState({
                box,
                error: '',
                isAcceptBoxStatusModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `BOXES.STATUS_TOGGLE_ERROR_MESSAGE`,
                }),
                isAcceptBoxStatusModalOpen: false,
            });
        }
    };

    rejectBoxHandler = async () => {
        const {box, review} = this.state;

        if (review.length < 10) {
            this.setState({
                reviewError: this.props.intl.formatMessage({
                    id: `BOXES.VALIDATION.REVIEW_${
                        !review.length ? 'MISSING' : 'FORMAT'
                    }`,
                }),
            });

            return;
        }

        this.setState({loading: true});

        try {
            await API.boxes.changeStatus(this.props.match.params.id, {
                status: 'returned',
                review: this.state.review,
            });

            box.status = 'returned';
            box.readyForReview = false;

            this.setState({
                box,
                error: '',
                reviewError: '',
                isRejectBoxStatusModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `BOXES.STATUS_TOGGLE_ERROR_MESSAGE`,
                }),
                reviewError: '',
                isRejectBoxStatusModalOpen: false,
            });
        }
    };

    handleReviewChange = ({target: {value}}) => {
        this.setState({review: value});
    };

    render() {
        const {
            box: {readyForReview, isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/boxes'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='boxDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'BOXES.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>Id</label>
                                <p>{this.state.box.id}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_TYPE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.box.type &&
                                        this.props.intl.formatMessage({
                                            id: `BOXES.BOX_TYPE_${this.state.box.type.toUpperCase()}`,
                                        })}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_ZONE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.boxZone}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_NUMBER_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.number}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_SEATS_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.availableSeats}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_DETAIL_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.detail}</p>
                            </Col>
                            {this.state.box.type === 'onetime' ||
                            this.state.box.type === 'both' ? (
                                <>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_PRICE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>${this.state.box.price}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_DEPOSIT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>${this.state.box.deposit}</p>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_PRICE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_REVIEW_IN_SEASONS_SECTION',
                                            })}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_DEPOSIT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_REVIEW_IN_SEASONS_SECTION',
                                            })}
                                        </p>
                                    </Col>
                                </>
                            )}
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_CITY_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.city}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_VENUE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.venue}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_OWNER_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.userName}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'BOXES.BOX_ACCESS_INDICATIONS_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.accessIndications}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'BOXES.BOX_CANCEL_POLICY_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.box.cancellationPolicy}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_IS_SHARED_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.box.isShared
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No'}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_STATUS_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: `BOXES.BOX_STATUS_${this.state.box.status.toUpperCase()}`,
                                    })}
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BOXES.BOX_IS_ACTIVE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.box.isActive
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : 'No'}
                                </p>
                            </Col>
                            {this.state.box.status === 'returned' && (
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'BOXES.BOX_REVIEW_FIELD_LABEL',
                                        })}
                                    </label>
                                    <p>{this.state.box.review}</p>
                                </Col>
                            )}
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'BOXES.SEASONS_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        {this.state.box.seasons.length > 0 ? (
                            this.state.box.seasons.map((season) => (
                                <Row className='mb-4'>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_PRICE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>${season.price}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_DEPOSIT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>${season.deposit}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_SEASON_STARTS_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {new Date(
                                                season.seasonStartsAt,
                                            ).toLocaleDateString()}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOXES.BOX_SEASON_ENDS_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {new Date(
                                                season.seasonEndsAt,
                                            ).toLocaleDateString()}
                                        </p>
                                    </Col>
                                </Row>
                            ))
                        ) : (
                            <p>
                                {this.props.intl.formatMessage({
                                    id: 'BOXES.BOX_ONLY_SEASONS_SECTION',
                                })}
                            </p>
                        )}
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'BOXES.IMAGES_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={{span: 6, offset: 3}}>
                                <Carousel>
                                    {this.state.box.pictureUrl.map((url, i) => (
                                        <Carousel.Item key={i}>
                                            <img
                                                src={url}
                                                alt={this.props.intl.formatMessage(
                                                    {
                                                        id:
                                                            'BOXES.BOX_IMAGE_FIELD_LABEL',
                                                    },
                                                )}
                                                className='d-block w-100'
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'BOXES.VENUE_POS_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col ref={(el) => (this.container = el)} md={6}>
                                <Stadium
                                    dimensions={this.state.dimensions}
                                    position={this.state.box.position}
                                    venuePictureUrl={
                                        this.state.box.venuePictureUrl
                                    }
                                    venuePictureWidth={
                                        this.state.box.venuePictureWidth
                                    }
                                    venuePictureHeight={
                                        this.state.box.venuePictureWidth
                                    }
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'BOXES.DOCUMENTS_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <p className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'BOXES.BOX_DOCUMENT_TITLE_FIELD_LABEL',
                                    })}
                                </p>
                                <Image
                                    src={this.state.box.propertyTitlePictureUrl}
                                    className='img-fluid'
                                    alt={this.props.intl.formatMessage({
                                        id:
                                            'BOXES.BOX_DOCUMENT_TITLE_FIELD_LABEL',
                                    })}
                                />
                            </Col>
                            <Col md={6}>
                                <p className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'BOXES.BOX_DOCUMENT_PROOF_OF_RESIDENCE_FIELD_LABEL',
                                    })}
                                </p>
                                <Image
                                    src={
                                        this.state.box
                                            .proofOfResidencyPictureUrl
                                    }
                                    className='img-fluid'
                                    alt={this.props.intl.formatMessage({
                                        id:
                                            'BOXES.BOX_DOCUMENT_PROOF_OF_RESIDENCE_FIELD_LABEL',
                                    })}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-start'>
                    <div>
                        <Can run='BOXES_CHANGE_STATUS'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: 'BOXES.ACCEPT_BOX_MODAL',
                                })}
                                handleConfirm={this.acceptBoxHandler}
                                handleShow={this.toggleAcceptBoxStatusModal}
                                handleClose={this.toggleAcceptBoxStatusModal}
                                show={this.state.isAcceptBoxStatusModalOpen}
                                disabled={!readyForReview}
                                variant='success'
                                buttonClassName='btn-bold btn-light-success'
                                buttonLabel={this.props.intl.formatMessage({
                                    id: 'BOXES.ACCEPT_BOX_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {id: 'BOXES.CONFIRM_ACCEPT_BOX'},
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'BOXES.ACCEPT_BOX_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div className='ml-4'>
                        <Can run='BOXES_CHANGE_STATUS'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: 'BOXES.REJECT_BOX_MODAL',
                                })}
                                handleConfirm={this.rejectBoxHandler}
                                handleShow={this.toggleRejectBoxStatusModal}
                                handleClose={this.toggleRejectBoxStatusModal}
                                show={this.state.isRejectBoxStatusModalOpen}
                                disabled={!readyForReview}
                                variant='danger'
                                buttonClassName='btn-bold btn-light-danger'
                                buttonLabel={this.props.intl.formatMessage({
                                    id: 'BOXES.REJECT_BOX_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {id: 'BOXES.CONFIRM_REJECT_BOX'},
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'BOXES.REJECT_BOX_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                                <Form>
                                    <Form.Row>
                                        <Input
                                            md='12'
                                            type='textarea'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'BOXES.BOX_REVIEW_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'BOXES.BOX_REVIEW_PLACEHOLDER',
                                                },
                                            )}
                                            name='review'
                                            value={this.state.review}
                                            onChange={this.handleReviewChange}
                                            error={this.state.reviewError}
                                            isInvalid={this.state.reviewError}
                                        />
                                    </Form.Row>
                                </Form>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div className='ml-4'>
                        <Can run='BOXES_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'BOXES.BLOCK_BOX_MODAL'
                                        : 'BOXES.UNBLOCK_BOX_MODAL',
                                })}
                                handleConfirm={this.toggleBoxAvailability}
                                handleShow={this.toggleBoxAvailabilityModal}
                                handleClose={this.toggleBoxAvailabilityModal}
                                show={this.state.isBoxAvailabilityModalOpen}
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'BOXES.BLOCK_BOX_MODAL'
                                        : 'BOXES.UNBLOCK_BOX_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'BOXES.CONFIRM_BLOCK_BOX'
                                            : 'BOXES.CONFIRM_UNBLOCK_BOX',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'BOXES.BLOCK_BOX_CONFIRMATION_MESSAGE'
                                            : 'BOXES.UNBLOCK_BOX_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                </div>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'BOXES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/boxes'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchBox}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(BoxDetail);
