import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import API from '../../common/utils/API';
import moment from 'moment';
import Loader from './Loader';

class ReservedBoxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
        };
    }

    async componentDidMount() {
        await this.getReport();
    }

    async getReport() {
        this.setState({loading: true});
        const data = await API.reports.getFutureBookings();
        this.setState({
            data,
            loading: false,
        });
    }

    render() {
        const data = this.state.data;

        return (
            <Row>
                <Col sm={12}>
                    <Card className='mb-4'>
                        <Card.Header>Palcos reservados</Card.Header>
                        <Card.Body
                            style={{
                                width: '100%',
                                maxHeight: 400,
                                position: 'relative',
                                overflowY: 'scroll',
                            }}
                        >
                            <Loader loading={this.state.loading} />
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Reserva</th>
                                        <th>Fecha</th>
                                        <th>Palco</th>
                                        <th>Venue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((booking) => (
                                        <tr key={booking.key}>
                                            <td>{booking.key}</td>
                                            <td>
                                                {moment(
                                                    booking.startsAt,
                                                ).format('YYYY-MM-DD')}
                                            </td>
                                            <td>
                                                {`${booking.box?.number || ''}
                                                ${booking.box?.boxZone || ''}`}
                                            </td>
                                            <td>
                                                {booking.event?.venue?.name ||
                                                    ''}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ReservedBoxes;
