import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import * as Yup from 'yup';
import './Booking.scss';
import ChangeTicketStatusForm from '../../components/form/bookings/ChangeTicketStatusForm';
import Booking from '../../common/models/Booking';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import Form from 'react-bootstrap/Form';
import Input from '../../components/form/Input';
import ErrorModal from '../../components/ui/ErrorModal';
import ConfirmModal from '../../components/form/ConfirmModal';
import {AdminChat} from './AdminChat';

export class BookingDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            booking: new Booking(),

            // Modals
            isChangeOwnerPaymentModalOpen: false,
            isCancelBookingModalOpen: false,
            cancelPoliticsAccepted: false,

            // Reference number of payment to owner
            referenceNumber: '',
            referenceNumberError: '',

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            // Loading
            loading: false,
        };

        this.updateBookingField = this.updateBookingField.bind(this);
    }

    async componentDidMount() {
        await this.fetchBooking();
    }

    fetchBooking = async () => {
        this.setState({loading: true});

        try {
            const {booking} = await API.bookings.detail(
                this.props.match.params.id,
            );
            console.log(booking);
            this.setState({
                booking,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleOwnerPaymentModal = () => {
        this.setState({
            isChangeOwnerPaymentModalOpen: !this.state
                .isChangeOwnerPaymentModalOpen,
        });
    };

    toggleCancelBookingModal = () => {
        this.setState({
            isCancelBookingModalOpen: !this.state.isCancelBookingModalOpen,
        });
    };

    cancelBooking = async () => {
        this.setState({loading: true});

        try {
            const {booking} = await API.bookings.cancel(
                this.props.match.params.id,
            );

            this.setState({
                cancelPoliticsAccepted: false,
                isCancelBookingModalOpen: false,
                booking,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `BOOKINGS.PAYMENT_OWNER_STATUS_TOGGLE_ERROR_MESSAGE`,
                }),
                cancelPoliticsAccepted: false,
                isCancelBookingModalOpen: false,
            });
        }
    };

    changeOwnerPaymentStatus = async (e) => {
        e.preventDefault();

        const {booking, referenceNumber} = this.state;

        const schema = Yup.object().shape({
            referenceNumber: Yup.string().required(
                this.props.intl.formatMessage({
                    id: 'BOOKINGS.VALIDATION.REFERENCE_NUMBER_MISSING',
                }),
            ),
        });

        try {
            await schema.validate(
                {referenceNumber: referenceNumber.trim()},
                {abortEarly: false},
            );
        } catch (e) {
            this.setState({
                referenceNumberError: e.inner[0].message,
            });

            return;
        }

        this.setState({loading: true});

        try {
            await API.bookings.updateBooking(this.props.match.params.id, {
                ownerPaymentReference: this.state.referenceNumber,
            });

            booking.ownerPaymentReference = this.state.referenceNumber;

            this.setState({
                booking,
                referenceError: '',
                isChangeOwnerPaymentModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `BOOKINGS.PAYMENT_OWNER_STATUS_TOGGLE_ERROR_MESSAGE`,
                }),
                reviewError: '',
                isChangeOwnerPaymentModalOpen: false,
            });
        }
    };

    handleReferenceNumberChange = ({target: {value}}) => {
        this.setState({referenceNumber: value});
    };

    handleToggleCancelPoliticsCheckbox = ({
        target: {checked: cancelPoliticsAccepted},
    }) => {
        this.setState({cancelPoliticsAccepted});
    };

    getOwnerBankAccount = () => {
        let bankAccount = {};
        if (this.state.booking?.box?.user?.bankAccounts) {
            bankAccount = this.state.booking.box.user.bankAccounts[0];
        }
        return bankAccount;
    };

    getBookingOwnerPaymentStatus = () => {
        return this.state.booking?.ownerPaymentReference ? 'PAYED' : 'PENDING';
    };

    updateBookingField(name, value) {
        const booking = {...this.state.booking};
        booking[name] = value;
        this.setState({booking});
    }

    formatDate(dateString) {
        if (!dateString) return '';
        return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    }

    render() {
        let startsAt;
        let createdAt;

        if (this.state.booking.startsAt) {
            startsAt = `${moment(
                this.state.booking.startsAt.slice(0, -5),
            ).format('D/M/YYYY h:mm:ss A')} - ${moment(
                this.state.booking.endsAt.slice(0, -5),
            ).format('D/M/YYYY h:mm:ss A')}`;
        }

        if (this.state.booking.createdAt) {
            createdAt = `${moment(this.state.booking.createdAt).format(
                'D/M/YYYY h:mm:ss A',
            )}`;
        }

        return (
            <div data-test='bookingDetailComponent'>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/bookings'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Tabs>
                    <Tab eventKey='info' title='Información'>
                        <Card className='mb-4 mt-4'>
                            <Card.Header>
                                {this.props.intl.formatMessage({
                                    id:
                                        'BOOKINGS.GENERAL_INFORMATION_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            Key
                                        </label>
                                        <p>{this.state.booking.key}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_BOOKING_TYPE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.state.booking?.boxType &&
                                                this.props.intl.formatMessage({
                                                    id: `BOOKINGS.BOOKING_TYPE_${this.state.booking.boxType.toUpperCase()}`,
                                                })}
                                            {this.state.booking?.boxType ===
                                                'onetime' &&
                                                ': ' +
                                                    this.state.booking?.event
                                                        ?.name}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.state.booking?.boxType &&
                                                this.props.intl.formatMessage({
                                                    id: `BOOKINGS.BOOKING_${this.state.booking.boxType.toUpperCase()}_STARTS_AT_FIELD_LABEL`,
                                                })}
                                        </label>
                                        <p>{startsAt}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_STATUS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.state.booking?.status &&
                                                this.props.intl.formatMessage({
                                                    id: `BOOKINGS.BOOKING_STATUS_${this.state.booking.status.toUpperCase()}`,
                                                })}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_PAYMENT_STATUS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.state.booking?.payments &&
                                                this.state.booking?.payments
                                                    ?.length &&
                                                this.props.intl.formatMessage({
                                                    id: `BOOKINGS.BOOKING_PAYMENT_STATUS_${this.state.booking.payments[0].status.toUpperCase()}`,
                                                })}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_CREATED_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{createdAt}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            Total
                                        </label>
                                        <p>${this.state.booking.total}</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row>
                            <Col sm={6}>
                                <Card className='mb-4'>
                                    <Card.Header>
                                        {this.props.intl.formatMessage({
                                            id: 'BOOKINGS.CLIENT_SECTION_TITLE',
                                        })}
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={6}>
                                                <label className='field-title'>
                                                    Id
                                                </label>
                                                <p>
                                                    {
                                                        this.state.booking.buyer
                                                            ?.id
                                                    }
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'BOOKINGS.BOOKING_CLIENT_NAME_FIELD_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>{`${this.state.booking.buyer?.firstName} ${this.state.booking.buyer?.lastName}`}</p>
                                            </Col>
                                            <Col md={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'BOOKINGS.BOOKING_CLIENT_EMAIL_FIELD_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>
                                                    {
                                                        this.state.booking
                                                            ?.buyer?.email
                                                    }
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'BOOKINGS.BOOKING_CLIENT_PHONE_FIELD_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>
                                                    {
                                                        this.state.booking
                                                            ?.buyer?.phone
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6}>
                                <Card className='mb-4'>
                                    <Card.Header>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'BOOKINGS.BOOKING_TICKET_STATUS_FIELD_LABEL',
                                        })}
                                    </Card.Header>
                                    <Card.Body>
                                        <Table className='p-0' borderless>
                                            <tbody>
                                                <tr>
                                                    <th
                                                        className='text-left'
                                                        style={{width: '100px'}}
                                                    >
                                                        {this.props.intl.formatMessage(
                                                            {
                                                                id:
                                                                    'BOOKINGS.TICKETS_SENT',
                                                            },
                                                        )}
                                                    </th>
                                                    <td className='text-left'>
                                                        {this.formatDate(
                                                            this.state.booking
                                                                .ticketsSentAt,
                                                        ) ||
                                                            this.props.intl.formatMessage(
                                                                {
                                                                    id: `BOOKINGS.BOOKING_TICKET_PENDING`,
                                                                },
                                                            )}
                                                    </td>
                                                    <td className='text-left'>
                                                        <ChangeTicketStatusForm
                                                            id={
                                                                this.state
                                                                    .booking.id
                                                            }
                                                            fieldName='ticketsSentAt'
                                                            show={
                                                                !this.state
                                                                    .loading &&
                                                                !this.state
                                                                    .booking
                                                                    .ticketsSentAt
                                                            }
                                                            setField={
                                                                this
                                                                    .updateBookingField
                                                            }
                                                            buttonLabel={this.props.intl.formatMessage(
                                                                {
                                                                    id:
                                                                        'BOOKINGS.BOOKING_TICKET_MARK_AS_SENT',
                                                                },
                                                            )}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th
                                                        className='text-left'
                                                        style={{width: '100px'}}
                                                    >
                                                        {this.props.intl.formatMessage(
                                                            {
                                                                id:
                                                                    'BOOKINGS.BOOKING_TICKET_DELIVERED',
                                                            },
                                                        )}
                                                    </th>
                                                    <td className='text-left'>
                                                        {this.formatDate(
                                                            this.state.booking
                                                                .ticketsDeliveredAt,
                                                        ) ||
                                                            this.props.intl.formatMessage(
                                                                {
                                                                    id: `BOOKINGS.BOOKING_TICKET_PENDING`,
                                                                },
                                                            )}
                                                    </td>
                                                    <td className='text-left'>
                                                        <ChangeTicketStatusForm
                                                            id={
                                                                this.state
                                                                    .booking.id
                                                            }
                                                            fieldName='ticketsDeliveredAt'
                                                            show={
                                                                !this.state
                                                                    .loading &&
                                                                this.state
                                                                    .booking
                                                                    .ticketsSentAt &&
                                                                !this.state
                                                                    .booking
                                                                    .ticketsDeliveredAt
                                                            }
                                                            setField={
                                                                this
                                                                    .updateBookingField
                                                            }
                                                            buttonLabel={this.props.intl.formatMessage(
                                                                {
                                                                    id:
                                                                        'BOOKINGS.BOOKING_TICKET_MARK_AS_DELIVERED',
                                                                },
                                                            )}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Card className='mb-4'>
                            <Card.Header>
                                {this.props.intl.formatMessage({
                                    id: 'BOOKINGS.OWNER_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            Id
                                        </label>
                                        <p>
                                            {this.state.booking?.box?.user?.id}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{`${this.state.booking.box?.userName}`}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_EMAIL_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {
                                                this.state.booking?.box?.user
                                                    ?.email
                                            }
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_PHONE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {
                                                this.state.booking?.box?.owner
                                                    ?.phone
                                            }
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_BANK_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {
                                                this.getOwnerBankAccount()?.bank
                                                    ?.name
                                            }
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_BANK_ACCOUNT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {
                                                this.getOwnerBankAccount()
                                                    ?.accountNumber
                                            }
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_BANK_CLABE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {
                                                this.getOwnerBankAccount()
                                                    ?.clabeNumber
                                            }
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_AMOUNT_TO_PAY_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>${this.state.booking.ownerTotal}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_PAYMENT_STATUS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id: `BOOKINGS.BOOKING_OWNER_PAYMENT_${this.getBookingOwnerPaymentStatus()}`,
                                            })}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.BOOKING_OWNER_PAYMENT_REFERENCE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {
                                                this.state.booking
                                                    .ownerPaymentReference
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <div className='d-flex justify-content-start'>
                            <div>
                                <Can run='BOOKINGS_UPDATE'>
                                    <ConfirmModal
                                        titleModal={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'BOOKINGS.MODAL_OWNER_PAYMENT_TITLE',
                                            },
                                        )}
                                        handleConfirm={
                                            this.changeOwnerPaymentStatus
                                        }
                                        handleShow={
                                            this.toggleOwnerPaymentModal
                                        }
                                        handleClose={
                                            this.toggleOwnerPaymentModal
                                        }
                                        show={
                                            this.state
                                                .isChangeOwnerPaymentModalOpen
                                        }
                                        disabled={
                                            this.getBookingOwnerPaymentStatus() !==
                                            'PENDING'
                                        }
                                        variant='success'
                                        buttonClassName='btn-bold btn-light-success'
                                        buttonLabel={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'BOOKINGS.MODAL_OWNER_PAYMENT_BUTTON_LABEL',
                                            },
                                        )}
                                        buttonAcceptLabel={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'BOOKINGS.MODAL_OWNER_PAYMENT_CONFIRM_BUTTON_LABEL',
                                            },
                                        )}
                                        buttonCloseLabel={this.props.intl.formatMessage(
                                            {
                                                id: 'GENERAL.CANCEL',
                                            },
                                        )}
                                    >
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.MODAL_OWNER_PAYMENT_CONFIRMATION_MESSAGE',
                                            })}
                                        </p>
                                        <Form
                                            onSubmit={
                                                this.changeOwnerPaymentStatus
                                            }
                                        >
                                            <Form.Row>
                                                <Input
                                                    md='12'
                                                    type='text'
                                                    label={this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'BOOKINGS.BOOKING_OWNER_PAYMENT_REFERENCE_FIELD_LABEL',
                                                        },
                                                    )}
                                                    placeholder={this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'BOOKINGS.BOOKING_OWNER_PAYMENT_REFERENCE_PLACEHOLDER',
                                                        },
                                                    )}
                                                    name='referenceNumber'
                                                    value={
                                                        this.state
                                                            .referenceNumber
                                                    }
                                                    onChange={
                                                        this
                                                            .handleReferenceNumberChange
                                                    }
                                                    error={
                                                        this.state
                                                            .referenceNumberError
                                                    }
                                                    isInvalid={
                                                        this.state
                                                            .referenceNumberError
                                                    }
                                                />
                                            </Form.Row>
                                        </Form>
                                    </ConfirmModal>
                                </Can>
                            </div>
                            <div className='ml-4'>
                                <Can run='BOOKINGS_CANCEL_BOOKING'>
                                    <ConfirmModal
                                        disabledConfirmButton={
                                            !this.state.cancelPoliticsAccepted
                                        }
                                        disabled={
                                            this.state.booking.status ===
                                            'canceled'
                                        }
                                        titleModal={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'BOOKINGS.CANCEL_BOOKING_TITLE',
                                            },
                                        )}
                                        handleConfirm={this.cancelBooking}
                                        handleShow={
                                            this.toggleCancelBookingModal
                                        }
                                        handleClose={
                                            this.toggleCancelBookingModal
                                        }
                                        show={
                                            this.state.isCancelBookingModalOpen
                                        }
                                        variant='danger'
                                        buttonClassName='btn-bold btn-light-danger'
                                        buttonLabel={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'BOOKINGS.CANCEL_BOOKING_BUTTON_LABEL',
                                            },
                                        )}
                                        buttonAcceptLabel={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'BOOKINGS.CANCEL_BOOKING_CONFIRM_BUTTON_LABEL',
                                            },
                                        )}
                                        buttonCloseLabel={this.props.intl.formatMessage(
                                            {
                                                id: 'GENERAL.CANCEL',
                                            },
                                        )}
                                    >
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'BOOKINGS.CANCEL_BOOKING_CONFIRMATION_MESSAGE',
                                            })}{' '}
                                            <a
                                                target='_blank'
                                                href='https://www.google.com'
                                            >
                                                {this.props.intl.formatMessage({
                                                    id:
                                                        'BOOKINGS.CANCEL_BOOKING_CANCEL_POLICIES',
                                                })}
                                            </a>
                                        </p>
                                        <Input
                                            type='checkbox'
                                            id='cancelPoliticsCheckbox'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'BOOKINGS.CANCEL_ACCEPT_CANCEL_POLICIES',
                                                },
                                            )}
                                            hideLabel={true}
                                            onChange={
                                                this
                                                    .handleToggleCancelPoliticsCheckbox
                                            }
                                            checked={
                                                this.state
                                                    .cancelPoliticsAccepted
                                            }
                                        />
                                    </ConfirmModal>
                                </Can>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey='messages' title='Mensajes'>
                        <Row>
                            <Col md={6}>
                                {this.state.booking?.id && (
                                    <AdminChat
                                        className='mt-4'
                                        title='Owner'
                                        bookingId={this.state.booking?.id}
                                        userId={
                                            this.state.booking?.box?.user?.id
                                        }
                                    />
                                )}
                            </Col>
                            <Col md={6}>
                                {this.state.booking?.id && (
                                    <AdminChat
                                        className='mt-4'
                                        title='Guest'
                                        bookingId={this.state.booking?.id}
                                        userId={this.state.booking?.buyer?.id}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
                <ErrorModal
                    isLoadErrorModalOpen={this.state.isLoadErrorModalOpen}
                    toggleLoadErrorModal={this.toggleLoadErrorModal}
                    errorMessage={this.props.intl.formatMessage({
                        id: 'SERVICES.ERROR_MODAL_TEXT',
                    })}
                    returnUrl={`/bookings/`}
                    loading={this.state.loading}
                    handleRetryClick={this.fetchBooking}
                />
            </div>
        );
    }
}

export default injectIntl(BookingDetail);
