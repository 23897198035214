import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

function Datepicker(props) {
    // minDate is in days if it is 0 the current date will be the min date
    const dateFormat = props.showTimeSelect ? 'd/M/yyyy h:mm aa' : 'd/M/yyyy';
    const minDate =
        (typeof props.minDate !== 'undefined' &&
            moment().add(3, 'day').toDate()) ||
        null;
    let date = props.value ? moment(props.value) : null;

    return (
        <>
            <DatePicker
                selected={date?.toDate() || null}
                onChange={props.onChange}
                minDate={minDate}
                placeholderText={props.placeholder}
                isClearable={props.isClearable}
                showTimeSelect={props.showTimeSelect}
                dateFormat={dateFormat}
                className={`form-control ${
                    props.isInvalid ? 'is-invalid' : ''
                }`}
            />
        </>
    );
}

export default Datepicker;
