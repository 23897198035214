import React from 'react';
import Card from 'react-bootstrap/Card';
import LoadingButton from '../../components/form/LoadingButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import './Booking.scss';
import API from '../../common/utils/API';

export class AdminChat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newMessageText: '',
            isLoadingMessages: false,
            messages: [],
            messagesPage: 1,
            messagesLastPage: 1,
            messagesTotal: 0,
        };
    }

    async componentDidMount() {
        await this.fetchMessages();
    }

    fetchMessages = async () => {
        this.setState({isLoadingMessages: true});
        try {
            const response = await API.bookings.messages(this.props.bookingId, {
                userId: this.props.userId,
                page: this.state.messagesPage,
            });
            this.setState({
                isLoadingMessages: false,
                messages: [...response.items.reverse(), ...this.state.messages],
                messagesPage: response.page,
                messagesLastPage: response.pageCount,
                messagesTotal: response.total,
            });
        } catch (e) {
            this.setState({
                isLoadingMessages: false,
            });
            console.log(e);
        }
    };

    handleLoadOldMessages = async () => {
        if (this.state.messagesLastPage > this.state.messagesPage) {
            this.setState(
                {
                    messagesPage: this.state.messagesPage + 1,
                },
                () => {
                    this.fetchMessages();
                },
            );
        }
    };

    handleSendMessage = async (event) => {
        event.preventDefault();

        this.setState({isLoadingMessages: true});

        try {
            console.log('will send the message');
            const newMessage = await API.bookings.sendMessage(
                this.props.bookingId,
                {
                    userId: this.props.userId,
                    message: this.state.newMessageText,
                },
            );

            this.setState({
                messages: [...this.state.messages, newMessage],
                isLoadingMessages: false,
                newMessageText: '',
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                isLoadingMessages: false,
            });
        }
    };

    handleNewMessageChange = async (event) => {
        this.setState({
            newMessageText: event.target.value,
        });
    };

    render() {
        return (
            <Card className={this.props.className}>
                <Card.Header>{this.props.title}</Card.Header>
                <Card.Body>
                    <div className='text-center mb-3 mt-1'>
                        <LoadingButton
                            variant='primary'
                            loading={this.state.isLoadingMessages}
                            disabled={
                                this.state.messagesLastPage ===
                                this.state.messagesPage
                            }
                            onClick={this.handleLoadOldMessages}
                        >
                            Cargar anteriores
                        </LoadingButton>
                    </div>
                    {this.state.messages.map((message) => (
                        <div
                            key={message.id}
                            className={
                                'message-container ' +
                                (message.repliedToUser?.id ? 'admin' : '')
                            }
                        >
                            <div>{message.message}</div>
                        </div>
                    ))}
                </Card.Body>
                <Card.Footer>
                    <Form onSubmit={this.handleSendMessage}>
                        <InputGroup>
                            <Form.Control
                                onChange={this.handleNewMessageChange}
                                value={this.state.newMessageText}
                                placeholder='Mensaje'
                            />
                            <InputGroup.Append>
                                <Button variant='primary' type='submit'>
                                    Enviar
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form>
                </Card.Footer>
            </Card>
        );
    }
}
