import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import logo from '../../components/ui/assets/black-logo.png';
import LoginImage from '../../components/ui/assets/login-img.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './ColumnContainer.scss';

function ColumnContainer(props) {
    return (
        <div className='column-container'>
            <div className='aside'>
                <LazyLoadImage
                    alt='Login Image'
                    height='100%'
                    width='100%'
                    effect='blur'
                    src={LoginImage}
                />
            </div>
            <div className='control-column'>
                <div className='alt-logo'>
                    <LazyLoadImage
                        alt='Starter Icon'
                        height='80'
                        effect='blur'
                        src={logo}
                    />
                </div>
                {props.children}
            </div>
        </div>
    );
}

export default ColumnContainer;
