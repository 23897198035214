import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Owner from '../../models/Owner';

const owners = {
    get: async (filters = {}) => {
        const {data: response} = await API.get('/owners', {
            params: filters,
        });

        const list = new PaginatedList();

        list.items = response.data.map((owner) => new Owner(owner));
        list.page = response.meta.page;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/owners/${id}`);

        return {
            owner: new Owner(response.data),
        };
    },
    changeStatus: async (id, input) => {
        await API.put(`/owners/${id}/status`, input);

        return {};
    },
    activate: async (id) => {
        await API.put(`/owners/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/owners/${id}/deactivate`);

        return {};
    },
};

export default owners;
