import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

import API from '../../common/utils/API';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';
import Form from '../../components/form/events/EventForm';
import Event from '../../common/models/Event';
import ErrorModal from '../../components/ui/ErrorModal';

export class EditEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: new Event(),

            // Fetch all categories and venues
            categories: [],
            venues: [],

            // Redirects
            shouldRedirectToIndex: false,
            shouldRedirectToDetail: false,
            redirectSuccessMessage: '',

            // Errors
            isLoadErrorModalOpen: false,
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchEvent(),
            this.fetchCategories(),
            this.fetchVenues(),
        ]);
    }

    fetchEvent = async () => {
        this.setState({loading: true});

        try {
            const {event} = await API.events.detail(this.props.match.params.id);

            this.setState({
                event,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    fetchCategories = async () => {
        this.setState({loading: true});

        try {
            const {categories} = await API.categories.getAll();

            this.setState({
                categories,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    fetchVenues = async () => {
        this.setState({loading: true});

        try {
            const {venues} = await API.venues.getAll();

            this.setState({
                venues,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        const formData = new FormData();
        const startsAt = moment(input.startsAt).format('YYYY-MM-DD HH:mm:ss');
        formData.append('name', input.name);
        formData.append('duration', input.duration);
        formData.append('venue', input.venue);
        formData.append('startsAt', startsAt);

        if (input.picture?.length) {
            formData.append('picture', input.picture[0]);
        }

        input.categories.forEach((category) => {
            formData.append('categories[]', category);
        });

        try {
            await API.events.update(this.props.match.params.id, formData);

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'EVENTS.EVENT_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.UPDATING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    handleDeleteEvent = async () => {
        this.setState({loading: true});

        try {
            await API.events.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'EVENTS.EVENT_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        const categories = this.state.categories.map((category) => ({
            label: category.name,
            value: category.id,
        }));
        const venues = this.state.venues.map((venue) => ({
            label: venue.name,
            value: venue.id,
        }));
        const {
            shouldRedirectToDetail,
            shouldRedirectToIndex,
            redirectSuccessMessage: successMessage,
        } = this.state;
        const initialValues = {
            name: this.state.event?.name || '',
            duration: this.state.event?.duration || '',
            venue: this.state.event?.venue?.id || '',
            startsAt:
                (this.state.event?.startsAt &&
                    moment(this.state.event.startsAt).format(
                        'YYYY-MM-DD HH:mm:ss',
                    )) ||
                '',
            categories:
                this.state.event?.categories?.map((category) => category.id) ||
                [],
            picture: [],
        };

        return shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/events/${this.props.match.params.id}`,
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/events',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'EVENTS.EVENT_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='updateEventComponent'
                    initialValues={initialValues}
                    backRedirectUrl={`/events/${this.props.match.params.id}`}
                    backText={this.props.intl.formatMessage({
                        id: 'GENERAL.BACK_TO_DETAIL',
                    })}
                    categories={categories}
                    venues={venues}
                    preview={this.state.event.pictureUrl}
                    isEdit={true}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                    handleDeleteEvent={this.handleDeleteEvent}
                />
                <ErrorModal
                    isLoadErrorModalOpen={this.state.isLoadErrorModalOpen}
                    toggleLoadErrorModal={this.toggleLoadErrorModal}
                    errorMessage={this.props.intl.formatMessage({
                        id: 'EVENTS.ERROR_MODAL_TEXT',
                    })}
                    returnUrl={`/events/${this.props.match.params.id}`}
                    loading={this.state.loading}
                    handleRetryClick={this.fetchCategory}
                />
            </>
        );
    }
}

export default injectIntl(EditEvent);
