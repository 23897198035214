import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom';
import moment from 'moment';

import Event from '../../common/models/Event';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import ConfirmModal from '../../components/form/ConfirmModal';
import ErrorModal from '../../components/ui/ErrorModal';

export class EventDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: new Event(),

            // Enable/Disable Event
            isEventAvailabilityModalOpen: false,

            // Errors
            isLoadErrorModalOpen: false,
            error: false,

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchEvent();
    }

    fetchEvent = async () => {
        this.setState({loading: true});

        try {
            const {event} = await API.events.detail(this.props.match.params.id);

            this.setState({
                event,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleEventAvailabilityModal = () => {
        this.setState({
            isEventAvailabilityModalOpen: !this.state
                .isEventAvailabilityModalOpen,
        });
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    toggleEventAvailability = async () => {
        const {event} = this.state;

        this.setState({loading: true});

        try {
            if (event.isActive) {
                await API.events.deactivate(this.props.match.params.id);

                event.isActive = false;
            } else {
                await API.events.activate(this.props.match.params.id);

                event.isActive = true;
            }

            this.setState({
                event,
                isEventAvailabilityModalOpen: false,
            });
        } catch (e) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: `EVENTS.AVAILABILITY_TOGGLE_ERROR_MESSAGE`,
                }),
                isEventAvailabilityModalOpen: false,
            });
        }
    };

    render() {
        const {
            event: {isActive},
        } = this.state;

        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/events'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='eventDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'EVENTS.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                {this.state.event.pictureUrl && (
                                    <img
                                        className='img-fluid'
                                        src={this.state.event.pictureUrl}
                                        alt={this.state.event.name}
                                    />
                                )}
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            Id
                                        </label>
                                        <p>{this.state.event.id}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'EVENTS.EVENT_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.event.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'EVENTS.EVENT_STARTS_AT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {moment(
                                                this.state.event.startsAt,
                                            ).format('D/M/YYYY h:mm:ss A')}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'EVENTS.EVENT_VENUE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.state.event.venue?.name || ''}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'EVENTS.EVENT_CATEGORIES_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.state.event.categories
                                                .map(
                                                    (category) => category.name,
                                                )
                                                .join(', ')}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4' data-test='eventDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'EVENTS.BOXES_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {this.state.event.venue?.boxes &&
                                this.state.event.venue.boxes.map((box) => (
                                    <Col
                                        key={box.id}
                                        md={4}
                                        className='text-center'
                                    >
                                        <NavLink to={`/boxes/${box.id}`}>
                                            {box.number} {box.boxZone}
                                        </NavLink>
                                    </Col>
                                ))}
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='EVENTS_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'EVENTS.BLOCK_EVENT_MODAL'
                                        : 'EVENTS.UNBLOCK_EVENT_MODAL',
                                })}
                                handleConfirm={this.toggleEventAvailability}
                                handleShow={this.toggleEventAvailabilityModal}
                                handleClose={this.toggleEventAvailabilityModal}
                                show={this.state.isEventAvailabilityModalOpen}
                                variant={isActive ? 'danger' : 'success'}
                                buttonClassName={`btn-bold ${
                                    isActive
                                        ? 'btn-light-danger'
                                        : 'btn-light-success'
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: isActive
                                        ? 'EVENTS.BLOCK_EVENT_MODAL'
                                        : 'EVENTS.UNBLOCK_EVENT_MODAL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: isActive
                                            ? 'EVENTS.CONFIRM_BLOCK_EVENT'
                                            : 'EVENTS.CONFIRM_UNBLOCK_EVENT',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: 'GENERAL.CANCEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: isActive
                                            ? 'EVENTS.BLOCK_EVENT_CONFIRMATION_MESSAGE'
                                            : 'EVENTS.UNBLOCK_EVENT_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div>
                        <Can run='EVENTS_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/events/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'EVENTS.EDIT_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>
                <ErrorModal
                    isLoadErrorModalOpen={this.state.isLoadErrorModalOpen}
                    toggleLoadErrorModal={this.toggleLoadErrorModal}
                    errorMessage={this.props.intl.formatMessage({
                        id: 'EVENTS.ERROR_MODAL_TEXT',
                    })}
                    returnUrl={`/events`}
                    loading={this.state.loading}
                    handleRetryClick={this.fetchEvent}
                />
            </>
        );
    }
}

export default injectIntl(EventDetail);
