import general from './general/es';
import starterList from './starter_list/es';
import roles from './roles/es';
import users from './users/es';
import routes from './routes/es';
import cities from './cities/es';
import states from './states/es';
import countries from './countries/es';
import owners from './owners/es';
import paymentProviders from './payment_providers/es';
import paymentMethods from './payment_methods/es';
import venues from './venues/es';
import boxes from './boxes/es';
import categories from './categories/es';
import services from './services/es';
import events from './events/es';
import notifications from './notifications/es';
import bookings from './bookings/es';
import settings from './settings/es';

export default {
    ...general,
    ...starterList,
    ...roles,
    ...users,
    ...routes,
    ...states,
    ...cities,
    ...countries,
    ...owners,
    ...paymentProviders,
    ...paymentMethods,
    ...venues,
    ...boxes,
    ...categories,
    ...services,
    ...events,
    ...notifications,
    ...bookings,
    ...settings,
};
