import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Notification from '../../common/models/Notification';
import API from '../../common/utils/API';
import LoadingButton from '../../components/form/LoadingButton';
import {returnNotificationPath} from '../../common/utils/helpers';

export class NotificationDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: new Notification(),

            // Errors
            isLoadErrorModalOpen: false,
            error: '',

            /// Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchNotification();
    }

    fetchNotification = async () => {
        this.setState({loading: true});

        try {
            const {notification} = await API.notifications.detail(
                this.props.match.params.id,
            );

            this.setState({
                notification,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/notifications'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>

                <Card className='mb-4' data-test='notificationDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'NOTIFICATIONS.GENERAL_INFORMATION_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>Id</label>
                                <p>{this.state.notification.id}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'NOTIFICATIONS.NOTIFICATION_TITLE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.notification.title}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'NOTIFICATIONS.NOTIFICATION_BODY_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    <a
                                        href={returnNotificationPath(
                                            this.state.notification
                                                .notificationType,
                                            this.state.notification
                                                .notificationTypeId,
                                        )}
                                    >
                                        {this.state.notification.body}
                                    </a>
                                </p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'NOTIFICATIONS.NOTIFICATION_READ_AT_LABEL',
                                    })}
                                </label>
                                <p>{this.state.notification.readAt}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'NOTIFICATIONS.NOTIFICATION_CREATED_AT_LABEL',
                                    })}
                                </label>
                                <p>{this.state.notification.createdAt}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'NOTIFICATIONS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/notifications'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchNotification}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(NotificationDetail);
