import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import Country from '../../common/models/Country';
import API from '../../common/utils/API';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';
import Form from '../../components/form/countries/CountryForm';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LoadingButton from "../../components/form/LoadingButton";

export class EditCountry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            country: new Country(),

            // Redirects
            shouldRedirectToIndex: false,
            shouldRedirectToDetail: false,
            redirectSuccessMessage: '',

            // Errors
            isLoadErrorModalOpen: false,
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await this.fetchCountry();
    }

    fetchCountry = async () => {
        this.setState({loading: false});

        try {
            const {country} = await API.countries.detail(
                this.props.match.params.id,
            );

            this.setState({
                country,
                loading: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        try {
            await API.countries.update(this.props.match.params.id, input);

            this.setState({
                loading: false,
                shouldRedirectToDetail: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'COUNTRIES.COUNTRY_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.UPDATING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    handleDeleteCountry = async () => {
        this.setState({loading: true});

        try {
            await API.countries.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'COUNTRIES.COUNTRY_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    toggleLoadErrorModal = () => {
        this.setState({
            isLoadErrorModalOpen: !this.state.isLoadErrorModalOpen,
        });
    };

    render() {
        const {
            shouldRedirectToDetail,
            shouldRedirectToIndex,
            redirectSuccessMessage: successMessage,
        } = this.state;

        const initialValues = {
            name: this.state.country?.name || '',
            code: this.state.country?.code || '',
        };

        return shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/countries/${this.props.match.params.id}`,
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/countries',
                    state: {
                        successMessage,
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='updateCountryComponent'
                    initialValues={initialValues}
                    backRedirectUrl={`/countries/${this.props.match.params.id}`}
                    backText={this.props.intl.formatMessage({
                        id: 'GENERAL.BACK_TO_DETAIL',
                    })}
                    loading={this.state.loading}
                    isEdit={true}
                    handleSubmit={this.onSubmit}
                    handleDeleteCountry={this.handleDeleteCountry}
                />
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'COUNTRIES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/countries'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchCountry}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ERRORS.MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(EditCountry);
