import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

import API from '../../common/utils/API';
import {formatErrorMessageFromAPI} from '../../common/utils/helpers';
import Form from '../../components/form/events/EventForm';

export class CreateEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Fetch all categories and venues
            categories: [],
            venues: [],

            // Redirects
            shouldRedirectToIndex: false,

            // Errors
            errorMessage: '',

            // Loading
            loading: false,
        };
    }

    async componentDidMount() {
        await Promise.all([this.fetchVenues(), this.fetchCategories()]);
    }

    fetchVenues = async () => {
        this.setState({loading: true});

        try {
            const {venues} = await API.venues.getAll();

            this.setState({
                venues,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    fetchCategories = async () => {
        this.setState({loading: true});

        try {
            const {categories} = await API.categories.getAll();

            this.setState({
                categories,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        const formData = new FormData();
        const startsAt = moment(input.startsAt).format('YYYY-MM-DD HH:mm:ss');
        formData.append('name', input.name);
        formData.append('duration', input.duration);
        formData.append('venue', input.venue);
        formData.append('startsAt', startsAt);
        formData.append('picture', input.picture[0]);

        input.categories.forEach((category) => {
            formData.append('categories[]', category);
        });

        try {
            await API.events.save(formData);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            const error = formatErrorMessageFromAPI(
                e,
                this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.STORING_DATA',
                }),
            );

            this.setState({
                ...error,
            });
        }
    };

    render() {
        const categories = this.state.categories.map((category) => ({
            label: category.name,
            value: category.id,
        }));
        const venues = this.state.venues.map((venue) => ({
            label: venue.name,
            value: venue.id,
        }));
        const {shouldRedirectToIndex} = this.state;
        const initialValues = {
            name: '',
            duration: '',
            venue: '',
            startsAt: '',
            categories: [],
            picture: [],
        };

        return shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/events',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'EVENTS.EVENT_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='createEventComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/events'
                    backText={this.props.intl.formatMessage({
                        id: 'LIST.BACK_TO_LIST',
                    })}
                    categories={categories}
                    venues={venues}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                />
            </>
        );
    }
}

export default injectIntl(CreateEvent);
