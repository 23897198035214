import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from '../common/redux/StoreReducers';
import LoginContainer from './login_containers/LoginContainer';
import ResetPasswordContainer from './login_containers/ResetPasswordContainer';
import ForgotPasswordContainer from './login_containers/ForgotPasswordContainer';
import PasswordResetLinkSentContainer from './login_containers/PasswordResetLinkSentContainer';
import MainContainer from './MainContainer';
import ActivateAccount from './activation/ActivateAccount';
import PrivateRoute from '../components/PrivateRoute';
import AuthRoute from '../components/AuthRoute';
import './App.css';

import I18nProvider from '../common/internalization/I18nProvider';

function App() {
    return (
        <Provider store={store}>
            <I18nProvider>
                <div className='App'>
                    <Router>
                        <Switch>
                            <Route
                                exact
                                path={'/activate-account'}
                                component={ActivateAccount}
                            />
                            <AuthRoute
                                exact
                                path={'/login'}
                                component={LoginContainer}
                            />
                            <AuthRoute
                                exact
                                path={'/forgot-password'}
                                component={ForgotPasswordContainer}
                            />
                            <Route
                                exact
                                path={'/reset-password'}
                                component={ResetPasswordContainer}
                            />
                            <AuthRoute
                                exact
                                path={'/password-reset-link-sent'}
                                component={PasswordResetLinkSentContainer}
                            />
                            <PrivateRoute
                                path={'/'}
                                component={MainContainer}
                            />
                        </Switch>
                    </Router>
                </div>
            </I18nProvider>
        </Provider>
    );
}

export default App;
