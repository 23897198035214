import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import SettingsIndex from './SettingsIndex';

function Settings() {
    const intl = useIntl();

    return (
        <Switch data-test='servicesComponent'>
            <PrivateRoute
                permission='SERVICES_INDEX'
                exact
                path='/settings'
                component={withLayout(
                    SettingsIndex,
                    intl.formatMessage({id: 'ROUTES.SETTINGS.INDEX'}),
                )}
            />
        </Switch>
    );
}

export default Settings;
